import { BLUE_100, BLUE_600, GRAY_400, GRAY_900, WHITE } from 'constants/styling/theme'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import React, { ReactNode } from 'react'

import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Label } from '../Label'
import { styled } from '@mui/material/styles'

/**
 * @interface Props Input properties
 */
export interface Props extends CheckboxProps {
  label?: string | ReactNode
  labelPlacement?: FormControlLabelProps['labelPlacement']
}

const StyledBox = styled('span')(() => ({
  width: '1.6rem',
  height: '1.6rem',
  borderRadius: '.4rem',
  border: `solid .1rem ${GRAY_400}`,
  backgroundColor: WHITE,
  'input:hover ~ &': {
    borderColor: BLUE_600
  }
}))

const StyledCheckedBox = styled((props) => (
  <StyledBox {...props}><CheckRoundedIcon /></StyledBox>
))(() => ({
  border: `solid .1rem ${BLUE_600}`,
  backgroundColor: BLUE_100,
  color: BLUE_600,
  'input:disabled ~ &': {
    opacity: 0.5,
  },
}))

/**
 * @component
 * MUICheckbox represents a customized Checkbox with blue theme.
 *
 * @example
 * <MUICheckbox
 *   checked={isChecked}
 *   onClick={() => handleCheckboxClick()}
 * />
 */
export const MUICheckbox: React.FC<Props> = ({
  checked,
  label,
  labelPlacement,
  onClick,
  ...defaultProps
}) => {

  return (
    <FormControlLabel
      sx={{
        margin: 0,
        gap: '1rem',
        '.MuiFormControlLabel-label': {
          color: GRAY_900,
          fontSize: '1.2rem',
          lineHeight: 1,
        }
      }}
      control={(
        <Checkbox
          sx={{
            '&:hover': { bgcolor: 'transparent' },
            padding: 0
          }}
          checked={checked}
          disableRipple
          checkedIcon={<StyledCheckedBox />}
          icon={<StyledBox />}
          onClick={onClick}
          {...defaultProps}
        />
      )}
      label={typeof label === 'string' ? <Label text={label} /> : label}
      labelPlacement={labelPlacement}
    />
  )
}
