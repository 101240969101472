import { FC, ReactNode, createContext, useContext } from 'react'

import { UpsellPurchaseConfiguration } from '../upsellPurchaseConfiguration.interface'

const defaultPurchaseConfigurationValue: UpsellPurchaseConfiguration = {
  assignmentId: undefined,
  isOpen: false,
  productId: undefined,
  productType: undefined,
  productQuantity: undefined,
  total: undefined,
  beforeConfirmActionState: undefined,
  onClose: undefined,
  beforeConfirmAction: undefined,
  afterClosed: undefined,
  successNode: undefined,
  successTitle: undefined,
  beforeConfirmationActionRequest: undefined,
}

/** Upsell purchase configuration context */
export const UpsellPurchaseConfigurationContext = createContext<UpsellPurchaseConfiguration>(defaultPurchaseConfigurationValue)
/** Upsell purchase configuration context hook */
export const useUpsellPurchaseConfiguration = (): UpsellPurchaseConfiguration => useContext(UpsellPurchaseConfigurationContext)

/** Context provider for upsell purchase configuration */
export const UpsellPurchaseConfigurationContextProvider: FC<
  {
    children?: ReactNode
  }
  &
  UpsellPurchaseConfiguration
> = ({
  children,
  ...props
}) => {

    return (
      <UpsellPurchaseConfigurationContext.Provider value={props}>
        {children}
      </UpsellPurchaseConfigurationContext.Provider>
    )
  }
