import { BasementCeilingType, BasementType } from 'constants/purchaseFlow'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import React, { useState } from 'react'

import { GRAY_900 } from 'constants/styling/theme'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIFloatlNumberField } from 'components/common/MUIFloatNumberField'
import { MUINumberField } from 'components/common/MUINumberField'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useConstructionMethod } from './_main/ConstructionMethod.context'
import { useTranslation } from 'react-i18next'

/**
 * @component Basement renders options for the user to select the type of basement and ceiling/floor type.
 *
 * @example
 *   <Basement />
 */
export const Basement: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    basementType,
    setBasementType,
    basementCeilingType,
    setBasementCeilingFloorType,
    subsequentBasementInsulation,
    setSubsequentBasementInsulation,
    soilDepth,
    setSoilDepth,
    heatedBasementArea,
    setHeatedBasementArea,
  } = useConstructionMethod()

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  const isBasementTypeHeated = basementType === BasementType.HEATED_WINDOW_SOLAR_GAIN || basementType === BasementType.HEATED_NO_WINDOW

  return (
    <SectionedBorderBox
      title={
        < Typography variant='text-md' fontWeight={600} color={GRAY_900} >
          {t('basement_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.2} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          {/* BASEMENT/CELLAR */}
          <Stack gap="1.8rem" width={{ xs: '100%', lg: '32rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  label={t('basement_cellar')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!basementType ? `basement_type.${basementType}` : 'select_basement_cellar_option')}
                  onClick={action}
                />
              )}
            >
              {Object.values(BasementType).map(type => (
                <MUIDropdownItem key={type} onClick={() => setBasementType(type)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`basement_type.${type}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Stack>

          {/* BASEMENT CEILING FLOOR */}


          <Stack gap="1.8rem" width={{ xs: '100%', lg: '32rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  label={t('basement_ceiling_floor')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!basementCeilingType ? `basement_ceiling_floor_type.${basementCeilingType}` : 'select_basement_ceiling_option')}
                  onClick={action}
                />
              )}
            >
              {Object.values(BasementCeilingType).map(type => (
                <MUIDropdownItem key={type} onClick={() => setBasementCeilingFloorType(type)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`basement_ceiling_floor_type.${type}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Stack>


          {isBasementTypeHeated &&
            <>
              {/* DEPTH GROUND METERS */}
              <Stack marginTop={.8}>
                <MUIFloatlNumberField
                  required
                  suffix={'m'}
                  inputBoxWidth='100%'
                  label={t('soil_depth')}
                  value={soilDepth}
                  isError={showError && !soilDepth}
                  onBlur={handleOnInputBlur}
                  onChange={(value) => setSoilDepth(value ?? 0)}
                />
              </Stack>

              {/* AREA OF HEATED CELLAR IN SQUARE METERS */}
              <Stack marginTop={.8}>
                <MUINumberField
                  min={0}
                  required
                  suffix='m²'
                  inputBoxWidth='100%'
                  label={t('heated_basement_area')}
                  value={heatedBasementArea}
                  isError={showError && !heatedBasementArea}
                  onBlur={handleOnInputBlur}
                  onChange={(value) => setHeatedBasementArea(value ?? 0)}
                />
              </Stack>
            </>
          }

          {/* SUBSEQUENT INSULATION */}
          <Stack marginTop={.8}>
            <MUINumberField
              min={0}
              suffix={t('cm')}
              inputBoxWidth='100%'
              label={t('insulation_basement')}
              value={subsequentBasementInsulation}
              onChange={(value) => setSubsequentBasementInsulation(value)}
            />
          </Stack>

        </Stack>
      </Stack>

    </SectionedBorderBox>
  )
}
