import { FC, ReactElement } from 'react'

import { GOOGLE_API_KEY } from 'constants/API'
import { LoadScriptNext } from '@react-google-maps/api'
import { libraries } from '../AddressMap.context'

/**
 * Props for the LoadMapScriptWrapper component.
 * @interface Props
 */
export interface Props {
  /** The children components or content to be rendered - specific for wrapping Google Map and Autocomplete component. */
  children: ReactElement
  /** Language used to initialize the google script */
  language?: string
  /** Country code used to specify where to search for addresses */
  countryCode?: string
}

/**
 * @component
 * LoadMapScriptWrapper component for wrapping the Google map and Autocomplete to make address service work.
 * 
 * @example
 * <LoadMapScriptWrapper>
 *   <AddressMap />
 *   <Autocomplete />
 * </LoadMapScriptWrapper>
 */
export const LoadMapScriptWrapper: FC<Props> = ({
  children,
  language,
  countryCode
}) => (
  <LoadScriptNext
    googleMapsApiKey={GOOGLE_API_KEY}
    libraries={libraries}
    language={language}
    region={countryCode}
  >
    {children}
  </LoadScriptNext>
)
