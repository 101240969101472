import { FC, Fragment, useMemo, useState } from 'react'
import { useGalleryAssignment, useGalleryDeal } from '../../_main/contexts'

import { AssignmentChangelogPopup } from './AssignmentChangelog/AssignmentChangelogPopup.module'
import { AssignmentDTOIsAdministratorDTO } from 'utils/typeguards'
import { AssignmentDeliverySpeed } from 'models/assignment'
import { AssignmentLabels } from 'constants/assignment'
import { Badge } from 'components/common/Badges'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Button from 'components/common/Button/Button'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { ORANGE_100 } from 'constants/styling/theme'
import { Stack } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { getDateString } from 'utils/time/timeUtils'
import styles from './AssignmentDetailsCard.module.sass'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useSyncAssignment } from 'dataQueries'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties of AssignmentDetailsCard component
 */
interface Props {
  /** ID of the selected assignment */
  assignmentId: string
}

/**
 * Display assignment details card in the gallery sidebar for administrators.
 * 
 * @example <AssignmentDetailsCard assignmentId={102385} />
 */
export const AssignmentDetailsCard: FC<Props> = ({ assignmentId }) => {
  const { spawnSuccessToast } = useSnackbar()
  const { t } = useTranslation(['assignment_stage', 'deal_assignment', 'gallery', 'product_kind', 'schedule_meeting_popup'])

  const { assignmentData, assignmentStage } = useGalleryAssignment()
  const { dealId } = useGalleryDeal()

  const syncAssignment = useSyncAssignment()

  const [changelogPopup, setChangelogPopup] = useState(false)
  const assignmentAutomationStatus = useMemo(() => (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData)) ? assignmentData.assignmentAutomationStatus : undefined, [assignmentData])
  const product = useMemo(() => assignmentData?.products[0], [assignmentData])

  if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return <Fragment></Fragment>

  return (
    <div className="details noPadding">

      {/** Assignment Badges (stage and status) */}
      <div className={styles.wrapperBadges}>
        <Badge color="gray">{t(`assignment_stage:admin:${assignmentStage}`)}</Badge>
        {!!assignmentData &&
          <Fragment>
            {assignmentData.assignmentLabels.includes(AssignmentLabels.IMPORTANT) &&
              <Badge color="red">{t('deal_assignment:important_assignment')}</Badge>
            }
            {assignmentData.deliverySpeed === AssignmentDeliverySpeed.FAST &&
              <Badge color="blue">{t('deal_assignment:fast_delivery')}</Badge>
            }
            {assignmentData.scheduledByCT &&
              <Badge color="orange">{t('deal_assignment:scheduled_by_ct')}</Badge>
            }
          </Fragment>
        }
      </div>

      {/** Assignment description (product kind and shooting date) */}
      <div className={styles.description}>
        <h2>
          {product
            ? (
              product.quantity > 1 ? `${product.quantity} x ` : ''
            ) + t(`product_kind:${product.kind}`) + ' - ' + t(`product:p_${product.id}`)
            : 'N/A'
          }
        </h2>
        {!!assignmentData?.shootingStartDateTime && assignmentData?.shootingDuration > 0 &&
          <span className={styles.textDate}>
            {getDateString(assignmentData.shootingStartDateTime)}
          </span>
        }
      </div>

      {/* Late CT schedule */}
      {assignmentData.scheduledByCT && !!assignmentData.lateCtSchedulingReason &&
        <BorderBoxWrapper
          className={styles.lateSchedule}
          backgroundColor={ORANGE_100}
          elevation="none"
          borderColor={ORANGE_100}
          p={1}
          m={2}
        >
          <Stack gap={1}>

            <h4>{t('deal_assignment:scheduled_late')}</h4>

            <p>{t('deal_assignment:reason')}: <span className={styles.darker}>{t(`deal_assignment_card:schedule_meeting_popup.reasons.${assignmentData.lateCtSchedulingReason}`)}</span></p>

            {!!assignmentData.lateCtSchedulingComment &&
              <BorderBoxWrapper elevation="none" p={1}>
                <p>{assignmentData.lateCtSchedulingComment}</p>
              </BorderBoxWrapper>
            }

          </Stack>
        </BorderBoxWrapper>
      }

      <div className={styles.wrapperFooter}>

        {/** Changelog */}
        {!!assignmentAutomationStatus && !!assignmentStage &&
          <Fragment>
            <Button
              textAndIcon={true}
              className={styles.customButton}
              onClick={() => setChangelogPopup(true)}
            >
              <FormatListBulletedIcon fontSize="large" />
              <span>{t('gallery:showcase_changelog')}</span>
            </Button>
            <AssignmentChangelogPopup
              isOpen={changelogPopup}
              assignmentId={assignmentId}
              onClickOutside={() => setChangelogPopup(false)}
              onClickClose={() => setChangelogPopup(false)}
            />
          </Fragment>
        }

        {/** Sync assignment */}
        <Button
          textAndIcon={true}
          className={styles.customButton}
          disabled={syncAssignment.isPending}
          isLoading={syncAssignment.isPending}
          onClick={() => syncAssignment.mutate({ assignmentId, orderId: dealId }, {
            onSuccess: () => {
              spawnSuccessToast(t('deal_assignment:assignment_synchronized'))
            }
          })}
        >
          <SyncIcon fontSize="large" />
          <span>{t('deal_assignment:sync_assignment')}</span>
        </Button>

      </div>

    </div>
  )
}
