import { AxiosError, AxiosResponse } from 'axios'

/**
 * Function returning Promise that resolves after specified time and can be awaited as a delay in async fnc
 * @param ms - How many milliseconds will take the Promise to get resolved
 * @returns Promise
 */
export const waitFor = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * Function that will call request until returns success or until retryLimit is reached with specified delay in between calls
 * @param fnc - Function which should perform axios call and return Promise with AxiosResponse
 * @param retryDelay - How long delay should be between calls in milliseconds (default 500)
 * @param retryLimit - How many retries are allowed (default 30)
 * @returns Either AxiosResponse or AxiosError
 */
export async function retryRequestUntilSuccess<T = unknown>(fnc: () => Promise<AxiosResponse<T>>, retryDelay = 500, retryLimit = 30) {

  let retryCount = 0
  let lastError: AxiosError

  do {
    try {
      const result = await fnc()
      return result
    } catch (err: unknown) {
      lastError = err as AxiosError
    }

    await waitFor(retryDelay)

    retryCount++
  } while (retryCount < retryLimit)

  return lastError
}
