import { EntityKeys, QueryType } from 'utils/reactQuery'
import { PageRealEstatePropertyListItemDTO, RealEstatePropertyDTO } from 'models/property'

import { AxiosError } from 'axios'
import { Nullable } from 'models/helpers'
import { PropertyEndpoint } from 'constants/API'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

// -- QUERIES
export function useGetPropertyDetail(propertyId: Nullable<string>, workspaceId: Nullable<string>, allowRetry = true) {
  const api = useAPI<PropertyEndpoint>()

  return useQuery<RealEstatePropertyDTO, AxiosError>({
    queryKey: [EntityKeys.PROPERTY, QueryType.GET_ONE, { propertyId: propertyId!, workspaceId: workspaceId! }],
    queryFn: () => api.get<RealEstatePropertyDTO>(
      PropertyEndpoint.GET_PROPERTY_DETAIL,
      {
        workspaceId: workspaceId!,
        propertyId: propertyId!,
      },
      false
    ),
    enabled: !!propertyId && !!workspaceId,
    retry: allowRetry,
  })
}

export function useGetPropertyDetailWithObjectReferenceId(objectReferenceId: Nullable<string>, workspaceId: Nullable<string>, allowRetry = true) {
  const api = useAPI<PropertyEndpoint>()

  return useQuery<RealEstatePropertyDTO, AxiosError>({
    queryKey: [EntityKeys.PROPERTY, QueryType.GET_ONE, { objectReferenceId: objectReferenceId!, workspaceId: workspaceId! }],
    queryFn: () => api.get<RealEstatePropertyDTO>(
      PropertyEndpoint.GET_PROPERTY_DETAIL_WITH_OBJECT_REFERENCE,
      {
        workspaceId: workspaceId!,
        objectReferenceId: objectReferenceId!,
      },
      false
    ),
    enabled: !!objectReferenceId && !!workspaceId,
    retry: allowRetry,
  })
}

export function useGetSearchProperty(workspaceId: string, searchText: string) {
  const api = useAPI<PropertyEndpoint>()

  return useQuery({
    queryKey: [EntityKeys.PROPERTY, QueryType.LIST, { workspaceId, searchText }],
    queryFn: () => api.get<PageRealEstatePropertyListItemDTO>(
      PropertyEndpoint.GET_SEARCH_PROPERTY,
      { workspaceId },
      true,
      {
        params: {
          searchText
        },
      }
    ),
    enabled: false
  })
}
