import { FC, ReactNode } from 'react'

import { Workspace } from '../Workspace'
import styles from './PageLayout.module.sass'

interface Props {
  controlPanel?: JSX.Element
  children?: ReactNode
}

/**
 * Layout component for organizing basic page setup.
 * Children are displayed in Workspace which automatically handles scrolling.
 * @param controlPanel - Accepts JSX element and displays it as a left static sidebar. If nothing provided, no sidebar is rendered and workspace takes the entire width.
 * @example
 *  <PageLayout controlPanel={<ControlPanel title="Page title" />}>
 *    -workspace content-
 *  </PageLayout>
 */
export const PageLayout: FC<Props> = ({
  controlPanel,
  children,
}) => {

  return (
    <div className={styles.pageLayout}>

      {!!controlPanel &&
        <div className={styles.controlPanelWrapper}>
          {controlPanel}
        </div>
      }

      <div className={styles.workspaceWrapper}>
        <Workspace>
          {children}
        </Workspace>
      </div>

    </div>
  )
}
