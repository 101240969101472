import { BEIGE_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, useCallback } from 'react'
import { Fade, Typography } from '@mui/material'

import { AdditionalInformation } from 'components/pages/PurchaseFlow/common/AdditionalInformation'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { BuildingLayout } from '../BuildingLayout/_main/BuildingLayout.component'
import { ConstructionMethod } from '../ConstructionMethod/_main/ConstructionMethod.component'
import { InformationType } from 'constants/purchaseFlow'
import { PropertyInformation } from '../PropertyInformation/PropertyInformation.component'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import { ToggleButton } from 'components/common/ToggleButton'
import { VentilationAndHeating } from '../VentilationAndHeating/_main/VentilationAndHeating.component'
import { useEnergyCertificateStep } from './EnergyCertificateStep.context'
import { useTranslation } from 'react-i18next'

/**
 * Controller for the energy certificate requirements step in the Purchase Flow.
 * 
 * @example
 * <EnergyCertificateStepController />
 */
export const EnergyCertificateStepController: FC = () => {
  const { t } = useTranslation(['purchase_flow'])

  const {
    infoType,
    setInfoType,
    isBuildingLayoutValid,
    isConstructionMethodValid,
    isEnergyAndHeatingValid,
    isAdditionalInformationValid,
    isPropertyInformationValid
  } = useEnergyCertificateStep()

  const isInformationToggled = useCallback((infoType: InformationType) => {
    switch (infoType) {
      case InformationType.PROPERTY_INFORMATION:
        return isPropertyInformationValid
      case InformationType.BUILDING_LAYOUT:
        return isBuildingLayoutValid
      case InformationType.CONSTRUCTION_METHOD:
        return isConstructionMethodValid
      case InformationType.ENERGY_AND_HEATING:
        return isEnergyAndHeatingValid
      case InformationType.ADDITIONAL_INFORMATION:
        return isAdditionalInformationValid
      default:
        return false
    }
  }, [isPropertyInformationValid, isBuildingLayoutValid, isConstructionMethodValid, isEnergyAndHeatingValid, isAdditionalInformationValid])

  return (
    <StepWrapper
      title={t('energy_certificate_step.section_title')}
      subtitle={t('energy_certificate_step.section_description')}
    >
      <Fade in={true}>
        <Stack gap="1.6rem" width="100%" display="flex" justifyContent="space-between">

          <BorderBoxWrapper backgroundColor={BEIGE_500} padding="2.4rem">
            <Stack gap="2.4rem" display="column" flexWrap="nowrap" alignItems="center" justifyContent="center">

              <Stack gap=".4rem" alignItems="center">
                <Typography variant="text-md" fontWeight={600} color={GRAY_900}>{t('energy_certificate_step.information_fill')}</Typography>
                <Typography variant="text-sm" fontWeight={400} color={GRAY_700}>{t('energy_certificate_step.information_fill_description')}</Typography>
              </Stack>

              <Stack gap=".8rem" flexDirection="row" flexWrap="wrap">
                {Object.values(InformationType).map((type) => (
                  <ToggleButton
                    key={type}
                    isToggled={isInformationToggled(type)}
                    isSelected={infoType === type}
                    label={t(`energy_certificate_step.${type.toLowerCase()}`)}
                    sx={{ width: 'fit-content', justifyContent: 'center' }}
                    onClick={() => setInfoType(type)}
                  />
                ))}
              </Stack>

            </Stack>
          </BorderBoxWrapper>

          {infoType === InformationType.PROPERTY_INFORMATION && <PropertyInformation />}

          {infoType === InformationType.BUILDING_LAYOUT && <BuildingLayout />}

          {infoType === InformationType.CONSTRUCTION_METHOD && <ConstructionMethod />}

          {infoType === InformationType.ENERGY_AND_HEATING && <VentilationAndHeating />}

          {infoType === InformationType.ADDITIONAL_INFORMATION && <AdditionalInformation allowConstructionUpload />}

        </Stack>
      </Fade>
    </StepWrapper>
  )
}
