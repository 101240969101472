import { cloneDeep, merge } from 'lodash'

import { RecursivePartial } from 'models/helpers'
import { Reducer } from 'react'
import { FileAPIEntry } from './fileAPI.types'

export type FileAPIInventory = Record<string, Record<string, FileAPIEntry>>

/** Possible actions for FileAPI reducer */
enum FileAPIActionType {
  ADD_FILE = 'ADD_FILE',
  REMOVE_FILE = 'REMOVE_FILE',
  UPDATE_FILE = 'UPDATE_FILE',
  SET_FILES = 'SET_FILES',
  INIT_SCOPE = 'INIT_SCOPE',
  PURGE_SCOPE = 'PURGE_SCOPE',
}

/** Describes FileAPI inventory action */
interface FileAPIAction {
  type: FileAPIActionType
}

/** Action for adding room into inventory scope */
export class AddEntryAction implements FileAPIAction {
  readonly type = FileAPIActionType.ADD_FILE
  constructor(
    public scope: string,
    public id: string,
    public file: FileAPIEntry,
    public duplicityPolicy: 'skip' | 'overwrite'
  ) { }
}

/** Action for removing file from inventory scope */
export class RemoveEntryAction implements FileAPIAction {
  readonly type = FileAPIActionType.REMOVE_FILE
  constructor(public scope: string, public id: string) { }
}

/** Action for updating file in inventory scope */
export class UpdateEntryAction implements FileAPIAction {
  readonly type = FileAPIActionType.UPDATE_FILE
  constructor(
    public scope: string,
    public id: string,
    public fileData: RecursivePartial<FileAPIEntry>
  ) { }
}

/** Action to initialize scope within inventory */
export class InitScopeAction implements FileAPIAction {
  readonly type = FileAPIActionType.INIT_SCOPE
  constructor(public scope: string) { }
}

/** Action to remove scope within inventory */
export class PurgeScopeAction implements FileAPIAction {
  readonly type = FileAPIActionType.PURGE_SCOPE
  constructor(public scope: string) { }
}

/** Action for setting content of entire FileAPI inventory */
export class SetAction implements FileAPIAction {
  readonly type = FileAPIActionType.SET_FILES
  constructor(public scope: string, public scopeInventory: Record<string, FileAPIEntry>) { }
}

/** Union type of possible actions */
type FileAPIActions = AddEntryAction | RemoveEntryAction | UpdateEntryAction | SetAction | InitScopeAction | PurgeScopeAction

/** Reducer function that handles updating inventory state according to dispatched actions */
export const reduceFileAPIInventory: Reducer<FileAPIInventory, FileAPIActions> = (state = {}, action) => {

  const newState = cloneDeep(state)

  switch (action.type) {
    case FileAPIActionType.ADD_FILE: {
      if (!state[action.scope]?.[action.id] || action.duplicityPolicy === 'overwrite') {

        return {
          ...newState,
          [action.scope]: {
            ...(newState[action.scope] || {}),
            [action.id]: action.file
          }
        }
      }

      return newState
    }

    case FileAPIActionType.REMOVE_FILE: {
      const { [action.id]: _, ...prunedFiles } = newState[action.scope]

      return {
        ...newState,
        [action.scope]: prunedFiles,
      }
    }

    case FileAPIActionType.UPDATE_FILE: {
      if (!newState[action.scope]?.[action.id]) return newState

      // Using _.merge to correctly merge changes in metadata, which could even have more nested values
      const newFile: FileAPIEntry = merge(
        newState[action.scope][action.id],
        action.fileData
      )

      if (!!action.fileData.id && action.fileData.id !== action.id) {
        delete newState[action.scope][action.id]

        return {
          ...newState,
          [action.scope]: {
            ...newState[action.scope],
            [action.fileData.id]: newFile
          }
        }

      } else {
        return {
          ...newState,
          [action.scope]: {
            ...newState[action.scope],
            [action.id]: newFile
          }
        }
      }
    }

    case FileAPIActionType.SET_FILES:
      return {
        ...newState,
        [action.scope]: cloneDeep(action.scopeInventory)
      }

    case FileAPIActionType.INIT_SCOPE:
      return {
        ...newState,
        [action.scope]: {}
      }

    case FileAPIActionType.PURGE_SCOPE: {
      delete newState[action.scope]

      return newState
    }

    default:
      return newState
  }
}
