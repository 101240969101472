import { PropertyListParams, useInfiniteListProperties } from 'dataQueries'

import { GalleryHeader } from 'components/common/Gallery/GalleryHeader'
import { InfiniteList } from 'components/common/InfiniteList/InfiniteList.component'
import { PageContent } from 'components/common/Page/PageContent'
import { PageHeader } from 'components/common/Page/PageHeader'
import { PageLayout } from 'components/common/Page/PageLayout'
import { PageTransition } from 'utils/animations'
import { Params } from 'constants/misc'
import { PropertiesDashboardFilter } from '../PropertiesDashboardFilter'
import { PropertiesDashboardPageAction } from '../PropertiesDashboardPageAction'
import { PropertiesDashboardTable } from '../PropertiesDashboardTable'
import { PropertiesDashboardTableRow } from '../PropertiesDashboardTableRow'
import { RealEstatePropertyListItemDTO } from 'models/property'
import { Stack } from '@mui/material'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useDebouncedMemo } from 'utils/helpers'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Page listing properties of logged in client with search and filter options.
 * @example <PropertiesDashboardController />
 */
export const PropertiesDashboardController: React.FC = () => {
  const { t } = useTranslation(['properties_dashboard'])
  const params = useBkbnSearchParams()

  const { userPropertyWorkspaceId } = useUserData()

  // SEARCH DEBOUNCE HANDLING
  const search = useMemo(() => params.get(Params.SEARCH), [params])
  const debouncedSearch = useDebouncedMemo(() => search, [search], 300)

  // PARAMS
  const propertiesParams = useMemo<PropertyListParams>(() => ({
    searchText: debouncedSearch || 'Mettlacher Str',  // TODO: Remove default value when BE update is done
    size: 25,
  }), [debouncedSearch])

  // PROPERTY LIST
  const propertyList = useInfiniteListProperties(userPropertyWorkspaceId, propertiesParams)

  return (
    <PageTransition>
      <PageLayout
        header={
          <PageHeader
            actions={<PropertiesDashboardPageAction />}
          >
            <GalleryHeader title={t('properties')} />
          </PageHeader>
        }
        layoutErrorContent={null}
      >
        <Stack width="100%" alignItems="center" justifyContent="center">
          <PageContent>
            <Stack sx={{ overflowY: 'auto', padding: '4rem 0' }}>

              <PropertiesDashboardFilter />

              <InfiniteList<RealEstatePropertyListItemDTO>
                query={propertyList}
                customRenderItem={(data) => (
                  <PropertiesDashboardTable>
                    {data.map(item => <PropertiesDashboardTableRow key={item.id} property={item} />)}
                  </PropertiesDashboardTable>
                )}
                sx={{
                  boxSizing: 'border-box',
                  width: '100%',
                  overflow: 'hidden',
                  borderRadius: '.8rem',
                }}
              />

            </Stack>
          </PageContent>
        </Stack>
      </PageLayout>
    </PageTransition>
  )
}
