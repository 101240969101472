import { CORAL_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { IconButton, Stack, Typography } from '@mui/material'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { useMemo } from 'react'
import { usePurchaseFlowRealEstateProperty } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @component SearchProperty component in Address step
 * @example <SearchProperty />
 */
export const SearchProperty: React.FC = () => {
  const { t } = useTranslation('order', { keyPrefix: 'step_address' })

  const {
    searchProperty,
    setSearchProperty,
    selectedProperty,
    setSelectedProperty,
    searchPropertyData,
    resetRealEstateProperty,
    getSearchProperty
  } = usePurchaseFlowRealEstateProperty()

  const selectedPropertyAdress = useMemo(() => {
    if (!selectedProperty) return {}

    return {
      street: selectedProperty?.address.street,
      streetNumber: selectedProperty?.address.streetNumber,
      city: selectedProperty?.address.city,
      postalCode: selectedProperty?.address.postalCode,
      country: selectedProperty?.address.country,
    }
  }, [selectedProperty])

  return (
    <>
      <MUIDropdown
        sx={{ width: '100%' }}
        button={(isOpen, action) => (
          <MUIDropdownInput
            label={t('search_property')}
            type={DropdownInputTypes.SELECTOR}
            value={searchProperty}
            onClick={action}
            onBlur={() => searchProperty && getSearchProperty.refetch()}
            onChange={e => setSearchProperty(e.target.value)}
          />
        )}
      >
        {!searchPropertyData || searchPropertyData.empty ?
          <MUIDropdownItem>
            <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
              {t('empty_property')}
            </Typography>
          </MUIDropdownItem>
          :
          <BorderBoxWrapper
            alignContent="center"
            padding=".4rem"
            width="100%"
            sx={{ cursor: 'pointer' }}
          >
            {searchPropertyData?.content.map(property => (
              <Stack
                gap=".2rem"
                padding="1.2rem"
                key={property.id}
                onClick={() => {
                  setSearchProperty(property.address.street)
                  setSelectedProperty(property)
                }}
              >

                <Typography variant="text-sm" color={GRAY_900} fontWeight="semiBold">
                  {property?.title ?? property?.address.street}
                </Typography>

                <Stack gap=".2rem" flexDirection="row">

                  <Typography variant="text-xs" color={GRAY_900} fontWeight="semiBold">
                    {property.address.street} {property.address.streetNumber},
                  </Typography>

                  <Typography variant="text-xs" color={GRAY_700}>
                    {property.address.city}, {property.address.postalCode}, {property.address.country}
                  </Typography>

                </Stack>

              </Stack>
            ))}
          </BorderBoxWrapper>
        }
      </MUIDropdown>

      {selectedProperty &&
        <BorderBoxWrapper
          height="7.2rem"
          alignContent="center"
          padding="1.2rem"
        >
          <Stack gap="1.2rem" flexDirection="row" justifyContent="space-between">

            <Stack gap=".2rem">

              <Typography variant="text-sm" color={GRAY_900} fontWeight="semiBold">
                {selectedProperty?.title ?? selectedProperty?.address.street}
              </Typography>

              <Stack gap=".2rem" flexDirection="row">

                <Typography variant="text-xs" color={GRAY_900} fontWeight="semiBold">
                  {selectedPropertyAdress.street} {selectedPropertyAdress.streetNumber},
                </Typography>

                <Typography variant="text-xs" color={GRAY_700}>
                  {selectedPropertyAdress.city}, {selectedPropertyAdress.postalCode}, {selectedPropertyAdress.country}
                </Typography>

              </Stack>

            </Stack>

            <IconButton
              size='medium'
              onClick={resetRealEstateProperty}
            >
              <DeleteOutlineRoundedIcon fontSize='large' sx={{ color: CORAL_500 }} />
            </IconButton>

          </Stack>
        </BorderBoxWrapper>
      }
    </>
  )
}
