import { GlobalShapeType, Wall } from 'constants/purchaseFlow'

export const shapeWallSettings: Record<GlobalShapeType, { target: string; source: string; subtract?: string | string[] }[]> = {
  [GlobalShapeType.RECTANGLE]: [
    { target: 'C', source: 'A' },
    { target: 'D', source: 'B' }
  ],
  [GlobalShapeType.L_SHAPE]: [
    { target: 'E', source: 'C', subtract: 'A' },
    { target: 'F', source: 'B', subtract: 'D' }
  ],
  [GlobalShapeType.T_SHAPE]: [
    { target: 'F', source: 'D' },
    { target: 'G', source: 'C' },
    { target: 'H', source: 'B' }
  ],
  [GlobalShapeType.U_SHAPE]: [
    { target: 'G', source: 'A' },
    { target: 'H', source: 'D', subtract: ['F', 'B'] }
  ],
  [GlobalShapeType.OTHER]: []
}

/**
 * Calculates the lengths of walls based on the global shape type and updates the wall lengths accordingly.
 * 
 * The function supports different shape types and updates the wall lengths based on specific rules:
 * 
 * - **Rectangle**: 
 *   - `c = a`
 *   - `d = b`
 *   - Disable input for `c` and `d` through the disabled property in component.
 * 
 * - **LShape**: 
 *   - `e = c - a`
 *   - `f = b - d`
 *   - Disable input for `e` and `f` through the disabled property in component.
 * 
 * - **TShape**: 
 *   - `f = d`
 *   - `g = c`
 *   - `h = b`
 *   - Disable input for `f`, `g`, and `h` through the disabled property in component.
 * 
 * - **UShape**: 
 *   - `g = a`
 *   - `h = d - f - b`
 *   - Disable input for `g` and `h` through the disabled property in component.
 * 
 * @param globalShapeType - The type of the global shape (e.g., Rectangle, LShape, TShape, UShape).
 * @param walls - An object containing wall properties, including length, mounted status, insulation status, etc.
 * @returns An updated object with recalculated wall lengths based on the specified shape type.
 */
export const calculateWallLengths = (globalShapeType: GlobalShapeType, walls: Partial<Record<Wall, {
  mounted: boolean
  insulated: boolean
  length: string
  insulation: string
  readonly?: boolean
}>> & Record<string, any>) => {
  const updatedWalls = { ...walls }

  const settings = shapeWallSettings[globalShapeType] || []

  settings.forEach(({ target, source, subtract }: { target: string; source: string; subtract?: string | string[] }) => {
    const sourceLength = parseFloat(walls[source]?.length || '')
    const subtractLength = Array.isArray(subtract)
      ? subtract.reduce((acc, key) => acc + parseFloat(walls[key]?.length || ''), 0)
      : subtract ? parseFloat(walls[subtract]?.length || '') : 0

    updatedWalls[target] = {
      ...walls[target],
      length: !isNaN(sourceLength - subtractLength) ? (sourceLength - subtractLength).toString() : '',
      mounted: walls[target]?.mounted || false,
      insulated: walls[target]?.insulated || false,
      insulation: walls[target]?.insulation || ''
    }
  })

  return updatedWalls
}
