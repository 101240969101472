import { BuildingLocationType, BuildingType } from 'constants/purchaseFlow'

import { Nullable } from 'models/helpers'
import constate from 'constate'
import { useState } from 'react'

export const [PropertyInformationContextProvider, usePropertyInformation] = constate(() => {
  const [buildingType, setBuildingType] = useState<Nullable<BuildingType>>(null)
  const [buildingLocationType, setBuildingLocationType] = useState<Nullable<BuildingLocationType>>(null)
  const [residentialUnit, setResidentialUnit] = useState<number>(1)
  const [commercialArea, setCommercialArea] = useState('')
  const [commercialAreaType, setCommercialAreaType] = useState('')
  const [constructionYear, setConstructionYear] = useState('')
  const [livingSpace, setLivingSpace] = useState('')

  return {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    livingSpace,
    setLivingSpace,
    commercialArea,
    setCommercialArea,
    commercialAreaType,
    setCommercialAreaType,
  }
})
