import { ActionPopupOptions, ActionPopupTypes } from './actionPopup.interfaces'

import { ActionString } from 'models/redux'
import { ActionStringType } from 'constants/redux'
import { ReactNode } from 'react'
import { createStringAction } from 'redux/Helpers'

/** Describes action which opens action popup of provided type with provided configuration */
export interface ActionOpenActionPopup extends ActionString {
  payload: {
    body: string | ReactNode
    type: ActionPopupTypes
    options?: ActionPopupOptions
  }
}

/** Creates the action for opening action popup*/
export function openActionPopup(
  body: string | ReactNode,
  type: ActionPopupTypes,
  options?: ActionPopupOptions
): ActionOpenActionPopup {
  return createStringAction(ActionStringType.OPEN_ACTION_POPUP, {
    body,
    type,
    options
  })
}

/** Describes action which closes opened action popup */
export interface ActionCloseActionPopup extends ActionString {
  payload: {}
}

/** Creates the action for closing action popup */
export function closeActionPopup(): ActionCloseActionPopup {
  return createStringAction(ActionStringType.CLOSE_ACTION_POPUP, {})
}
