import { CreatePlatformUserBody, PlatformUser, PlatformUserStatus, UserWorkspaceMembershipList } from 'models/user'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { RoleMimetype } from 'constants/API'
import { InstructionOptionDTO } from 'models/purchaseFlow'
import { useDispatch } from 'react-redux'
import { updateUserMeStoreData } from 'redux/Individual/User/UserMe'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAPI } from 'utils/API'

export enum UserQueryKeys {
  AVAILABLE_BILLING_PRODUCTS = 'AVAILABLE_BILLING_PRODUCTS',
  STRIPE_PAYMENT_METHOD = 'STRIPE_PAYMENT_METHOD'
}

enum Endpoints {
  GET_USER_AVAILABLE_BILLING_PRODUCTS = '/user/availableBillingProducts',
  GET_USER_CLIENT = '/user/client',
  GET_USER_WORKSPACES = '/user/workspace',
  RESEND_CHANGE_EMAIL = '/user/me/email/resendVerification',
  RESET_PASSWORD = '/user/resetPassword',
  TOGGLE_CT_STATUS = '/user/me/enabled',
  UPDATE_RECEIPT_EMAIL = '/user/billingEmail?billingEmail={billingEmail}',
  UPDATE_VAT_NUMBER = '/user/vatNumber?vatNumber={vatNumber}',
  UPDATE_USER_CC_EMAILS = '/user/ccEmails',
  USER_CHANGE_EMAIL = '/user/me/email',
  USER_CREATE = '/user/',
  USER_DELETE_SAVED_STRIPE_PAYMENT_METHODS = '/user/savedPaymentMethods/{stripeId}',
  USER_VERIFY = '/user/verify',
  USER_VERIFY_EMAIL = '/user/verifyEmail?token={token}',
  USER_STATUS = '/user/status'
}

// QUERIES

export function useGetUserWorkspaces() {
  const api = useAPI<Endpoints>()

  return useQuery<UserWorkspaceMembershipList | null>({
    queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE],
    queryFn: () => api.get<UserWorkspaceMembershipList | null>(
      Endpoints.GET_USER_WORKSPACES,
      {},
      false
    )
  })
}

export function useUserStatus(email?: string) {
  const api = useAPI<Endpoints>()

  return useQuery<PlatformUserStatus | null>({
    queryKey: [EntityKeys.USER_STATUS, QueryType.GET],
    queryFn: () => api.get<PlatformUserStatus | null>(
      Endpoints.USER_STATUS,
      {},
      false,
      {
        params: {
          email
        },
        headers: {
          Accept: email ? RoleMimetype.ADMIN : RoleMimetype.CLIENT
        }
      }
    )
  })
}

export function useGetAvailableBillingProducts(enabled?: boolean) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.USER_WORKSPACE, QueryType.GET, UserQueryKeys.AVAILABLE_BILLING_PRODUCTS],
    queryFn: () => api.get<InstructionOptionDTO[]>(
      Endpoints.GET_USER_AVAILABLE_BILLING_PRODUCTS,
      {},
      false
    ),
    enabled,
  })
}

// MUTATIONS
export function useGetUserClient$() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<PlatformUser>, { email: string }>(
    ({ email }) => api.get(
      Endpoints.GET_USER_CLIENT,
      {},
      true,
      {
        params: {
          email,
        }
      }
    )
  )
}

export const useToggleLoggedInCTStatus = () => {
  const api = useAPI<Endpoints>()
  const dispatch = useDispatch()

  return getMutation<PlatformUser, { enabled: boolean }>(
    ({ enabled }) => api.put(
      Endpoints.TOGGLE_CT_STATUS,
      {},
      undefined,
      false,
      { params: { value: enabled } }
    ),
    // [TODO-RQ BKBN-285] invalidate query instead of dispatch after calling userMe via react-query
    (_, __, { data }) => {
      dispatch(updateUserMeStoreData(data ?? {}))
    }
  )
}

export const useChangeEmail = () => {
  const api = useAPI<Endpoints>()

  return getMutation<{}, { email: string }>(
    ({ email }) => api.post(
      Endpoints.USER_CHANGE_EMAIL,
      {},
      { email },
      false
    )
  )
}

export const useResendChangeEmail = () => {
  const api = useAPI<Endpoints>()

  return getMutation(
    () => api.post(
      Endpoints.RESEND_CHANGE_EMAIL,
      {},
      {},
      false
    )
  )
}

export const useVerifyEmail = () => {
  const api = useAPI<Endpoints>()

  return getMutation<{}, { token: string }>(
    ({ token }) => api.post(
      Endpoints.USER_VERIFY_EMAIL,
      { token },
      {},
      false
    )
  )
}

export function useUpdateReceiptEmail() {
  const api = useAPI<Endpoints>()
  const dispatch = useDispatch()

  return getMutation<AxiosResponse<PlatformUser>, { email: string, callUpdateUserMe: boolean }>(
    ({ email }) => api.put(
      Endpoints.UPDATE_RECEIPT_EMAIL,
      { billingEmail: email },
      {},
      true
    ),
    (_, { callUpdateUserMe }, { data }) => {
      if (callUpdateUserMe) {
        dispatch(updateUserMeStoreData(data ?? {}))
      }
    }
  )
}

/** Mutation for resetting password on user profile. */
export function useResetPassword() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<{ status: string }>, {}>(
    () => api.get(
      Endpoints.RESET_PASSWORD,
      {},
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
        }
      }
    )
  )
}

/** Mutation for verifying user profile. */
export function useVerifyUser() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<{ status: string }>, {}>(
    () => api.get(
      Endpoints.USER_VERIFY,
      {},
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT,
        }
      }
    )
  )
}

/** Mutation for updating VAT number on user profile. */
export function useUpdateVatNumber() {
  const api = useAPI<Endpoints>()
  const dispatch = useDispatch()

  return getMutation<AxiosResponse<PlatformUser>, { vatNumber: string }>(
    ({ vatNumber }) => api.put(
      Endpoints.UPDATE_VAT_NUMBER,
      { vatNumber },
      {},
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
        }
      }
    ), (_, __, { data }) => {
      // [TODO-RQ BKBN-285] invalidate query instead of dispatch after calling userMe via react-query
      dispatch(updateUserMeStoreData(data ?? {}))
    }
  )
}

/** Mutation for updating user cc emails on user profile. */
export function useUpdateCcEmails() {
  const api = useAPI<Endpoints>()
  const dispatch = useDispatch()

  return getMutation<AxiosResponse<PlatformUser>, { ccEmails: string[] }>(
    ({ ccEmails }) => api.put(
      Endpoints.UPDATE_USER_CC_EMAILS,
      {},
      ccEmails,
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
        }
      }
    ), (_, __, { data }) => {
      // [TODO-RQ BKBN-285] invalidate query instead of dispatch after calling userMe via react-query
      dispatch(updateUserMeStoreData(data ?? {}))
    }
  )
}

interface CreateUserPayload extends CreatePlatformUserBody {
  refreshUserCallback: () => void
}

/** Mutation for creating a new user (client) account in the system. */
export function useCreateUser() {
  const api = useAPI<Endpoints>()
  const dispatch = useDispatch()

  return getMutation<AxiosResponse<PlatformUser>, CreateUserPayload>(
    ({ refreshUserCallback, ...body }) => api.post(
      Endpoints.USER_CREATE,
      {},
      { ...body },
      false,
      {
        // When creating a new user the current role is undefined so we set the client role
        headers: {
          Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
        }
      }
    ),
    (_, { refreshUserCallback, ...data }) => {
      dispatch(updateUserMeStoreData(data ?? {}))
      // Refresh user on success
      refreshUserCallback()
    },
    {
      // Error or success... doesn't matter!
      onSettled: (data) => {
        logAnalyticsEvent('sign_up', { ...data })
      }
    }
  )
}
