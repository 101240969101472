import { AddEntryAction, FileAPIInventory, InitScopeAction, PurgeScopeAction, RemoveEntryAction, UpdateEntryAction, reduceFileAPIInventory } from './fileInventoryUtils'
import { FileAPIEntry, FileAction, FileDeleteFnc, FileState, LoadUrlResolver, ReUploadOptions, UploadOptions, UploadUrlResolver } from './fileAPI.types'
import { retryRequestUntilSuccess, useAPI } from 'utils/API'
import { startLoadWorker, uploadToGC } from './fileUtils'
import { useCallback, useMemo, useReducer, useRef } from 'react'

import { RecursivePartial } from 'models/helpers'
import { UrlDTO } from 'models/visuals'
import axios from 'axios'
import constate from 'constate'
import { uniqueId } from 'lodash'

function* getOrderGenerator() {
  let index = 0
  while (true) {
    yield index++
  }
}

export const [FileAPIProvider, useFileAPIController] = constate(() => {
  const [fileInventory, dispatch] = useReducer(reduceFileAPIInventory, {})

  const inventoryRef = useRef<FileAPIInventory>({})
  inventoryRef.current = fileInventory

  const getNewIndex = useMemo(() => getOrderGenerator(), [])

  const createFileAPIEntry = useCallback(<MetadataType extends { [key: string]: any }>(overrides?: Partial<FileAPIEntry<MetadataType>>): FileAPIEntry<MetadataType> => {
    return {
      id: uniqueId('file-entry:'),
      state: FileState.IDLE,
      action: FileAction.INIT,
      progress: 0,
      fileObject: new File([], ''),
      abortController: new AbortController(),
      signedUrl: null,
      displayUrl: null,
      originalFilename: null,
      gcFilename: null,
      tag: null,
      error: null,
      order: getNewIndex.next().value as number,
      metadata: {} as MetadataType,
      ...overrides,
    }
  }, [getNewIndex])

  const api = useAPI<string>()

  /** Updated file entry with partially updated data */
  const updateFileEntry = <MetadataType extends { [key: string]: any }>(scope: string, id: string, fileUpdates: RecursivePartial<FileAPIEntry<MetadataType>>) => {
    dispatch(new UpdateEntryAction(scope, id, fileUpdates))
  }

  /** Adds file entry into scope */
  const addFileEntry = <MetadataType extends { [key: string]: any }>(scope: string, id: string, file: FileAPIEntry<MetadataType>, duplicityPolicy: 'skip' | 'overwrite' = 'skip') => {
    dispatch(new AddEntryAction(scope, id, file, duplicityPolicy))
  }

  /** Removes file entry from scope */
  const removeFileEntry = (scope: string, id: string) => {
    dispatch(new RemoveEntryAction(scope, id))
  }

  /** Creates new empty scope in inventory */
  const initScope = (scope: string) => {
    dispatch(new InitScopeAction(scope))
  }

  /** Removes scope from inventory */
  const purgeScope = (scope: string) => {
    dispatch(new PurgeScopeAction(scope))
  }

  /** Adds all provided files to scope - mostly utility now, subject to change or be privatized */
  const initFiles = <MetadataType extends { [key: string]: any }>(scope: string, files: Array<FileAPIEntry<MetadataType>>) => {
    const addedEntries: Record<string, FileAPIEntry<MetadataType>> = {}

    for (const file of files) {

      dispatch(new AddEntryAction(
        scope,
        file.id,
        file,
        'overwrite'
      ))

      addedEntries[file.id] = file
    }

    return addedEntries
  }

  /** Calls delete fnc handler for each file and removes entry from scope if successful */
  async function deleteFiles<MetadataType extends { [key: string]: any }>(scope: string, ids: string[], deleteFnc: FileDeleteFnc<MetadataType>) {
    for (const id of ids) {
      let fileEntry = fileInventory?.[scope]?.[id] as FileAPIEntry<MetadataType> | undefined
      if (!fileEntry) continue

      updateFileEntry(scope, id, { state: FileState.RUNNING, action: FileAction.DELETE, progress: 0 })

      deleteFnc(fileEntry, api)
        .then(() => {
          updateFileEntry(scope, id, { state: FileState.SUCCESS, action: FileAction.DELETE, progress: 100 })
          removeFileEntry(scope, id)
        })
        .catch((error) => {
          updateFileEntry(scope, id, { state: FileState.ERROR, progress: 0, error })
        })
    }
  }

  /** Obtains signedUrl for each file and proceeds with parallel upload of all files updating progress */
  async function uploadFiles<MetadataType extends { [key: string]: any }>(
    scope: string,
    files: FileList | Array<File>,
    signedUrlResolver: UploadUrlResolver<MetadataType>,
    options: UploadOptions<MetadataType>
  ) {

    // Should be separated probably
    const initializedFiles = Object.values(initFiles<MetadataType>(
      scope,
      Array.from(files).map((file) => createFileAPIEntry<MetadataType>({ fileObject: file, tag: options.tag, originalFilename: file.name }))
    ))

    // Set all files to prep state
    for (const file of initializedFiles) {
      updateFileEntry(scope, file.id, { state: FileState.RUNNING, action: FileAction.UPLOAD, progress: 0 })
    }

    const successIds: string[] = []
    const cancelledIds: string[] = []
    const errorIds: string[] = []

    // Sequentially obtain signed urls for all files
    // Sequentially because if called too quickly GC can return duplicated filename => duplicated urls
    for (const file of initializedFiles) {
      await signedUrlResolver(file, api)
        .then((response) => {
          const signedUrl = response.data

          // Update temporary fileEntry with signed url data to up to date when passing to id creator fnc
          file.signedUrl = signedUrl
          file.gcFilename = signedUrl.filename

          // Either use pased id creator fnc to generate new id, or default to gcFilename
          const newFileId = options.fileIdCreatorFnc
            ? options.fileIdCreatorFnc(file)
            : file.gcFilename

          // Assign signedUrl and change temporary id to GC filename
          updateFileEntry(scope, file.id, {
            signedUrl,
            gcFilename: signedUrl.filename,
            id: newFileId
          })

          // Replace temporary id by newly created id based on file
          file.id = newFileId
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            updateFileEntry(scope, file.id, { state: FileState.IDLE, progress: 0 })
            cancelledIds.push(file.id)
          } else {
            updateFileEntry(scope, file.id, { state: FileState.ERROR, progress: 0 })
            errorIds.push(file.id)
          }
        })
    }

    // In case all signed url retrievals failed / been cancelled
    if (cancelledIds.length + errorIds.length === initializedFiles.length) {
      options.onSettled?.(successIds, errorIds, cancelledIds)
      return
    }

    // Trigger parallel upload for all files
    const allUploads: Promise<unknown>[] = []

    for (const file of initializedFiles) {

      // Sanity check
      if (!file || !file.fileObject || !file.signedUrl || !file.abortController) continue

      allUploads.push(uploadToGC(
        file.fileObject,
        file.signedUrl,
        file.abortController.signal,
        (progress) => {
          updateFileEntry(scope, file.id, { progress })
        },
        // on success
        () => {
          updateFileEntry(scope, file.id, { state: FileState.SUCCESS, progress: 0 })
          successIds.push(file.id)
        },
        // on cancel
        () => {
          updateFileEntry(scope, file.id, { state: FileState.CANCELLED, progress: 0 })
          cancelledIds.push(file.id)
        },
        // on error
        () => {
          updateFileEntry(scope, file.id, { state: FileState.ERROR, progress: 0 })
          errorIds.push(file.id)
        }
      ))
    }

    Promise.allSettled(allUploads).then(() => options.onSettled?.(successIds, errorIds, cancelledIds))
  }

  /** Obtains signedUrl for each file and proceeds with parallel upload of all files updating progress */
  async function reUploadFile<MetadataType extends { [key: string]: any }>(
    scope: string,
    replaceId: string,
    file: File,
    signedUrlResolver: UploadUrlResolver<MetadataType>,
    options: ReUploadOptions<MetadataType>
  ) {

    const entry = fileInventory[scope]?.[replaceId] as FileAPIEntry<MetadataType> | undefined

    if (!entry) {
      console.error('No existing entry for this scope and id, nothing to replace, aborting!')
      return
    }

    // Set state and action and update file object data in state
    updateFileEntry(scope, entry.id, {
      state: FileState.RUNNING,
      action: FileAction.RE_UPLOAD,
      progress: 0,
      fileObject: file,
      originalFilename: file.name,
      tag: options.tag ?? entry.tag,
    })

    // Update crucial details in fnc kept entry
    entry.fileObject = file
    entry.originalFilename = file.name
    entry.tag = options.tag ?? entry.tag

    // Obtain signed url
    await signedUrlResolver(entry, api)
      .then((response) => {
        const signedUrl = response.data

        // Assign signedUrl and change temporary id to GC filename
        updateFileEntry(scope, entry.id, {
          signedUrl,
          gcFilename: signedUrl.filename,
          // Should be the same, just to be sure
          id: signedUrl.filename,
        })

        // Update signed url of entry
        entry.signedUrl = signedUrl
        // Should be the same, just to be sure
        entry.id = signedUrl.filename
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          updateFileEntry(scope, entry.id, { state: FileState.CANCELLED, progress: 0 })
          options.onCancel?.()
        } else {
          updateFileEntry(scope, entry.id, { state: FileState.ERROR, progress: 0 })
          options.onError?.()
        }
      })

    // Sanity check
    if (!entry || !entry.fileObject || !entry.signedUrl || !entry.abortController) return

    uploadToGC(
      entry.fileObject,
      entry.signedUrl,
      entry.abortController.signal,
      (progress) => {
        updateFileEntry(scope, entry.id, { progress })
      },
      // on success
      () => {
        updateFileEntry(scope, entry.id, { state: FileState.SUCCESS, progress: 0 })
        options.onSuccess?.()
      },
      // on cancel
      () => {
        updateFileEntry(scope, entry.id, { state: FileState.CANCELLED, progress: 0 })
        options.onCancel?.()
      },
      // on error
      () => {
        updateFileEntry(scope, entry.id, { state: FileState.ERROR, progress: 0 })
        options.onError?.()
      }
    )
  }

  const loadFile = async <MetadataType extends { [key: string]: any }>(scope: string, fileId: string, urlResolver: LoadUrlResolver<MetadataType>) => {

    const inventory = inventoryRef.current[scope]

    const entry = inventory[fileId] as FileAPIEntry<MetadataType> | undefined

    if (!entry) {
      console.warn(`No file entry for ${fileId} found, skipping.`)
      return
    }

    updateFileEntry(scope, fileId, {
      action: FileAction.LOAD,
      state: FileState.RUNNING,
      progress: 0,
    })

    const urlResponse = await retryRequestUntilSuccess<UrlDTO>(() => urlResolver(entry, api))

    if (axios.isAxiosError(urlResponse)) {
      updateFileEntry(scope, fileId, {
        state: FileState.ERROR,
        progress: 0,
        error: urlResponse.message,
      })
      return
    }

    const signedURL = urlResponse.data.url

    try {
      await startLoadWorker(signedURL)

      /*
          We are currently using Default Browser caching for resources.
          If we ever need to manipulate the data or switch to manual handling, data are already provided as a Blob.
          This might become necessary when handling different file types than images.
          
          To access the data as resource, resource URL must be assigned to it and be provided instead of the signed URL.
          Like so:
  
          const url = URL.createObjectURL(data)
          updateFileEntry(scope, fileId, { displayUrl: url })
  
          However, manual revoking of the URL is then needed to prevent memory leaks.
          
          URL.revokeObjectURL(url)
        */
      updateFileEntry(scope, fileId, {
        displayUrl: signedURL,
        state: FileState.SUCCESS,
        progress: 0,
      })
    } catch (err: any) {
      updateFileEntry(scope, fileId, {
        state: FileState.ERROR,
        progress: 0,
        error: err.message,
      })
    }
  }

  const loadFiles = <MetadataType extends { [key: string]: any }>(scope: string, fileIds: Array<string>, urlResolver: LoadUrlResolver<MetadataType>) => {
    for (const fileId of fileIds) {
      loadFile(scope, fileId, urlResolver)
    }
  }

  return {
    uploadFiles,
    reUploadFile,
    fileInventory,
    addFileEntry,
    updateFileEntry,
    initScope,
    purgeScope,
    deleteFiles,
    initFiles,
    loadFiles,
    createFileAPIEntry,
  }
})
