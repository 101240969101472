import { SignedURLDTO, UrlDTO } from 'models/visuals'

import { AxiosResponse } from 'axios'
import { Nullable } from 'models/helpers'
import { UseAPIReturn } from 'utils/API'

export enum FileState {
  IDLE = 'IDLE',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  RUNNING = 'RUNNING',
  CANCELLED = 'CANCELLED',
}

export enum FileAction {
  UPLOAD = 'UPLOAD',
  RE_UPLOAD = 'RE_UPLOAD',
  LOAD = 'LOAD',
  DELETE = 'DELETE',
  INIT = 'INIT',
}

export interface FileAPIEntry<MetadataType extends { [key: string]: any } = { [key: string]: any }> {
  id: string
  state: FileState
  action: FileAction
  progress: number
  signedUrl: Nullable<SignedURLDTO>
  displayUrl: Nullable<string>
  abortController: Nullable<AbortController>
  originalFilename: Nullable<string>
  gcFilename: Nullable<string>
  tag: Nullable<string>
  fileObject: File
  order: number
  error: Nullable<string>
  metadata: MetadataType
}

export type UploadUrlResolver<MetadataType extends { [key: string]: any }> = (fileEntry: FileAPIEntry<MetadataType>, api: UseAPIReturn<string>) => Promise<AxiosResponse<SignedURLDTO>>
export type ReUploadUrlResolver<MetadataType extends { [key: string]: any }> = (fileEntry: FileAPIEntry<MetadataType>, api: UseAPIReturn<string>) => Promise<AxiosResponse<SignedURLDTO>>
export type LoadUrlResolver<MetadataType extends { [key: string]: any }> = (fileEntry: FileAPIEntry<MetadataType>, api: UseAPIReturn<string>) => Promise<AxiosResponse<UrlDTO>>
export type FileDeleteFnc<MetadataType extends { [key: string]: any }> = (fileEntry: FileAPIEntry<MetadataType>, api: UseAPIReturn<string>) => Promise<AxiosResponse<any, any>>

export interface UploadOptions<MetadataType extends { [key: string]: any }> {
  tag?: string
  onSettled?: (successIds: string[], errorIds: string[], cancelledIds: string[]) => void
  uploadUrlResolverFnc?: UploadUrlResolver<MetadataType>
  fileIdCreatorFnc?: (fileEntry: FileAPIEntry<MetadataType>) => string
}

export interface ReUploadOptions<MetadataType extends { [key: string]: any }> {
  tag?: string
  onSuccess?: () => void
  onError?: () => void
  onCancel?: () => void
  reUploadUrlResolverFnc?: ReUploadUrlResolver<MetadataType>
}

export interface DeleteOptions<MetadataType extends { [key: string]: any }> {
  deleteHandlerFnc?: FileDeleteFnc<MetadataType>
}

export interface LoadOptions<MetadataType extends { [key: string]: any }> {
  loadUrlResolverFnc?: LoadUrlResolver<MetadataType>
}
