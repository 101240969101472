import '../_main/Profile.sass'

import { AccountDetails, Actions, Preferences } from '..'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, Fragment, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { DidYouKnow } from '../DidYouKnow'
import { EmailForwardning } from 'components/common/EmailForwardning'
import { Flow } from 'constants/misc'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { QueryStatus } from 'components/common/QueryStatus'
import { SavedPaymentMethods } from '../SavedPaymentMethods'
import { UserRole } from 'constants/auth'
import { WorkPreferences } from '../WorkPreferences'
import { useAuth0 } from 'utils/auth'
import { useUpdateCcEmails } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'

export const ProfileController: FC = () => {
  const { t } = useTranslation(['profile'])
  const navigate = useNavigate()
  const { user, roles } = useAuth0()
  const { hasUserActiveSubscription, clientData } = useUserData()

  const clientCcEmails = useMemo(() => clientData?.ccEmails ?? [], [clientData?.ccEmails])

  const updateClientCcEmails = useUpdateCcEmails()

  useEffect(() => {
    let currentUserRole = UserRole.CLIENT

    if (roles.isCreative) currentUserRole = UserRole.CREATIVE
    if (roles.isAdmin) currentUserRole = UserRole.ADMIN

    logAnalyticsEvent('profile_page_opened', {
      userRole: currentUserRole
    })

  }, [roles.isAdmin, roles.isClient, roles.isCreative])

  if (!user) return <Fragment></Fragment>

  return (
    <div className="page profile blue-bg">
      <div className="page-content">
        <div className="top-row">
          <Link to="/" onClick={e => { e.preventDefault(); navigate(-1) }} className="backbutton button secondary nobackground">
            <Icon icon={IconType.CARET_LEFT} />
          </Link>
          <h1>{t('heading')}</h1>
        </div>
        <div className="white-content">
          <h2 className="decorated">{t('profile_icon')}</h2>
          <div className="icon-section">
            <img src={user.picture} alt="" />
            <div>
              <BlockInfo>
                <Trans t={t} i18nKey="profile:gravatar">
                  <a rel="nofollow noopener noreferrer" target="_blank" href="https://gravatar.com/">
                    Gravatar
                  </a>
                </Trans>
              </BlockInfo>
            </div>
          </div>
          <AccountDetails />
          {roles.isClient && !hasUserActiveSubscription &&
            <DidYouKnow />
          }
          {roles.isCreative &&
            <WorkPreferences />
          }
          <Preferences />
          <Actions />

          <h2 className="decorated">{t('cc_emails')}</h2>

          <EmailForwardning
            ccEmails={clientCcEmails}
            onChange={(emails) => {
              logAnalyticsEvent(AnalyticsEvent.CC_EMAIL_ADDED, { Flow: Flow.PROFILE })
              updateClientCcEmails.mutate({ ccEmails: emails })
            }}
            onDelete={(email) => {
              logAnalyticsEvent(AnalyticsEvent.CC_EMAIL_REMOVED, { Flow: Flow.PROFILE })
              updateClientCcEmails.mutate({ ccEmails: clientCcEmails.filter(prevEmail => prevEmail !== email) })
            }}
          />

          <QueryStatus
            query={updateClientCcEmails}
            spaceTopRem={2}
          />

          {roles.isClient &&
            <SavedPaymentMethods />
          }
        </div>
      </div>
    </div>
  )
}
