import { ImmutableList, ImmutableMap, Nullable } from 'models/helpers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthoritiesDocuments, usePurchaseFlowProducts } from '../../_main/contexts'

import { useFlag } from '@unleash/proxy-client-react'
import constate from 'constate'
import { uniqueId } from 'lodash'
import { AuthoritiesDocumentDTO } from 'models/purchaseFlow'
import { Moment } from 'moment-timezone'
import { FeatureFlag } from 'utils/featureFlags'

/**
 * Represents an item in the land register sheet.
 */
type LandRegisterSheetItem = {
  /** The name of land register sheet item. */
  name: string
  /** The district of the item. */
  district: string
  /** The list of floors in the item. */
  floors: ImmutableList<LandRegisterSheetFloorItem>
}

/**
 * Represents an item in the land register sheet floor.
 */
type LandRegisterSheetFloorItem = {
  /** The hallway of the floor. */
  hallway: string
  /** The parcel of the floor. */
  parcels: ImmutableList<LandRegistrationSheetParcel>
}

/**
 * Represents an item in the land register sheet floor.
 */
type LandRegistrationSheetParcel = {
  /** The number of the parcel */
  number: string,
}
/**
 * Generates a unique ID for a land register sheet.
 * @returns The generated unique ID.
 */
const _generateLandRegisterSheetId = (): string => uniqueId('land-register-')

/**
 * Generates a default land register sheet item.
 * @returns The generated default land register sheet item.
 */
const _generateDefaultLandRegisterSheetItem = (): LandRegisterSheetItem => {
  return {
    name: '',
    district: '',
    floors: ImmutableList([
      {
        hallway: '',
        parcels: ImmutableList([{
          number: '',
        }])
      }
    ])
  }
}

export const [PropertyInformationStepContextProvider, usePropertyInformationStep] = constate(() => {
  const { selectedProducts } = usePurchaseFlowProducts()
  const { searchProduct } = useAuthoritiesDocuments()
  const allowAuthoritiesDocuments = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)

  const [landRegisterSheetItems, setLandRegisterSheetItems] = useState<ImmutableMap<string, LandRegisterSheetItem>>(ImmutableMap([]))
  const [landlordFirstName, setLandlordFirstName] = useState<string>('')
  const [landlordLastName, setLandlordLastName] = useState<string>('')
  const [landlordDateOfBirth, setLandlordDateOfBirth] = useState<Nullable<Moment>>(null)
  const [comment, setComment] = useState<string>('')

  /**
   * Updates the land register sheet item with the specified ID.
   * 
   * @param id - The ID of the land register sheet item to update.
   * @param updates - The partial updates to apply to the land register sheet item.
   */
  const updateSheetList = (id: string, updates: Partial<LandRegisterSheetItem>) => {
    if (!landRegisterSheetItems.get(id)) return

    setLandRegisterSheetItems((prevItems) => prevItems.update(id, item => ({ ...item!, ...updates })))
  }

  /**
   * Updates the land register sheet floor with the specified id and floor index.
   * 
   * @param id - The id of the land register sheet.
   * @param floorIndex - The index of the floor to update.
   * @param updates - The partial updates to apply to the floor.
   */
  const updateLandRegisterSheetFloor = (id: string, floorIndex: number, updates: Partial<LandRegisterSheetFloorItem>) => {

    if (!landRegisterSheetItems.get(id)?.floors.get(floorIndex)) return

    updateSheetList(
      id,
      {
        floors: landRegisterSheetItems.get(id)!.floors.update(floorIndex, prev => ({ ...prev!, ...updates }))
      }
    )
  }

  const updateParcel = (sheetId: string, floorIndex: number, parcelIndex: number, update: Partial<LandRegistrationSheetParcel>) => {
    const sheet = landRegisterSheetItems.get(sheetId)
    if (!sheet) return

    const floor = sheet.floors.get(floorIndex)
    if (!floor) return

    updateLandRegisterSheetFloor(
      sheetId,
      floorIndex,
      {
        parcels: floor.parcels.update(parcelIndex, (prev) => ({ ...prev!, ...update })),
      }
    )
  }

  /**
   * Adds a new land register sheet.
   */
  const onAddLandRegisterSheet = () => {
    setLandRegisterSheetItems(prev => prev.set(_generateLandRegisterSheetId(), _generateDefaultLandRegisterSheetItem()))
  }

  /**
   * Removes a land register sheet.
   * @param id - The ID of the land register sheet.
   */
  const onRemoveLandRegisterSheet = (id: string) => {
    setLandRegisterSheetItems(prev => prev.remove(id))
  }

  /**
   * Adds a new floor to a land register sheet.
   * @param id - The ID of the land register sheet.
   */
  const addFloor = (id: string) => {
    if (!landRegisterSheetItems.get(id)) return

    const newFloor = {
      hallway: '',
      parcels: ImmutableList([{
        number: '',
      }]),
    }

    updateSheetList(id, { floors: landRegisterSheetItems.get(id)!.floors.push(newFloor) })
  }

  /**
   * Removes a floor from a land register sheet.
   * @param id - The ID of the land register sheet.
   * @param index - The index of the floor to remove.
   */
  const removeFloor = (id: string, index: number) => {
    if (!landRegisterSheetItems.get(id)) return

    updateSheetList(id, { floors: landRegisterSheetItems.get(id)!.floors.splice(index, 1) })
  }

  /**
   * Adds a new floor to a land register sheet.
   * @param sheetId - The ID of the land register sheet.
   * @param floorIndex - The index of the floor to add parcel
   */
  const addParcel = (sheetId: string, floorIndex: number) => {
    const sheet = landRegisterSheetItems.get(sheetId)
    if (!sheet) return

    const floor = sheet.floors.get(floorIndex)
    if (!floor) return

    updateLandRegisterSheetFloor(
      sheetId,
      floorIndex,
      {
        parcels: floor.parcels.push({
          number: '',
        }),
      }
    )
  }

  /**
   * Removes a floor from a land register sheet.
   * @param sheetId - The ID of the land register sheet.
   * @param floorIndex - The index of the floor.
   * @param parcelIndex - The index of the parcel to remove.
   */
  const removeParcel = (sheetId: string, floorIndex: number, parcelIndex: number) => {
    const sheet = landRegisterSheetItems.get(sheetId)
    if (!sheet) return

    const floor = sheet.floors.get(floorIndex)
    if (!floor) return

    updateLandRegisterSheetFloor(
      sheetId,
      floorIndex,
      {
        parcels: floor.parcels.splice(parcelIndex, 1),
      }
    )
  }

  /**
   * Indicates whether the landlord information is partially filled.
   */
  const isLandlordInfoPartiallyFilled: boolean = useMemo(() => {
    return !!(landlordFirstName || landlordLastName || landlordDateOfBirth)
  }, [landlordDateOfBirth, landlordFirstName, landlordLastName])

  useEffect(() => {
    // Set default land register sheet items
    if (!landRegisterSheetItems.size) {
      setLandRegisterSheetItems(prev => prev.set(_generateLandRegisterSheetId(), _generateDefaultLandRegisterSheetItem()))
    }
    // Only run on first render, no need to add dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAuthoritiesDocumentRequestData = useCallback((): AuthoritiesDocumentDTO | undefined => {
    if (!allowAuthoritiesDocuments) return undefined

    const nonSearchProduct = !searchProduct || !selectedProducts[searchProduct.id]
    const landRegistrationSheets = landRegisterSheetItems.toArray().map(([_, value]) => ({
      name: value.name,
      district: value.district,
      floors: value.floors.toArray().map(({ hallway, parcels }) => ({
        hallway,
        parcels: parcels.toArray()
      }))
    }))

    let landLordInformation = undefined

    if (landlordFirstName && landlordLastName && landlordDateOfBirth) {
      landLordInformation = {
        firstName: landlordFirstName,
        lastName: landlordLastName,
        dateOfBirth: landlordDateOfBirth.toISOString()
      }
    }

    return {
      landRegistrationSheets: nonSearchProduct ? landRegistrationSheets : [],
      landLordInformation,
      comment
    }
  }, [allowAuthoritiesDocuments, comment, landRegisterSheetItems, landlordDateOfBirth, landlordFirstName, landlordLastName, searchProduct, selectedProducts])

  return {
    landRegisterSheetItems,
    isLandlordInfoPartiallyFilled,
    landlordFirstName,
    landlordLastName,
    landlordDateOfBirth,
    addFloor,
    removeFloor,
    addParcel,
    removeParcel,
    onAddLandRegisterSheet,
    onRemoveLandRegisterSheet,
    setLandlordFirstName,
    setLandlordLastName,
    setLandlordDateOfBirth,
    updateSheetList,
    updateLandRegisterSheetFloor,
    updateParcel,
    getAuthoritiesDocumentRequestData,
    comment,
    setComment
  }
})
