import '../_main/Gallery.sass'

import { Box, Stack } from '@mui/material'
import { FC, Fragment } from 'react'
import { useGalleryVisualSelection, useGalleryVisualType } from '../_main/contexts'

import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileState } from 'components/common/FileAPI'
import { GalleryImage } from 'components/common/Gallery/GalleryImage'
import { MUIButton } from 'components/common/MUIButton'
import { VisualFileType } from 'constants/visual'
import { supportedImageFileTypes } from 'constants/misc'
import { useFileAPIGalleryVisuals } from './FileAPIGalleryVisuals.context'

export const TestGalleryController: FC = () => {

  const { setVisualTypeSelect, visualTypeSelect } = useGalleryVisualType()

  const { selected, toggleSelectOnImage } = useGalleryVisualSelection()

  const {
    allFilesArray,
    filesByTag,
    uploadVisuals,
  } = useFileAPIGalleryVisuals()

  const currentVisuals = filesByTag[visualTypeSelect] ?? []

  return (
    <Fragment>
      <div className="page gallery">
        <div className="page-content">

          <Stack direction="row">
            <MUIButton
              type={visualTypeSelect === VisualFileType.RAW ? 'defaultPrimary' : 'secondaryBorder'}
              onClick={() => setVisualTypeSelect(VisualFileType.RAW)}
            >
              {VisualFileType.RAW}
            </MUIButton>
            <MUIButton
              type={visualTypeSelect === VisualFileType.POST ? 'defaultPrimary' : 'secondaryBorder'}
              onClick={() => setVisualTypeSelect(VisualFileType.POST)}
            >
              {VisualFileType.POST}
            </MUIButton>
            <MUIButton
              type={visualTypeSelect === 'POST_WATERMARK' ? 'defaultPrimary' : 'secondaryBorder'}
              onClick={() => setVisualTypeSelect('POST_WATERMARK')}
            >
              POST_WATERMARK
            </MUIButton>
          </Stack>

          <Stack direction="row">
            <h3>Selected: {selected.size}</h3>
          </Stack>

          <FileAPIUpload
            files={allFilesArray}
            acceptedFileTypes={supportedImageFileTypes}
            showFileListing={false}
            disabled={false}
            uploadHandler={(acceptedFiles) => {
              uploadVisuals(acceptedFiles)
            }}
            onDelete={function (fileId: string): void {
              throw new Error('Function not implemented.')
            }}
          />

          <Stack direction="row" flexWrap="wrap" gap={1}>
            {currentVisuals.sort((fileA, fileB) => fileB.order - fileA.order).map(file => (
              <Box flex="1 0 350px" key={file.id}>
                <GalleryImage
                  imageUrl={file.displayUrl ?? undefined}
                  isLoading={file.state === FileState.RUNNING}
                  isError={file.state === FileState.ERROR}
                  errorMessage={file.error ?? undefined}
                  isFavorite={file.metadata.isFavourited}
                  isSelectable
                  isSelected={!!file.gcFilename && selected.has(file.gcFilename)}
                  onSelect={() => {
                    file.gcFilename && toggleSelectOnImage(file.gcFilename)
                  }}
                />
              </Box>
            ))}
          </Stack>

        </div>
      </div>
    </Fragment>
  )
}
