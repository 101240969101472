import './Autocomplete.sass'

import { CSSProperties, FormEvent, useCallback } from 'react'

import { ReactGeocodePlace } from 'react-geocode'
import classNames from 'classnames'
import { lookupPlaceFromAddress } from 'utils/helpers/lookUpPlaceFromAddress'
import { useAddressMap } from '../AddressMap.context'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'

export type InputPositioin = 'below' | 'above'

export interface Props {
  /** error text to display beside the input */
  error?: string
  /** Action called when address changes */
  handleChange: (place: ReactGeocodePlace | null, isInitialLookup: boolean) => void
  /** Handle autocomplete input change */
  onInputChange?: ((event: FormEvent<HTMLInputElement>) => void)
  /** Optional hook for autocomplete input blur event */
  onInputBlur?: () => void
  /** Position of the input, either above or below the map container, defaults to below */
  inputPosition?: InputPositioin
  /** A string or an array of countries such es ['CH, AT, DE'] to restrict the search to */
  restrictToCountries?: string | string[]
  /** Optional styles for address input */
  inputStyles?: CSSProperties
}

/**
 * @component
 * AutocompleteInput component responsible for handling the address selection step in the purchase flow.
 *
 * @example
 * <AutocompleteInput />
 */
export const AutocompleteInput: React.FC<Props> = ({
  error,
  handleChange,
  onInputChange,
  onInputBlur,
  inputPosition = 'below',
  restrictToCountries,
  inputStyles,
}) => {

  const { t } = useTranslation('address_map')

  const {
    address,
    setAddress,
    updateStateFromPlace,
  } = useAddressMap()

  const { ref: inputRef } = usePlacesWidget<HTMLInputElement>({
    onPlaceSelected: (place) => {
      onPlaceSelected(place)
    },
    options: {
      types: ['address'],
      componentRestrictions: restrictToCountries ? { country: restrictToCountries } : undefined,
    }
  })

  const handleSetStateFromPlace = useCallback((place: ReactGeocodePlace | null, isInitialLookup: boolean = false) => {
    updateStateFromPlace(place)
    handleChange(place, isInitialLookup)
  }, [handleChange, updateStateFromPlace])

  /**
  * When the user selects an address in the search box
  * @param place
  */
  const onPlaceSelected = useCallback(async (place: google.maps.places.PlaceResult) => {

    if (place.name) {
      try {
        const lookupPlace = await lookupPlaceFromAddress(place.name)
        return handleSetStateFromPlace(lookupPlace)
      } catch (error) {
        console.error(error)
        return handleSetStateFromPlace(null)
      }
    }

    if (!place?.geometry || !place.formatted_address) {
      return handleSetStateFromPlace(null)
    }

    const lat = place.geometry.location?.lat() ?? 0
    const lng = place.geometry.location?.lng() ?? 0

    if (!place.geometry.location) console.error('place.geometry.location is null')

    const place_object: ReactGeocodePlace = {
      ...place,
      geometry: {
        location: {
          lat,
          lng,
        }
      },
      place_id: place.place_id ?? '',
      formatted_address: place.formatted_address ?? '',
      address_components: place.address_components ?? [],
    }

    handleSetStateFromPlace(place_object)

  }, [handleSetStateFromPlace])

  const handleAutocompleteChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    setAddress(e.currentTarget.value)
  }, [setAddress])

  return (
    <div className="auto-complete">

      {error && inputPosition === 'above' &&
        <span className="error-message">{error}</span>
      }

      <input
        ref={inputRef}
        value={address}
        placeholder={t('placeholder')}
        onChange={e => {
          handleAutocompleteChange(e)
          onInputChange?.(e)
        }}
        onBlur={onInputBlur}
        style={{ ...(inputStyles || {}) }}
        className={
          classNames({
            'error-input': error,
            'below': inputPosition === 'below',
            'above': inputPosition === 'above',
          })
        }
      />

      {error && inputPosition === 'below' &&
        <span className="error-message">{error}</span>
      }

    </div>
  )
}
