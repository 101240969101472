import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { AreaCalculationTypes, FloorPlanCertificationPropertyTypes } from '.'
import { useCallback, useMemo, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import { CatalogueProduct } from 'dataQueries/purchase.query'
import { FeatureFlag } from 'utils/featureFlags'
import { ImmutableList } from 'models/helpers'
import { ProductKind } from 'constants/product'
import constate from 'constate'
import { useFlag } from '@unleash/proxy-client-react'

/**
 * Information for each floor of FP certification
 */
export type FloorPlanCertificationFloorItem = {
  /** The name of the floor. */
  name: string
  /** The rooms of the floor. */
  numberOfRooms: number
}

/**
 * Information for FP certification
 */
export type FloorPlanCertificationInformation = {
  /** The name of land register sheet item. */
  propertyType: FloorPlanCertificationPropertyTypes
  /** Number of floors for certification. */
  numberOfFloors: number
  /** The list of floors in the item and its info. */
  floors: ImmutableList<FloorPlanCertificationFloorItem>
  /** The area calculation type for the certification. */
  areaCalculation: AreaCalculationTypes
  /** If architect stamp was requested */
  hasArchitectStamp: boolean
  /** Comment for certification */
  comment: string
}

const defaultFloorPlanCertificationInformation: FloorPlanCertificationInformation = {
  propertyType: FloorPlanCertificationPropertyTypes.APARTMENT,
  numberOfFloors: 1,
  floors: ImmutableList([
    {
      name: '',
      numberOfRooms: 0
    }, {
      name: '',
      numberOfRooms: 1
    }
  ]),
  areaCalculation: AreaCalculationTypes.CALCULATE_WITH_25,
  hasArchitectStamp: false,
  comment: ''
}

export const [FloorPlanCertificationContextProvider, useFloorPlanCertification] = constate(() => {
  const allowFloorPlanCertificationFlow = useFlag(FeatureFlag.ALLOW_FLOOR_PLAN_CERTIFICATION_FLOW)

  const { extraProducts } = usePurchaseFlowConfig()
  const { selectProduct, selectedProducts, unselectProduct } = usePurchaseFlowProducts()

  const [floorPlanCertificationInformation, setFloorPlanCertificationInformation] = useState<FloorPlanCertificationInformation>(defaultFloorPlanCertificationInformation)

  const architectStampProduct = useMemo<CatalogueProduct | undefined>(() => {
    return extraProducts[ProductKind.EXTRA_ARCHITECT_STAMP]?.[0]
  }, [extraProducts])

  const extraCertifiedFloorProduct = useMemo<CatalogueProduct | undefined>(() => {
    return extraProducts[ProductKind.EXTRA_CERTIFIED_FLOOR]?.[0]
  }, [extraProducts])

  const extraCertifiedRoomProduct = useMemo<CatalogueProduct | undefined>(() => {
    return extraProducts[ProductKind.EXTRA_CERTIFIED_ROOM]?.[0]
  }, [extraProducts])

  /**
   * Adds a new floor to the floor plan certification information.
   *
   * This function creates a new floor object with the given id, an empty name, and a numberOfRooms of 0.
   * It then updates the floorPlanCertificationInformation state by adding the new floor to the existing list of floors.
   * If there is an extraCertifiedFloorProduct and the updated list of floors has more than one floor, 
   * it selects the extraCertifiedFloorProduct for the newly added floor.
   */
  const addFloor = () => {
    if (!floorPlanCertificationInformation) return

    const newFloor = {
      name: '',
      numberOfRooms: 1,
    }

    setFloorPlanCertificationInformation(prevState => ({
      ...prevState,
      floors: prevState.floors.push(newFloor)
    }))

    if (floorPlanCertificationInformation.floors.size > 1 && extraCertifiedFloorProduct) {
      selectProduct(extraCertifiedFloorProduct, floorPlanCertificationInformation.floors.size - 1)
    }
  }

  /**
   * Removes the last floor from the FP Certification information.
   * If the number of floors is less than or equal to 2 (1 product is included in original price and 0 floor is not counted), it unselects the extra certified floor product.
   * Otherwise, it updates the selection of the extra certified floor product.
   */
  const removeFloor = () => {
    if (!floorPlanCertificationInformation) return

    const updatedFloors = floorPlanCertificationInformation.floors.pop()

    setFloorPlanCertificationInformation(prevState => ({
      ...prevState,
      floors: updatedFloors
    }))

    if (extraCertifiedFloorProduct && updatedFloors.size <= 2) {
      unselectProduct(extraCertifiedFloorProduct.id)

      // Unselect extra certified room product if it was selected and total Floors number is decreased back to 0 floors
      if (updatedFloors.size <= 1 && extraCertifiedRoomProduct && selectedProducts[extraCertifiedRoomProduct.id]) {
        unselectProduct(extraCertifiedRoomProduct.id)
      }
    } else if (updatedFloors.size > 1 && extraCertifiedFloorProduct) {
      selectProduct(extraCertifiedFloorProduct, updatedFloors.size - 2)
    }
  }

  /**
   * Handles the change in the number of rooms for a specific floor.
   * 
   * This function updates the numberOfRooms for the specified floor in the floorPlanCertificationInformation state.
   * If the number of rooms on any floor is 8 or more, it selects the extra certified room product with the quantity 
   * being the number of rooms minus 7 free rooms for that floor. This is counted as total for each floor. If there are no extra rooms, it unselects the 
   * extra certified room product.
   * 
   * @param floorNumber - The index of the floor to update.
   * @param numberOfRooms - The new number of rooms for the specified floor.
   */
  const handleNumberOfRoomsChange = useCallback((floorNumber: number, numberOfRooms: number) => {
    setFloorPlanCertificationInformation(prevState => {
      const updatedFloors = prevState.floors.update(floorNumber, prev => ({ ...prev!, numberOfRooms }))

      if (!extraCertifiedRoomProduct) return { ...prevState, floors: updatedFloors }

      // If the number of rooms on any floor is 8 or more, select the extra certified room product
      // with the quantity being the number of rooms minus 7 free rooms for that floor.
      const extraRooms = Array.from(updatedFloors.values()).reduce((sum, floor) => {
        const rooms = floor.numberOfRooms ?? 0
        return sum + (rooms > 7 ? rooms - 7 : 0)
      }, 0)

      if (extraRooms > 0) {
        selectProduct(extraCertifiedRoomProduct, extraRooms)
      } else {
        // If there are no extra rooms, unselect the extra certified room product.
        unselectProduct(extraCertifiedRoomProduct.id)
      }

      return { ...prevState, floors: updatedFloors }
    })
    // ignore selectProduct and unselectProduct dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraCertifiedRoomProduct])

  const getFloorPlanCertificationtRequestData = useCallback(() => {
    if (!allowFloorPlanCertificationFlow) return undefined

    const { propertyType, numberOfFloors, floors, areaCalculation, comment } = floorPlanCertificationInformation

    return {
      propertyType,
      numberOfFloors,
      floors: ImmutableList(floors.shift().map((it, index) => {
        return {
          name: it.name,
          floorNumber: index + 1,
          numberOfRooms: it.numberOfRooms,
        }
      })), // Remove the first floor as it is used only for FE purposes
      regulatedLivingAreaCalculation: areaCalculation,
      comment
    }
  }, [allowFloorPlanCertificationFlow, floorPlanCertificationInformation])

  const logFloorPlanCertificationRequirements = useCallback(() => {
    const { propertyType, numberOfFloors, floors, areaCalculation, comment } = floorPlanCertificationInformation

    logAnalyticsEvent(AnalyticsEvent.LIVING_AREA_CALCULATION_REQUIREMENT_SET_UP, {
      PropertyType: propertyType,
      NumberFloors: numberOfFloors,
      FloorsData: floors,
      AreaCalculation: areaCalculation,
      Comments: comment,
    })
  }, [floorPlanCertificationInformation])

  return {
    architectStampProduct,
    extraCertifiedRoomProduct,
    removeFloor,
    addFloor,
    floorPlanCertificationInformation,
    setFloorPlanCertificationInformation,
    getFloorPlanCertificationtRequestData,
    logFloorPlanCertificationRequirements,
    handleNumberOfRoomsChange
  }
})
