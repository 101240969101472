import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Collapse, Fade, IconButton, Stack, Typography } from '@mui/material'
import { GRAY_800, GRAY_900 } from 'constants/styling/theme'
import React, { useEffect, useMemo } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { HeatingSystem } from '../HeatingSystem/_main/HeatingSystem.component'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { PhotovoltaicSystem } from '../PhotovoltaicSystem.component'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { TransitionGroup } from 'react-transition-group'
import { VentilationSystem } from '../VentilationSystem.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from './VentilationAndHeating.context'

/** @interface VentilationHeatingProps for the VentilationHeating components. */
export interface VentilationHeatingProps {
  /** The unique identifier for the heating system item. */
  id: string
}

export const VentilationAndHeating: React.FC = () => {
  const { t } = useTranslation(['heating_system'])

  const {
    ventilationAndHeatingSystem,
    handleAddHeatingSystemItem,
    handleRemoveHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  const { selectedCategory } = usePurchaseFlowConfig()

  /** Sorted heating system items by their IDs considering numeric values for proper ordering. */
  const sortedHeatingSystemItems = useMemo(() => {
    if (!ventilationAndHeatingSystem) return
    const arrayHeatinngSystemItems = ventilationAndHeatingSystem.toArray()

    return arrayHeatinngSystemItems.sort(([idA], [idB]) => idA.localeCompare(idB, undefined, { numeric: true }))
  }, [ventilationAndHeatingSystem])

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.OPENS_VENTILATION_HEATING, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })
    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fade in={true}>
      <Stack gap={2}>
        {/** Heating system items */}
        <TransitionGroup component={null}>
          {sortedHeatingSystemItems && sortedHeatingSystemItems.map(([id], index) =>
            <Collapse key={id}>
              <Stack gap={1.6}>
                <SectionedBorderBox title={
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
                      {`${t('heating_system_title')} #${index + 1}`}
                    </Typography>

                    {index > 0 &&
                      <IconButton
                        aria-label='delete-heating-system'
                        sx={{ width: '3rem', height: '3rem' }}
                        onClick={() => handleRemoveHeatingSystemItem(id)}
                      >
                        <CloseRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />
                      </IconButton>
                    }
                  </Stack>
                }>

                  {/* HEATING SYSTEM */}
                  <HeatingSystem id={id} />
                </SectionedBorderBox>
              </Stack>
            </Collapse>
          )}
        </TransitionGroup>

        {/** Add new items */}
        <MUIDivider>
          <MUIButton
            type='secondaryBorder'
            startIcon={<AddCircleOutlineRoundedIcon fontSize='large' />}
            onClick={() => handleAddHeatingSystemItem()}
          >
            {t('add_heating_system')}
          </MUIButton>
        </MUIDivider>

        {/* PHOTOVOLTAIC SYSTEM */}
        <PhotovoltaicSystem />

        {/* VENTILATION SYSTEM */}
        <VentilationSystem />

      </Stack>
    </Fade>
  )
}
