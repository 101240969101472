import {
  BLUE_600,
  CORAL_100,
  CORAL_600,
  GRAY_200,
  GRAY_400,
  GRAY_900,
  WHITE
} from 'constants/styling/theme'
import { FC, useMemo } from 'react'
import { TextField, inputBaseClasses, inputClasses } from '@mui/material'

import { BorderBoxWrapper } from '../BorderBoxWrapper'
import { Label } from '../Label'
import { MUIInputFieldButton } from '../MUIInputField/MUIInputFieldButton'
import {
  NumberInputProps
} from '@mui/base/Unstable_NumberInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/** @interface Props for the MUIFloatlNumberField component. */
export interface Props extends Omit<NumberInputProps, 'onChange' | 'value'> {
  /** The label for the number field. */
  label?: string
  /** Whether the field is in an error state. */
  isError?: boolean
  /** Indicates if the field is disabled. */
  disabled?: boolean
  /** Indicates if the field is required. */
  required?: boolean
  /** The error text to display when in an error state. */
  errorText?: string | null
  /** A suffix to display after the number. */
  suffix?: string
  /** The value of the number field. */
  value?: string
  /** The width of the input box. */
  inputBoxWidth?: string
  /** Callback function to handle value changes. */
  onChange?: (value: string) => void
  /** Callback function to handle blur events. */
  onBlur?: () => void
}

/**
 * @component
 * MUIFloatlNumberField renders a number input field allowing float number with optional label, suffix, and error handling.
 * Unfortunately MUI didnt provide a float number field within Number input, its their plan for future, so we needed to use 
 * TextField and modify it with validation.
 * https://github.com/mui/base-ui/issues/41
 *
 * @example
 * <MUIFloatlNumberField
 *  label="Float Input"
 *  value="2.5"
 *  suffix="Kg"
 *  onChange={(value) => console.log(value)}
 * />
 */
export const MUIFloatlNumberField: FC<Props> = ({
  label,
  value,
  suffix,
  isError,
  required,
  disabled,
  errorText,
  inputBoxWidth = '100%',
  onBlur,
  onChange,
}) => {
  const hoverBorderColor = useMemo(() => {
    if (isError) return 'inherit'
    if (disabled) return GRAY_400
    return BLUE_600
  }, [disabled, isError])

  const inputBgColor = useMemo(() => {
    if (isError) return CORAL_100
    if (disabled) return GRAY_200
    return 'inherit'
  }, [disabled, isError])

  return (
    <Stack gap={0.6} width={inputBoxWidth}>
      {!!label && (
        <Label
          text={label}
          required={required}
          isError={isError}
        />
      )}

      <BorderBoxWrapper
        borderColor={isError ? CORAL_600 : GRAY_400}
        sx={{
          height: '4.1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transition: '.3s ease',
          overflow: 'hidden',
          '&:hover': {
            border: `0.1rem solid ${hoverBorderColor}`,
          },
        }}
      >
        <TextField
          variant="standard"
          error={isError}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            if (/^\d*\.?\d*$/.test(e.target.value)) {
              e.preventDefault()
              onChange?.(e.target.value)
            }
          }}
          inputProps={{
            inputMode: 'decimal', // Numeric keyboard with decimal point on mobile
            pattern: '[0-9]*[.,]?[0-9]*', // Decimal format validation
            step: 'any',
          }}
          sx={{
            width: '100%',
            flex: '1 1 6rem',
            [`.${inputBaseClasses.input}`]: {
              padding: '.8rem 1.2rem !important',
              border: 'unset !important',
              borderBottomLeftRadius: '.8rem',
              borderTopLeftRadius: '.8rem',
              color: `${isError ? CORAL_600 : GRAY_900} !important`,
              backgroundColor: `${disabled ? inputBgColor : WHITE} !important`,
              boxShadow: 'unset !important',
              ':focus': {
                color: `${GRAY_900} !important`
              },
            },
            [`.${inputClasses.root}::before`]: {
              borderBottomColor: 'unset !important',
              borderBottom: 'unset !important',
            },
            [`.${inputClasses.root}::after`]: {
              borderBottomColor: 'unset !important',
              borderBottom: 'unset !important',
            },
          }}
        />

        {!!suffix && (
          <MUIInputFieldButton disabled sx={{ width: '3.1rem' }}>
            <Typography variant="text-sm" color={GRAY_900} fontWeight={500}>
              {suffix}
            </Typography>
          </MUIInputFieldButton>
        )}
      </BorderBoxWrapper>

      {!!isError && !!errorText && (
        <Typography variant="text-md" fontWeight="medium" color={CORAL_600}>
          {errorText}
        </Typography>
      )}
    </Stack>
  )
}
