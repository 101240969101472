import { BEIGE_300, BEIGE_600, BOX_SHADOW_XS, GRAY_900, WHITE } from 'constants/styling/theme'
import { FC, Fragment, useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { applyFeeDiscount, formatPrice } from 'utils/price'
import { extendVirtualVisitHosting, purgeExtendVirtualVisitHosting } from 'redux/Individual/Visual/ExtendVirtualVisitHosting'
import { getDateString, getUserTimezone } from 'utils/time'
import { useDispatch, useSelector } from 'react-redux'
import { useGalleryDeal, useGalleryVirtualVisit } from '../../contexts'

import { ActionTypeAPIData } from 'constants/redux'
import { MUIButton } from 'components/common/MUIButton'
import { ProductType } from 'constants/product'
import { RootStore } from 'models/redux'
import { UpsellPayment } from '../../../common'
import { ZERO_DECIMAL } from 'constants/misc'
import { useClientGallery } from '../_main/contexts'
import { useGetVirtualVisitExtensionPrice } from 'dataQueries'

/**
 * Client gallery virtual visit info card component
 * 
 * @example
 * <ClientGalleryVirtualVisitInfoCard />
 */

export const ClientGalleryVirtualVisitInfoCard: FC = () => {
  const { t } = useTranslation(['virtual_visit_info', 'gallery'])

  const dispatch = useDispatch()

  const { assignmentId } = useClientGallery()
  const { dealData } = useGalleryDeal()
  const {
    virtualVisitExpirationDate,
    virtualVisitExpired
  } = useGalleryVirtualVisit()

  const user = useSelector((state: RootStore) => state.APIData.USER_ME.data)

  const virtualExtensionPrice = useGetVirtualVisitExtensionPrice(assignmentId)
  const virtualExtensionPriceData = useMemo(() => virtualExtensionPrice.data?.data, [virtualExtensionPrice])
  const extendVirtualVisitRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING][assignmentId]?.request)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const extensionPrice = useMemo(() => virtualExtensionPriceData?.fee, [virtualExtensionPriceData])
  const extensionProductId = useMemo(() => virtualExtensionPriceData?.productId, [virtualExtensionPriceData])
  const currency = useMemo(() => extensionPrice?.currency, [extensionPrice])
  const extensionTotal = useMemo(() => !!extensionPrice ? applyFeeDiscount({ ...extensionPrice, discountPercentage: dealData?.financials.discount ?? ZERO_DECIMAL }) : undefined, [extensionPrice, dealData?.financials.discount])
  const expirationDateString = useMemo(() => !!virtualVisitExpirationDate ? getDateString(virtualVisitExpirationDate, { timezone: getUserTimezone(user) }) : '', [virtualVisitExpirationDate, user])

  const infoTitle = useMemo(() => {
    if (virtualVisitExpired) return t('reactivate_info_title')
    return t('extend_info_title', { expirationDate: expirationDateString })
  }, [expirationDateString, t, virtualVisitExpired])

  const infoBody = useMemo(() => {
    if (virtualVisitExpired) return 'reactivate_info_body'
    return 'extend_info_body'
  }, [virtualVisitExpired])

  return (
    <Fragment>

      <Stack
        width="100%"
        borderRadius=".8rem"
        boxShadow={BOX_SHADOW_XS}
        padding="1.6rem"
        boxSizing="border-box"
        gap="1.6rem"
        sx={{ backgroundColor: WHITE }}
      >
        <Stack
          borderRadius=".8rem"
          padding="1.2rem"
          color={GRAY_900}
          sx={{
            border: `.1rem solid ${BEIGE_600}`,
            backgroundColor: BEIGE_300
          }}
        >
          <Typography variant="text-sm" fontWeight="semiBold">{infoTitle}</Typography>
          <Typography variant="text-sm" fontWeight="regular">
            <Trans t={t} i18nKey={infoBody} values={{ price: formatPrice(extensionPrice, currency) }}>
              <span></span>
            </Trans>
          </Typography>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end">
          <MUIButton
            onClick={() => setIsModalOpen(true)}
          >
            {t(virtualVisitExpired ? 'reactivate_cta' : 'extend_cta')}
          </MUIButton>
        </Stack>
      </Stack>

      <UpsellPayment
        assignmentId={assignmentId}
        productId={extensionProductId}
        productQuantity={1}
        total={extensionTotal}
        isOpen={isModalOpen}
        beforeConfirmAction={(paymentIntentId, billingProductId) => dispatch(extendVirtualVisitHosting(assignmentId, virtualVisitExpired, billingProductId, paymentIntentId))}
        beforeConfirmActionState={extendVirtualVisitRequest?.state}
        beforeConfirmationActionRequest={extendVirtualVisitRequest}
        onClose={() => setIsModalOpen(false)}
        afterClosed={() => dispatch(purgeExtendVirtualVisitHosting(assignmentId, virtualVisitExpired))}
        successTitle={t(virtualVisitExpired ? 'reactivate_success' : 'extend_success')}
        productType={ProductType.VIRTUAL_VISIT}
      >
        <Trans t={t} i18nKey={virtualVisitExpired ? 'reactivate_confirm_text' : 'extend_confirm_text'} values={{ price: formatPrice(extensionTotal, currency) }}>
          <span></span>
        </Trans>
      </UpsellPayment>

    </Fragment>
  )
}
