export const enum GermanyRegionCodes {
  BY = 'BY', // Bavaria
  RP = 'RP', // Rhineland-Palatinate
  SL = 'SL', // Saarland
  TH = 'TH', // Thuringia
}

/** Set of restricted regions in Germany where specific products can get more expensive. */
export const RestrictedRegions = new Set([
  GermanyRegionCodes.BY,
  GermanyRegionCodes.RP,
  GermanyRegionCodes.SL,
  GermanyRegionCodes.TH,
])
