import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RootStore, StatusResponse } from 'models/redux'

import { ActionTypeAPIData } from 'constants/redux'
import { Nullable } from 'models/helpers'
import { VisualFileType } from 'constants/visual'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGalleryProduct } from './GalleryProduct.context'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useGalleryVisuals } from './GalleryVisuals.context'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'
import { useSelector } from 'react-redux'

interface GalleryVisualSelectionInterface {
  selected: Set<string>
  setSelected: Dispatch<SetStateAction<Set<string>>>
  canSelectVisuals: boolean
  selectableVisualsKeys: string[]
  selectableVisualsCount: number
  selectAll: () => void
  selectAllPurchased: () => void
  deselectAll: () => void
  toggleSelectOnImage: (filename?: string | undefined) => void
  selectedNotPurchasedVisualsKeys: Set<string>
  selectedNotPurchasedVisualsCount: number
  selectedPurchasedVisualsCount: number
  selectVisualsRequest?: Nullable<StatusResponse>
}

const defaultGalleryVisualSelectionValue: GalleryVisualSelectionInterface = {
  selected: new Set(),
  setSelected: () => { throw new Error('setSelected is undefined') },
  canSelectVisuals: false,
  selectableVisualsKeys: [],
  selectableVisualsCount: 0,
  selectAll: () => { throw new Error('selectAll is undefined') },
  selectAllPurchased: () => { throw new Error('selectAllPurchased is undefined') },
  deselectAll: () => { throw new Error('deselectAll is undefined') },
  toggleSelectOnImage: () => { throw new Error('toggleSelectOnImage is undefined') },
  selectedNotPurchasedVisualsKeys: new Set(),
  selectedNotPurchasedVisualsCount: 0,
  selectedPurchasedVisualsCount: 0,
  selectVisualsRequest: undefined
}

/** Gallery visual selection context */
export const GalleryVisualSelectionContext = createContext<GalleryVisualSelectionInterface>(defaultGalleryVisualSelectionValue)
/** Gallery visual selection context hook */
export const useGalleryVisualSelection = (): GalleryVisualSelectionInterface => useContext(GalleryVisualSelectionContext)

/** Context provider for gallery visual selection */
export const GalleryVisualSelectionContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const { roles } = useAuth0()

    const {
      stagesUnlockingCreativeSendAssignmentVisualsToAdmin,
    } = useGalleryConstants()

    const {
      assignmentStage,
    } = useGalleryAssignment()

    const {
      thumbnailType,
      originalType,
    } = useGalleryVisualType()

    const {
      purchasedVisualsKeys,
      preselectedFilenames,
    } = useGalleryVisualsMeta()

    const {
      downloadVisuals,
      downloadVisualsKeys,
    } = useGalleryVisuals()

    const {
      isVirtualVisit,
    } = useGalleryProduct()

    const [selected, setSelected] = useState<Set<string>>(new Set())
    const canSelectVisuals = useMemo(() => !isVirtualVisit && (roles.isAdmin || roles.isClient) || (roles.isCreative && !!assignmentStage && stagesUnlockingCreativeSendAssignmentVisualsToAdmin.has(assignmentStage)), [isVirtualVisit, roles, assignmentStage, stagesUnlockingCreativeSendAssignmentVisualsToAdmin])
    const selectableVisualsKeys = useMemo(() => downloadVisualsKeys.filter(key => thumbnailType && downloadVisuals?.[key]?.[thumbnailType] && (roles.isAdmin || roles.isClient || (roles.isCreative && !!assignmentStage && stagesUnlockingCreativeSendAssignmentVisualsToAdmin.has(assignmentStage)))), [downloadVisualsKeys, thumbnailType, downloadVisuals, roles, assignmentStage, stagesUnlockingCreativeSendAssignmentVisualsToAdmin])
    const selectableVisualsCount = useMemo(() => selectableVisualsKeys.length, [selectableVisualsKeys])

    const selectedNotPurchasedVisualsKeys = useMemo(() => new Set([...selected].filter(visual => ![...purchasedVisualsKeys].includes(visual))), [selected, purchasedVisualsKeys])
    const selectedNotPurchasedVisualsCount = useMemo(() => selectedNotPurchasedVisualsKeys.size, [selectedNotPurchasedVisualsKeys])

    const selectedPurchasedVisualsKeys = useMemo(() => new Set([...selected].filter(visual => [...purchasedVisualsKeys].includes(visual))), [selected, purchasedVisualsKeys])
    const selectedPurchasedVisualsCount = useMemo(() => selectedPurchasedVisualsKeys.size, [selectedPurchasedVisualsKeys])

    const selectVisualsRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.SELECT_VISUALS][assignmentId])

    const selectAll = useCallback(() => {
      setSelected(new Set(selectableVisualsKeys))
    }, [selectableVisualsKeys, setSelected])

    const selectAllPurchased = useCallback(() => {
      if (!!purchasedVisualsKeys) setSelected(new Set(purchasedVisualsKeys))
    }, [purchasedVisualsKeys, setSelected])

    const deselectAll = useCallback(() => {
      setSelected(new Set())
    }, [setSelected])

    const toggleSelectOnImage = useCallback((filename?: string) => {
      if (!filename) return
      if (selected.has(filename)) {
        selected.delete(filename)
        setSelected(new Set(selected))
      }
      else setSelected(new Set(selected.add(filename)))
    }, [selected])

    // Preselect all uploaded visuals for creative
    useEffect(() => {
      if (!roles.isCreative) return
      if (!canSelectVisuals) return
      if (selectableVisualsKeys.length === 0) return
      setSelected(new Set(selectableVisualsKeys))
    }, [roles, canSelectVisuals, selectableVisualsKeys])

    // Preselect all RAW visuals for creative and admin
    useEffect(() => {
      if (!(roles.isCreative || roles.isAdmin)) return
      if (originalType !== VisualFileType.RAW) return
      if (preselectedFilenames.length === 0) return
      setSelected(new Set(preselectedFilenames))
    }, [originalType, preselectedFilenames, roles])

    // Reset selection when changing galleries
    useEffect(() => {
      if (selected.size) deselectAll()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentId, deselectAll])

    return (
      <GalleryVisualSelectionContext.Provider
        value={{
          selected,
          setSelected,
          canSelectVisuals,
          selectableVisualsKeys,
          selectableVisualsCount,
          selectAll,
          selectAllPurchased,
          deselectAll,
          toggleSelectOnImage,
          selectedNotPurchasedVisualsKeys,
          selectedNotPurchasedVisualsCount,
          selectedPurchasedVisualsCount,
          selectVisualsRequest,
        }}
      >
        {children}
      </GalleryVisualSelectionContext.Provider>
    )
  }
