import { createTheme } from '@mui/material/styles'
import { HKGroteskFamily } from './theme'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'text-xl': React.CSSProperties
    'text-lg': React.CSSProperties
    'text-md': React.CSSProperties
    'text-sm': React.CSSProperties
    'text-xs': React.CSSProperties
    fontWeightSemiBold: number
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'text-xl'?: React.CSSProperties
    'text-lg'?: React.CSSProperties
    'text-md'?: React.CSSProperties
    'text-sm'?: React.CSSProperties
    'text-xs'?: React.CSSProperties
    fontWeightSemiBold?: number
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'text-xl': true
    'text-lg': true
    'text-md': true
    'text-sm': true
    'text-xs': true
    fontWeightSemiBold: true
  }
}

export const redesignMUITheme = createTheme({
  typography: {
    fontFamily: HKGroteskFamily,
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontSize: '7.2rem',
      fontWeight: 700,
      lineHeight: '8.6rem'
    },
    h2: {
      fontSize: '6rem',
      fontWeight: 700,
      lineHeight: '7.2rem'
    },
    h3: {
      fontSize: '4.8rem',
      fontWeight: 700,
      lineHeight: '5.7rem'
    },
    h4: {
      fontSize: '3.6rem',
      fontWeight: 700,
      lineHeight: '4.3rem'
    },
    h5: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '3.6rem'
    },
    h6: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '3rem'
    },
    'text-xl': {
      fontSize: '2rem',
      lineHeight: '3rem'
    },
    'text-lg': {
      fontSize: '1.8rem',
      lineHeight: '2.8rem'
    },
    'text-md': {
      fontSize: '1.6rem',
      lineHeight: '2.4rem'
    },
    'text-sm': {
      fontSize: '1.4rem',
      lineHeight: '1.9rem'
    },
    'text-xs': {
      fontSize: '1.2rem',
      lineHeight: '1.8rem'
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})
