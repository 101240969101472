import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Box, Fade, Typography } from '@mui/material'
import { BuildingLocation, BuildingType, ConsumptionCertificateBasementType, ConsumptionCertificateVentilationType } from 'constants/purchaseFlow'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { FC, useEffect, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import { CheckboxItem } from 'components/pages/PurchaseFlow/common/CheckboxItem'
import { GRAY_900 } from 'constants/styling/theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Label } from 'components/common/Label'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import { MUIInputFieldCounter } from 'components/common/MUIInputFieldCounter'
import { MUITooltip } from 'components/common/MUITooltip'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { SystemMessage } from 'components/common/SystemMessage'
import { usePropertyInformation } from './PropertyInformation.context'
import { useTranslation } from 'react-i18next'

const InfoInputWidth = { xs: '100%', sm: '25rem' }

/**
 * Content for the PropertyInformation in the ConsumptionCertificateStepController.
 * 
 * @example
 * <PropertyInformation />
 */
export const PropertyInformation: FC = () => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'consumption_certificate_step.property_information_page' })

  const {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    basementType,
    setBasementType,
    ventilationType,
    setVentilationType,
    livingSpace,
    setLivingSpace,
    yearOfRenovation,
    setYearOfRenovation,
    commercialArea,
    setCommercialArea,
    isConstructionConfirmed,
    setIsConstructionConfirmed,
    isShowConstructionNote,
    commercialAreaType,
    setCommercialAreaType
  } = usePropertyInformation()

  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  const { selectedCategory } = usePurchaseFlowConfig()

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.OPENS_PROPERTY_INFORMATION, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })
    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SectionedBorderBox title={t('title')}>
      <Stack gap="1.8rem" width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">

        {/* Building type selection */}
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('property_type')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!buildingType ? `building_type.${buildingType}` : 'select_building_type_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(BuildingType).map(type => (
            <MUIDropdownItem key={type} onClick={() => setBuildingType(type)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`building_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

        {/* Building location type selection */}
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('building_location')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!buildingLocationType ? `building_location_type.${buildingLocationType}` : 'select_building_location_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(BuildingLocation).map(type => (
            <MUIDropdownItem key={type} onClick={() => setBuildingLocationType(type)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`building_location_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

        {/* Number of residential unit */}
        <MUIInputFieldCounter
          isCenterInput
          minValue={1}
          counterValue={residentialUnit}
          counterOnChange={setResidentialUnit}
          inputBoxWidth="14rem"
          label={t('residential_units')}
        />

        {/* Commercial area - if mixed building type is selected */}
        {buildingType === BuildingType.MIXED_USE_BUILDING &&
          <>
            <Box width={InfoInputWidth}>
              <MUIInputField
                required
                label={t('commercial_area')}
                value={commercialArea}
                isError={showError && !commercialArea}
                onBlur={handleOnInputBlur}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '')
                  setCommercialArea(value)
                }}
              />
            </Box>
            <Box width={InfoInputWidth}>
              <MUIInputField
                required
                label={t('commercial_area_type')}
                value={commercialAreaType}
                isError={showError && !commercialAreaType}
                onChange={(e) => setCommercialAreaType(e.target.value)}
                onBlur={handleOnInputBlur}
              />
            </Box>
          </>
        }

        {/* Construction year */}
        <Box width={InfoInputWidth}>
          <MUIInputField
            required
            label={t('construction_year')}
            value={constructionYear}
            isError={showError && (!constructionYear || constructionYear.length < 4)}
            onBlur={handleOnInputBlur}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              setConstructionYear(value)
            }}
          />
        </Box>

        {isShowConstructionNote &&
          <Fade in={isShowConstructionNote}>
            <Stack gap="1.6rem">
              <SystemMessage
                variant="warning"
                title={t('construction_note_title')}
                message={t('construction_note_content')}
              />
              <CheckboxItem
                title={t('construction_note_confirm')}
                checked={isConstructionConfirmed}
                onClick={() => setIsConstructionConfirmed(!isConstructionConfirmed)}
              />
            </Stack>
          </Fade>
        }

        {/* Living space */}
        <Box width={InfoInputWidth}>
          <MUIInputField
            required
            label={t('living_space')}
            value={livingSpace}
            isError={showError && !livingSpace}
            tooltip={
              <MUITooltip content={t('living_area_tooltip')}>
                <InfoOutlinedIcon fontSize="medium" sx={{ paddingTop: '.4rem' }} />
              </MUITooltip>
            }
            onBlur={handleOnInputBlur}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              setLivingSpace(value)
            }}
          />
        </Box>

        {/* Year of renovation */}
        <Box width={InfoInputWidth}>
          <MUIInputField
            label={t('year_of_renovation')}
            value={yearOfRenovation}
            isError={!!yearOfRenovation && yearOfRenovation.length < 4}
            onBlur={handleOnInputBlur}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              setYearOfRenovation(value)
            }}
          />
        </Box>

        {/* Checkbox info for basement and ventilation. */}
        <Stack gap=".6rem">
          <Label text={t('basement')} />

          {Object.values(ConsumptionCertificateBasementType).map(type => (
            <CheckboxItem
              key={type}
              type="radio"
              title={t(`basement_type.${type}`)}
              checked={basementType === type}
              onClick={() => setBasementType(type)}
            />
          ))}
        </Stack>

        <Stack gap=".6rem">
          <Label text={t('ventilation')} />

          {Object.values(ConsumptionCertificateVentilationType).map(type => (
            <CheckboxItem
              key={type}
              type="radio"
              title={t(`ventilation_type.${type}`)}
              checked={ventilationType === type}
              onClick={() => setVentilationType(type)}
            />
          ))}
        </Stack>

      </Stack>
    </SectionedBorderBox>
  )
}
