import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { AxiosResponse } from 'axios'
import { Nullable } from 'models/helpers'
import { Organization } from 'models/organization'
import { RoleMimetype } from 'constants/API'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

enum Endpoints {
  GET_ORGANIZATION = '/organization/{organizationId}',
  ORGANIZATION_ME = '/organization/me',
}

// QUERIES
export function useGetOrganizationMe(enabled: boolean = false) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ORGANIZATION, QueryType.GET],
    queryFn: () => api.get<Nullable<Organization>>(
      Endpoints.ORGANIZATION_ME,
      {},
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT // TODO: Fix on BE so we can send proper accept header
        }
      }
    ),
    enabled
  })
}

// MUTATIONS
export function useGetOrganization$() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<Organization>, { organizationId: string }>(
    ({ organizationId }) => api.get(
      Endpoints.GET_ORGANIZATION,
      { organizationId },
      true
    )
  )
}
