import '../../Dashboard/Dashboard.sass'

import { AnalyticsEvent, logAnalyticsEvent, standardDebouncedLogAnalyticsEvent, trackIntercomEvent } from 'utils/analytics'
import { BEIGE_300, CORAL_600, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, Stack } from '@mui/material'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { OrderAssignmentStatusFilter, OwnershipScope, Params, SortBy, SortDirection } from 'constants/misc'
import { OrderListFilters, useInfiniteListOrders } from 'dataQueries/order.query'
import { externalNavigate, useDebouncedMemo } from 'utils/helpers'
import { useDispatch, useSelector } from 'react-redux'

import { ActionTypeAPIData } from 'constants/redux'
import { AssignmentDateFilters } from '../../Dashboard/DateFilters/AssignmentDateFilters.module'
import { AssignmentSortByFilters } from 'components/pages/Dashboard/Sort/AssignmentSortByFilters.module'
import { AssignmentSortDirectionFilters } from '../../Dashboard/Sort/AssignmentSortDirectionFilters.module'
import { ClearButton } from '../../Dashboard/ClearButton'
import { ClientAssignmentStatusFilters } from './ClientAssignmentStatusFilters.module'
import { ControlPanel } from 'components/pages/Dashboard/ControlPanel'
import { DealAssignmentCard } from 'components/common/DealAssignmentCard'
import { DealDTO } from 'models/deal'
import { DealDetailPopup } from 'components/common/DealDetailPopup/DealDetailPopup'
import FetchedContent from 'components/common/FetchedContent/FetchedContent'
import { InfiniteList } from 'components/common/InfiniteList/InfiniteList.component'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIDivider } from 'components/common/MUIDivider/MUIDivider.component'
import { MUITooltip } from 'components/common/MUITooltip'
import { OwnerFilterTab } from 'components/common/OwnerFilterTab'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { RootStore } from 'models/redux'
import { SearchFilter } from '../../Dashboard/SearchFilter'
import { UserFilter } from 'components/common/UserFilter'
import { getUserWorkspaces } from 'redux/Individual/User/GetUserWorkspaces'
import moment from 'moment-timezone'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Page listing orders of logged in client with filters
 * @example <ClientDashboardController />
 */
export const ClientDashboardController: React.FC = () => {
  const dispatch = useDispatch()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { t } = useTranslation(['dashboard_client', 'dashboard'])

  const params = useBkbnSearchParams()

  const {
    hasUserActiveSubscription,
    nonPersonalUserWorkspaces,
    clientData,
  } = useUserData()

  const [orderPopupId, setOrderPopupId] = useState<number | null>(null)

  // Workspaces
  const allUserWorkspacesResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_GET_WORKSPACES])
  const allUserWorkspacesData = useMemo(() => allUserWorkspacesResponse.data?.workspaces, [allUserWorkspacesResponse])
  const allUserWorkspacesMembers = useMemo(() => allUserWorkspacesData?.map(workspace => workspace.members).flat() ?? [], [allUserWorkspacesData])

  // SEARCH DEBOUNCE HANDLING
  const search = useMemo(() => params.get(Params.SEARCH) || '', [params])
  const debouncedSearch = useDebouncedMemo(() => search, [search], 300)

  // FILTERS
  const filters = useMemo<OrderListFilters>(() => ({
    scope: params.get(Params.OWNERSHIP) as OwnershipScope || OwnershipScope.INDIVIDUAL,
    status: params.get(Params.STATUS) as OrderAssignmentStatusFilter || OrderAssignmentStatusFilter.FINISHED,
    userIds: params.getArray(Params.USERS),
    search: debouncedSearch || null,
    sortDirection: params.get(Params.SORT_DIRECTION) as SortDirection || SortDirection.DESCENDING,
    creationDateStart: moment(params.get(Params.CREATION_DATE_START)),
    creationDateEnd: moment(params.get(Params.CREATION_DATE_END)),
    shootingDateStart: moment(params.get(Params.SHOOTING_DATE_START)),
    shootingDateEnd: moment(params.get(Params.SHOOTING_DATE_END)),
    sortBy: params.get(Params.SORT_BY) as SortBy || SortBy.DEFAULT,
  }), [params, debouncedSearch])

  const selectedUsers = useMemo(() => new Set(filters.userIds), [filters.userIds])
  const restrictedWorkspaceNames = clientData?.workspaces.filter((workspace) => workspace.isRestricted)

  const orderList = useInfiniteListOrders(
    'CLIENT_ORDER_LIST',
    filters
  )

  // Fetch all workspaces the user is a member of if active subscription
  useEffect(() => {
    if (hasUserActiveSubscription) dispatch(getUserWorkspaces())
  }, [hasUserActiveSubscription, dispatch])

  /* TODO:
      In future, make an actual system for these things to work like flags OR have some better data to create calculated condition for the trigger
      For now, this is kinda handled by our Intercom settings which allows a tour to be only triggered once (per user), so all the subsequent triggers will be ignored
  */
  useEffect(() => {
    if (!clientData?.firstLoginDateTime || moment(clientData.firstLoginDateTime).isAfter(moment().subtract(3, 'days'))) {
      trackIntercomEvent(AnalyticsEvent.CLIENT_FIRST_TIME_DASHBOARD)
    }
  })

  const getOwnerFilterIcon = () => {
    if (!hasUserActiveSubscription) return <LockOutlinedIcon fontSize='inherit' />
    else if (!!restrictedWorkspaceNames?.length) return <InfoOutlinedIcon fontSize='inherit' sx={{ color: CORAL_600 }} />
    else return <PeopleAltOutlinedIcon fontSize='inherit' />
  }

  const listTitle = useMemo(() => {
    switch (filters.status) {
      case OrderAssignmentStatusFilter.FINISHED:
        return 'dashboard:finished_deals'
      case OrderAssignmentStatusFilter.ONGOING:
        return 'dashboard:ongoing_deals'
      case OrderAssignmentStatusFilter.CANCELLED:
        return 'dashboard:cancelled_deals'

      default:
        return ''
    }
  }, [filters.status])

  return (
    <PageTransition>
      <Stack
        direction="row"
        alignItems="stretch"
        sx={{
          position: 'absolute',
          top: '6.4rem',
          left: 0,
          bottom: 0,
          width: '100%',
          height: 'calc(100vh - 6.4rem)',
          overflow: 'hidden',
          background: BEIGE_300,
        }}
      >

        <Box flex="0 0 auto">
          <ControlPanel title={t('dashboard:filters')}>

            <div className='dashboard-filters'>
              <div className="ownership-tabs">
                <OwnerFilterTab
                  active={filters.scope === OwnershipScope.INDIVIDUAL}
                  icon={<PersonOutlineIcon fontSize='inherit' />}
                  text={t('my_visuals')}
                  onClick={() => {
                    params.set(
                      [Params.OWNERSHIP, OwnershipScope.INDIVIDUAL],
                      [Params.USERS, null]
                    )
                  }}
                />
                <Stack flexGrow={1}>
                  <MUITooltip placement='bottom' content={!!restrictedWorkspaceNames?.length ? t('all_visuals_restricted') : undefined}>
                    <OwnerFilterTab
                      active={filters.scope === OwnershipScope.WORKSPACE}
                      icon={getOwnerFilterIcon()}
                      text={t('all_visuals')}
                      unavailable={hasUserActiveSubscription ? false : true}
                      onClick={() => {
                        if (hasUserActiveSubscription) {
                          params.set([Params.OWNERSHIP, OwnershipScope.WORKSPACE])
                          logAnalyticsEvent('Use Workspace\'s Visuals Tab', {
                            userId: clientData?.id,
                            workspaceIds: nonPersonalUserWorkspaces.map(workspace => workspace.id),
                          })
                        }
                        else {
                          externalNavigate(Path.PRICING)
                          logAnalyticsEvent('Click All order Upsell', {
                            userEmail: clientData?.email,
                            organizationId: clientData?.organizationId,
                          })
                        }
                      }}
                    />
                  </MUITooltip>
                </Stack>
              </div>

              <MUIDivider />

              <SearchFilter
                placeholder={t('search_order')}
                value={search}
                onChange={(value) => {
                  standardDebouncedLogAnalyticsEvent(`${filters.scope === OwnershipScope.INDIVIDUAL ? 'My Visuals Filter - Search Bar' : 'All Visuals Filter - Search Bar'}`, {
                    userEmail: clientData?.email,
                    organizationId: clientData?.organizationId
                  })

                  params.set([Params.SEARCH, value])
                }}
                onClick={() => {
                  logAnalyticsEvent(`${filters.scope === OwnershipScope.INDIVIDUAL ? 'My Visuals Filter - Click Search Icon' : 'All Visuals Filter - Click Search Icon'}`, {
                    userEmail: clientData?.email,
                    organizationId: clientData?.organizationId,
                  })
                }}
              />

              <ClientAssignmentStatusFilters filters={filters} />

              <AssignmentDateFilters filters={filters} />

              <AssignmentSortDirectionFilters filters={filters} />

              <AssignmentSortByFilters filters={filters} />

              {filters.scope === OwnershipScope.WORKSPACE && hasUserActiveSubscription &&
                <div className="member-filter-wrap">
                  <div className='member-filter-header'>
                    <span className="filter-section-label">{t('filter_members_label')}</span>
                    {!!selectedUsers.size &&
                      <ClearButton
                        text={t('dashboard:clear_filter')}
                        onClick={() => {
                          params.set([Params.USERS, null])
                        }}
                      />
                    }
                  </div>
                  <FetchedContent
                    request={allUserWorkspacesResponse}
                  >
                    <UserFilter
                      users={allUserWorkspacesMembers}
                      isExpandable
                      maxUnexpandedUserCount={isMobileView ? 5 : 12}
                      onChange={userId => {
                        const newUsers = new Set(selectedUsers)

                        if (selectedUsers.has(userId)) newUsers.delete(userId)
                        else newUsers.add(userId)

                        params.set([Params.USERS, Array.from(newUsers)])

                        logAnalyticsEvent('Member Filter', {
                          selectedMember: newUsers.size
                        })
                      }}
                      selectedUserIds={selectedUsers}
                    />
                  </FetchedContent>
                </div>
              }

            </div>
          </ControlPanel>
        </Box>

        <Stack flex="1 1 100%" alignItems="center" sx={{ overflowY: 'auto', paddingTop: '4rem' }}>
          <InfiniteList<DealDTO>
            query={orderList}
            header={(
              <div>
                <h2>{t(listTitle)}</h2>
                <MUIDivider />
              </div>
            )}
            renderItem={(data) => (
              <Fragment key={data.id}>
                <DealAssignmentCard
                  data={data}
                  onMoreInfoClick={() => setOrderPopupId(data.id)}
                />
                <DealDetailPopup
                  data={data}
                  isOpen={orderPopupId === data.id}
                  onClickOutside={() => setOrderPopupId(null)}
                  onClickClose={() => setOrderPopupId(null)}
                />
              </Fragment>
            )}
            sx={{
              boxSizing: 'border-box',
              width: '95%',
              maxWidth: '90rem',
            }}
          />
        </Stack>

      </Stack>
    </PageTransition>
  )
}
