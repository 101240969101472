import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { DocumentCategory, EditingCategory, NoUploadEditingKinds, ProductKind, ProductKindsWithPFDocumentUpload, UnlimitedUploadKinds } from 'constants/product'
import { FileAction, FileState } from 'components/common/FileAPI'
import { FloorPlanCertificationStep, useFloorPlanCertification } from '../../steps/FloorPlanCertification/_main'
import { InstructionStep, StyleSelectionStep } from '../../steps'
import { To, useNavigate } from 'react-router-dom'
import { isAddressValid, isDocumentCategory, isShootingCategory } from 'utils/validators'
import { useCallback, useEffect, useMemo } from 'react'

import { ConsumptionCertificateStep } from '../../steps/ConsumptionCertificateStep/_main/ConsumptionCertificateStep.module'
import { EnergyCertificateStep } from '../../steps/EnergyCertificateStep'
import { FeatureFlag } from 'utils/featureFlags'
import { FloorPlanConfigStep } from '../../steps/FloorPlanConfig'
import { MeasurementStep } from '../../steps/MeasurementStep'
import { Path } from 'constants/router'
import { PowerOfAttorneyStep } from '../../steps/PowerOfAttorneyStep'
import { PropertyInformationStep } from '../../steps/PropertyInformationStep'
import constate from 'constate'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useAuthoritiesDocuments } from './AuthoritiesDocuments.context'
import { useBookCreative } from '../../common'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useFlag } from '@unleash/proxy-client-react'
import { useFloorPlanConfig } from '../../steps/FloorPlanConfig/_main/FloorPlanConfigStep.context'
import { useFloorPlanLogo } from '../../steps/FloorPlanConfig/_main/FloorPlanLogo.context'
import { usePropertyInformationStep } from '../../steps/PropertyInformationStep/PropertyInformationStep.context'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { usePurchaseFlowOrderMeta } from './PurchaseFlowOrderMeta.context'
import { usePurchaseFlowPlaceOrder } from '../../steps/Validation/_main/contexts'
import { usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { usePurchaseFlowRealEstateProperty } from './PurchaseFlowRealEstateProperty.context'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTargetOrderUser } from './TargetOrderUser.context'
import { useTranslation } from 'react-i18next'
import { useUploadedPurchaseFlowVisuals } from './UploadedPurchaseFlowVisuals.context'

/** Enum of all purchase flow steps */
export enum PurchaseFlowStepKey {
  CLIENT = 'CLIENT',
  CATEGORY = 'CATEGORY',
  ADDRESS = 'ADDRESS',
  PRODUCT = 'PRODUCT',
  STYLE_SECTION = 'STYLE_SECTION',
  INSTRUCTIONS = 'INSTRUCTIONS',
  BILLING = 'BILLING',
  VALIDATION = 'VALIDATION',
  FLOOR_PLAN_CONFIG = 'FLOOR_PLAN_CONFIG',
  FLOOR_PLAN_CERTIFICATION = 'FLOOR_PLAN_CERTIFICATION',
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  MEASUREMENT_REQUIREMENTS = 'MEASUREMENT_REQUIREMENTS',
  ENERGY_CERTIFICATE_OF_NEED = 'ENERGY_CERTIFICATE_OF_NEED',
  CONSUMPTION_CERTIFICATE = 'CONSUMPTION_CERTIFICATE',
}

export const [PurchaseFlowUtilityProvider, usePurchaseFlowUtilities] = constate(() => {
  const { roles } = useAuth0()
  const { t } = useTranslation(['purchase_flow'])
  const navigate = useNavigate()
  const { showConfirm } = useActionPopup()
  const flowCtrl = useDynamicFlowController()
  const allowFloorPlanFlow = useFlag(FeatureFlag.ALLOW_FLOOR_PLAN_CONFIG_FLOW)
  const allowFloorPlanCertificationFlow = useFlag(FeatureFlag.ALLOW_FLOOR_PLAN_CERTIFICATION_FLOW)
  const allowAuthoritiesDocs = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)
  const allowMeasurementStep = useFlag(FeatureFlag.ALLOW_MEASUREMENT_STEP)
  const allowPropertySelectionStep = useFlag(FeatureFlag.ALLOW_PROPERTY_SELECTION_STEP)

  const { clientSelectionError } = useTargetOrderUser()
  const { uploadedImagesByProduct, uploadInProgress, documentFilesController } = useUploadedPurchaseFlowVisuals()
  const { allRoomsCompleted } = useRoomAPI()
  const { selectedCreative } = useBookCreative()
  const { isTemplateLogoUploading } = useFloorPlanLogo()
  const { isPlaceOrderButtonDisabled } = usePurchaseFlowPlaceOrder()
  const { floorPlanCertificationInformation } = useFloorPlanCertification()
  const { selectedCategory, isInstructionStepSkipped } = usePurchaseFlowConfig()
  const {
    showDisclaimer,
    disclaimerText
  } = useFloorPlanConfig()
  const {
    selectedPropertyType,
    selectedAssignmentPlace,
    selectedProperty,
    propertyName
  } = usePurchaseFlowRealEstateProperty()
  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
    hasFloorPlanCertificationProduct,
    hasFloorPlanProduct,
    hasMeasurementProduct,
    requiresConsumptionCertificateConfig,
    requiresEnergyCertificateConfig,
  } = usePurchaseFlowProducts()
  const {
    areCurrentMeetingFieldsValid,
    selectedPaymentMethod,
    selectedExtraServices,
    selectedMeetingType,
    billingAddressError,
  } = usePurchaseFlowOrderMeta()
  const {
    landRegisterSheetItems,
    landlordDateOfBirth,
    landlordFirstName,
    landlordLastName,
    isLandlordInfoPartiallyFilled,
  } = usePropertyInformationStep()
  const { searchProduct } = useAuthoritiesDocuments()

  /* -- PF STEPS AND NAVIGATION UTILITIES -- */
  const exitPurchaseFlow = useCallback(async (showConfirmation = false, pathTo: To = Path.INDEX) => {
    if (showConfirmation) {
      const response = await showConfirm(
        t('exit_confirm.text'),
        {
          title: t('exit_confirm.title'),
          confirmAcceptText: t('exit_confirm.accept_button'),
          confirmDenyText: t('exit_confirm.deny_button'),
        }
      )

      if (!response) return

      // Only log analytics when the user confirms exiting the PF.
      logAnalyticsEvent(AnalyticsEvent.CLOSE_PURCHASE_FLOW, { OrderStep: flowCtrl.currentNode?.key })
    }

    navigate(pathTo)
  }, [flowCtrl.currentNode, navigate, showConfirm, t])
  /* -- END OF PF STEPS AND NAVIGATION UTILITIES -- */

  /* -- STEP VALIDATORS -- */
  const isUserStepValid = useMemo(() => {
    if (!roles.isAdmin) return true

    return !clientSelectionError
  }, [clientSelectionError, roles.isAdmin])

  const isAddressStepValid = useMemo(() => {
    if (!isUserStepValid) return false
    if (!selectedAssignmentPlace) return false
    if (!isAddressValid(selectedAssignmentPlace)) return false
    if (allowPropertySelectionStep && !selectedProperty && !propertyName) return false

    return true
  }, [allowPropertySelectionStep, isUserStepValid, propertyName, selectedAssignmentPlace, selectedProperty])

  const isCategoryStepValid = useMemo(() => {
    if (!isAddressStepValid) return false

    return !!selectedCategory
  }, [isAddressStepValid, selectedCategory])

  const isProductStepValid = useMemo(() => {
    if (!isCategoryStepValid) return false

    const selectedProductsArray = Object.values(selectedProducts)

    if (selectedProductsArray.length <= 0) return false

    // Shooting products need no extras, return true for them
    if (selectedCategory && isShootingCategory(selectedCategory)) return true

    // Document products need no extras, return true for them
    if (selectedCategory && isDocumentCategory(selectedCategory)) return true

    // Handle image upload validation for editing/staging
    if (uploadInProgress) return false

    // For editing check, that all products requiring an image have uploaded at least 1 image (all except renderings)
    if (selectedCategory === EditingCategory.EDITING) {
      return selectedProductsArray.every((product) => {
        if (NoUploadEditingKinds.has(product.kind)) return true
        if (UnlimitedUploadKinds.has(product.kind) && Object.values(uploadedImagesByProduct[product.id] || {}).length >= product.quantity) return true

        if (ProductKindsWithPFDocumentUpload.has(product.kind)) {
          const files = (documentFilesController.filesByTag[product.id] ?? []).filter((file) => file.action === FileAction.UPLOAD && file.state === FileState.SUCCESS)

          return !!files && files.length > 0
        }

        return Object.values(uploadedImagesByProduct[product.id] || {}).length === product.quantity
      })
    }

    // For staging check, that all products have exact number of uploaded images as quantity selected
    if (selectedCategory === EditingCategory.STAGING) {
      return selectedProductsArray.every((product) =>
        product.quantity === Object.keys(uploadedImagesByProduct[product.id] || {}).length
      )
    }

    // Fall-through case should result to false = invalid
    return false
  }, [documentFilesController.filesByTag, isCategoryStepValid, selectedCategory, selectedProducts, uploadInProgress, uploadedImagesByProduct])

  const isFloorPlanConfigStepValid = useMemo(() => {
    if (isTemplateLogoUploading) return false
    if (!selectedPropertyType) return false
    if (showDisclaimer && !disclaimerText) return false

    return true
  }, [disclaimerText, isTemplateLogoUploading, selectedPropertyType, showDisclaimer])

  const isFloorPlanCertificationStepValid = useMemo(() => {
    if (floorPlanCertificationInformation.numberOfFloors === 0) return false
    // remove invalid floor 0 with empty values and check all other floors contain name and numberOfRooms values
    if (floorPlanCertificationInformation.floors.slice(1).some((floor) => !floor.name || floor.numberOfRooms === 0)) return false

    return true
  }, [floorPlanCertificationInformation])

  const isStagingStepValid = useMemo(() => {
    if (selectedCategory !== EditingCategory.STAGING) return true
    if (!isProductStepValid) return false

    return allRoomsCompleted
  }, [allRoomsCompleted, isProductStepValid, selectedCategory])

  const isInstructionStepValid = useMemo(() => {
    if (!isStagingStepValid || !selectedCategory) return false

    if (isShootingCategory(selectedCategory) && (!selectedMeetingType || !areCurrentMeetingFieldsValid)) return false
    if (selectedExtraServices.has(ProductKind.FAVOURITE_CREATIVE) && !selectedCreative) return false

    return true
  }, [areCurrentMeetingFieldsValid, isStagingStepValid, selectedCategory, selectedCreative, selectedExtraServices, selectedMeetingType])

  const isBillingStepValid = useMemo(() => {
    if (!isInstructionStepSkipped && !isInstructionStepValid) return false

    if (!selectedPaymentMethod) return false
    if (billingAddressError) return false

    return true
  }, [isInstructionStepValid, selectedPaymentMethod, billingAddressError, isInstructionStepSkipped])

  const isRecapStepValid = useMemo(() => {
    if (!isBillingStepValid) return false

    return !isPlaceOrderButtonDisabled
  }, [isBillingStepValid, isPlaceOrderButtonDisabled])

  const isLastStep = useMemo(() => !!flowCtrl.currentNode && !flowCtrl.currentNode.next, [flowCtrl.currentNode])

  const isPropertyInformationStepValid = useMemo(() => {
    if (!isProductStepValid) return false
    if (!landRegisterSheetItems) return false

    if (!searchProduct || !selectedProducts[searchProduct.id]) {
      for (const [, value] of landRegisterSheetItems.entries()) {
        if (!value.name || !value.district) return false

        for (const floor of value.floors) {
          if (!floor.parcels.size) return false

          for (const parcel of floor.parcels) {
            if (!parcel.number) return false
          }
        }
      }
    }

    if (isLandlordInfoPartiallyFilled && !(landlordFirstName && landlordLastName && landlordDateOfBirth)) return false

    return true
  }, [isLandlordInfoPartiallyFilled, isProductStepValid, landRegisterSheetItems, landlordDateOfBirth, landlordFirstName, landlordLastName, searchProduct, selectedProducts])

  useEffect(() => {
    if (selectedCategory && selectedCategory === EditingCategory.STAGING) {
      if (!flowCtrl.nodes.get(PurchaseFlowStepKey.STYLE_SECTION)) flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.STYLE_SECTION,
        {
          component: <StyleSelectionStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else {
      flowCtrl.removeNode(PurchaseFlowStepKey.STYLE_SECTION)
    }

    if (isInstructionStepSkipped) flowCtrl.removeNode(PurchaseFlowStepKey.INSTRUCTIONS)
    else if (!flowCtrl.nodes.get(PurchaseFlowStepKey.INSTRUCTIONS)) flowCtrl.insertBefore(
      PurchaseFlowStepKey.BILLING,
      PurchaseFlowStepKey.INSTRUCTIONS,
      {
        component: <InstructionStep />,
        hasFooter: true,
        type: 'main',
      }
    )

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstructionStepSkipped, selectedCategory])

  // Handle FloorPlanConfigStep add/remove
  useEffect(() => {
    if (!allowFloorPlanFlow) return
    if (hasFloorPlanCertificationProduct) return

    if (hasFloorPlanProduct && !flowCtrl.nodes.get(PurchaseFlowStepKey.FLOOR_PLAN_CONFIG)) {
      flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.FLOOR_PLAN_CONFIG,
        {
          component: <FloorPlanConfigStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!hasFloorPlanProduct) flowCtrl.removeNode(PurchaseFlowStepKey.FLOOR_PLAN_CONFIG)

    // Omit flowCtrl to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowFloorPlanFlow, hasFloorPlanProduct, selectedKinds, selectedProductTypes])

  // Handle Document steps add/remove
  useEffect(() => {
    if (!allowAuthoritiesDocs) return

    if (selectedCategory && selectedCategory === DocumentCategory.AUTHORITIES_DOCUMENTS) {
      if (!flowCtrl.nodes.get(PurchaseFlowStepKey.POWER_OF_ATTORNEY)) flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.POWER_OF_ATTORNEY,
        {
          component: <PowerOfAttorneyStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else {
      flowCtrl.removeNode(PurchaseFlowStepKey.POWER_OF_ATTORNEY)
    }

    const isLandRegistrationRequired = Object.values(selectedProducts).some((product) => product.isLandRegistrationNeeded)

    if (isLandRegistrationRequired && !flowCtrl.nodes.get(PurchaseFlowStepKey.PROPERTY_INFORMATION)) {
      flowCtrl.insertBefore(
        PurchaseFlowStepKey.POWER_OF_ATTORNEY,
        PurchaseFlowStepKey.PROPERTY_INFORMATION,
        {
          component: <PropertyInformationStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!isLandRegistrationRequired) {
      flowCtrl.removeNode(PurchaseFlowStepKey.PROPERTY_INFORMATION)
    }

    // Omit flowCtrl to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowAuthoritiesDocs, selectedCategory, selectedKinds, selectedProductTypes, selectedProducts])

  // Handle measurement steps add/remove
  useEffect(() => {
    if (!allowMeasurementStep) return

    if (hasMeasurementProduct && !flowCtrl.nodes.get(PurchaseFlowStepKey.MEASUREMENT_REQUIREMENTS)) {
      flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.MEASUREMENT_REQUIREMENTS,
        {
          component: <MeasurementStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!hasMeasurementProduct) {
      flowCtrl.removeNode(PurchaseFlowStepKey.MEASUREMENT_REQUIREMENTS)
    }

    // Omit flowCtrl to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowMeasurementStep, hasMeasurementProduct])

  // Handle FloorPlanCertificationStep add/remove
  useEffect(() => {
    if (!allowFloorPlanCertificationFlow) return

    if (hasFloorPlanCertificationProduct && !flowCtrl.nodes.get(PurchaseFlowStepKey.FLOOR_PLAN_CERTIFICATION)) {
      flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.FLOOR_PLAN_CERTIFICATION,
        {
          component: <FloorPlanCertificationStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!hasFloorPlanCertificationProduct) flowCtrl.removeNode(PurchaseFlowStepKey.FLOOR_PLAN_CERTIFICATION)

    // Omit flowCtrl to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowFloorPlanCertificationFlow, hasFloorPlanCertificationProduct])

  // TODO: Implement validation for measurement requirements
  const isMeasurementRequirementsStepValid = useMemo(() => true, [])

  // Handle energy certificate
  useEffect(() => {
    if (requiresEnergyCertificateConfig && !flowCtrl.nodes.get(PurchaseFlowStepKey.ENERGY_CERTIFICATE_OF_NEED)) {
      flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.ENERGY_CERTIFICATE_OF_NEED,
        {
          component: <EnergyCertificateStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!requiresEnergyCertificateConfig) {
      flowCtrl.removeNode(PurchaseFlowStepKey.ENERGY_CERTIFICATE_OF_NEED)
    }
  }, [flowCtrl, requiresEnergyCertificateConfig])

  // Handle consumption certificate
  useEffect(() => {
    if (requiresConsumptionCertificateConfig && !flowCtrl.nodes.get(PurchaseFlowStepKey.CONSUMPTION_CERTIFICATE)) {
      flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.CONSUMPTION_CERTIFICATE,
        {
          component: <ConsumptionCertificateStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!requiresConsumptionCertificateConfig) {
      flowCtrl.removeNode(PurchaseFlowStepKey.CONSUMPTION_CERTIFICATE)
    }
  }, [flowCtrl, requiresConsumptionCertificateConfig])

  return {
    isLastStep,
    isUserStepValid,
    isCategoryStepValid,
    isAddressStepValid,
    isProductStepValid,
    isStagingStepValid,
    isFloorPlanConfigStepValid,
    isFloorPlanCertificationStepValid,
    isInstructionStepValid,
    isBillingStepValid,
    isRecapStepValid,
    isInstructionStepSkipped,
    isPropertyInformationStepValid,
    isMeasurementRequirementsStepValid,
    exitPurchaseFlow,
    hasFloorPlanProduct,
  }
})
