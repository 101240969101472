import { Box, useMediaQuery } from '@mui/material'
import { CurrentActionType, ToolBarType, useClientGallery } from '../_main/contexts'
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { decimalFromBig, formatPrice } from 'utils/price'
import { useGalleryAdditionalVisuals, useGalleryAssignment, useGalleryDeal, useGalleryProduct, useGalleryVisualSelection, useGalleryVisuals, useGalleryVisualsDownloadArchive, useGalleryVisualsMeta } from '../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { GalleryFloatingToolBar } from 'components/common/Gallery/GalleryFloatingToolBar'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { useNavigate } from 'react-router-dom'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Floating tool bar for Client Gallery.
 * @example <ClientGalleryFloatingToolBar />
 */
export const ClientGalleryFloatingToolBar: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const { listedVisuals } = useGalleryVisuals()
  const { hasUserActiveSubscription } = useUserData()
  const {
    initiateArchiveDownload,
    isArchiveDisabled
  } = useGalleryVisualsDownloadArchive()
  const {
    assignmentId,
    toolBarType,
    isToolBarOpen,
    setIsToolBarOpen,
    setToolBarType,
    setCurrentActionType
  } = useClientGallery()
  const {
    selectMaxValue,
    purchasedVisualsExist,
    purchasedVisualsKeys,
  } = useGalleryVisualsMeta()
  const {
    pricePerAdditionalVisual,
    selectedAdditionalVisualsCount,
    totalForAdditionalVisualsAfterDiscount,
    currency
  } = useGalleryAdditionalVisuals()
  const {
    selected,
    selectedPurchasedVisualsCount,
    selectableVisualsCount,
    selectAll,
    selectAllPurchased,
    deselectAll,
  } = useGalleryVisualSelection()
  const { isVideo } = useGalleryProduct()
  const { logGalleryEvent, product } = useGalleryAssignment()
  const { dealData } = useGalleryDeal()

  /** Disabled visuals editing for subscription clients with no purchased visuals exist and no selected visuals */
  const isVisualsEditingDisabled = useMemo(() => hasUserActiveSubscription && (!purchasedVisualsExist || !selected.size), [hasUserActiveSubscription, purchasedVisualsExist, selected.size])

  // For special clients, they can order specific products which have no visuals selection limit. In this case, clients still can select any number of visuals but we will automatically select all the visuals for them when they confirm the selection.
  const selectedMaxVisualsValue = useMemo(() => selectMaxValue !== PIPEDRIVE_INFINITY ? selectMaxValue : listedVisuals.length, [listedVisuals.length, selectMaxValue])
  const totalExtraPrice = formatPrice(decimalFromBig(totalForAdditionalVisualsAfterDiscount))

  const purchasedExistSelectionStatus = useMemo(() => {
    const isExtraPlus = selectedPurchasedVisualsCount !== 0 ? '+' : ''

    // TODO: will need to be refined when implement download/edit tool bar. 
    const selectedPurchasedValue = selectedPurchasedVisualsCount ? selectedPurchasedVisualsCount : ''
    const selectedExtraValue = (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled) ? t('selected_n_extra', { plus: isExtraPlus, count: selectedAdditionalVisualsCount, totalPrice: currency ? `( ${totalExtraPrice}${currency} )` : null }) : ''
    const selectedPurchasedAndExtraValue = selected.size === 0 ? selected.size : `${selectedPurchasedValue} ${selectedExtraValue}`

    return (
      <Fragment>
        {selectedPurchasedAndExtraValue} <Box fontWeight={400} display='inline'> {t('selected')}</Box>
      </Fragment>
    )
  }, [isArchiveDisabled, selected.size, selectedAdditionalVisualsCount, selectedPurchasedVisualsCount, currency, t, totalExtraPrice])

  const noPurchasedExistSelectionStatus = useMemo(() => {
    const selectedValue = Math.min(selected.size, selectedMaxVisualsValue)
    const isShowExtraValue = selectedAdditionalVisualsCount > 0 && !isArchiveDisabled && pricePerAdditionalVisual
    const extraValue = isShowExtraValue ? t('selected_n_extra', { plus: '+', count: selectedAdditionalVisualsCount, totalPrice: currency ? `( ${totalExtraPrice}${currency} )` : null }) : ''
    const selectedValueWithMaxValue = `${selectedValue}/${selectedMaxVisualsValue}`

    return (
      <Fragment>
        {selectedValueWithMaxValue} <Box fontWeight={400} display='inline'>{t('selected')}</Box> {!isMobileView && extraValue}
        {isMobileView && <Box fontWeight={400} fontSize={12} display='flex'>{extraValue}</Box>}
      </Fragment>
    )
  }, [isMobileView, isArchiveDisabled, pricePerAdditionalVisual, totalExtraPrice, selected.size, selectedAdditionalVisualsCount, selectedMaxVisualsValue, currency, t])

  const additionalVisualsPurchase = useCallback((type: CurrentActionType) => {
    setCurrentActionType(type)
    initiateArchiveDownload()
  }, [initiateArchiveDownload, setCurrentActionType])

  const handleConfirmSelection = useCallback(() => {
    logGalleryEvent(AnalyticsEvent.CLICK_CONFIRM_VISUAL_SELECTION, {
      assignmentOwnerId: dealData?.customer.id,
      assignmentOwnerEmail: dealData?.customer.email,
      productType: product?.type,
      productKind: product?.kind,
      numberOfVisuals: selected.size
    })

    additionalVisualsPurchase(CurrentActionType.DOWNLOAD)
  }, [additionalVisualsPurchase, dealData, logGalleryEvent, product, selected.size])

  const handleDownload = useCallback(() => {
    logGalleryEvent(AnalyticsEvent.CLICK_DOWNLOAD_VISUALS)

    additionalVisualsPurchase(CurrentActionType.DOWNLOAD)
  }, [additionalVisualsPurchase, logGalleryEvent])

  const onCancelToolBar = useCallback(() => {
    if (purchasedVisualsExist) setToolBarType(ToolBarType.DOWNLOAD)
    setIsToolBarOpen(false)
    deselectAll()
  }, [deselectAll, purchasedVisualsExist, setIsToolBarOpen, setToolBarType])

  const handleExportingOptions = useCallback(() => {
    if (!hasUserActiveSubscription) {
      logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_EDIT_VISUALS)

      return externalNavigate(Path.PRICING)
    }

    if (selectedAdditionalVisualsCount > 0) return additionalVisualsPurchase(CurrentActionType.EDITING)

    logGalleryEvent(AnalyticsEvent.GALLERY_FLOATING_BAR_EXPORT_OPTIONS)
    navigate(Path.GALLERY_VISUALS_EDIT.replace(':id', assignmentId.toString()))

  }, [additionalVisualsPurchase, assignmentId, hasUserActiveSubscription, logGalleryEvent, navigate, selectedAdditionalVisualsCount])

  useEffect(() => {
    if (purchasedVisualsExist) return setToolBarType(ToolBarType.DOWNLOAD)
    return setToolBarType(ToolBarType.SELECTION)
  }, [purchasedVisualsExist, setToolBarType])

  const outputActions = useCallback(() => {
    switch (toolBarType) {
      case ToolBarType.SELECTION:
        return (
          <MUITooltip
            placement='top'
            arrow={false}
            content={selected.size < selectMaxValue &&
              <Trans
                t={t}
                i18nKey="confirm_selection_disabled_note"
                values={{ maxValue: selectedMaxVisualsValue }}
              />
            }
          >
            <MUIButton
              fullWidth
              type='darkPrimary'
              disabled={selected.size < selectMaxValue}
              onClick={handleConfirmSelection}
            >
              {t('confirm_selection')}
            </MUIButton>
          </MUITooltip>
        )
      case ToolBarType.DOWNLOAD:
        return (
          <Fragment>
            {!isVideo &&
              <MUIButton
                type="darkSecondaryNoborder"
                disabled={isVisualsEditingDisabled}
                onClick={handleExportingOptions}
              >
                {t('exporting_options')}
              </MUIButton>
            }
            <MUIButton
              type='darkPrimary'
              onClick={handleDownload}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
            >
              {t('direct_download')}
            </MUIButton>
          </Fragment>
        )
      default:
        break
    }
  }, [handleConfirmSelection, handleDownload, handleExportingOptions, isVideo, isVisualsEditingDisabled, selectMaxValue, selected.size, selectedMaxVisualsValue, t, toolBarType])

  const isShowSelectAll = useMemo(() => {
    if (purchasedVisualsExist) return selectedPurchasedVisualsCount !== purchasedVisualsKeys.size
    return selected.size !== selectableVisualsCount
  }, [purchasedVisualsExist, purchasedVisualsKeys.size, selectableVisualsCount, selected.size, selectedPurchasedVisualsCount])

  return (
    <GalleryFloatingToolBar
      isWide={!purchasedVisualsExist && !!selectedAdditionalVisualsCount && !!currency}
      isToolBarOpen={isToolBarOpen}
      toolBarLabel={purchasedVisualsExist ? purchasedExistSelectionStatus : noPurchasedExistSelectionStatus}
      openToolBarButtonLabel={t('select_visuals')}
      onOpenToolBar={() => setIsToolBarOpen(true)}
      contentActionContainer={
        <Fragment>
          <MUIButton
            type='darkSecondaryNoborder'
            onClick={onCancelToolBar}
          >
            {t('cancel')}
          </MUIButton>
          {isShowSelectAll ?
            <MUIButton
              type='darkSecondaryNoborder'
              onClick={!purchasedVisualsExist ? selectAll : selectAllPurchased}
            >
              {t('select_all')}
            </MUIButton>
            :
            <MUIButton
              type='darkSecondaryNoborder'
              onClick={deselectAll}
            >
              {t('unselect_all')}
            </MUIButton>
          }
        </Fragment>
      }
      outputActionContainer={
        outputActions()
      }
    />
  )
}
