import { BOX_SHADOW_MD, WHITE } from 'constants/styling/theme'
import { GoogleMap as DefaultGoogleMap, Marker } from '@react-google-maps/api'
import Geocode, { ReactGeocodePlace, ReactGeocodePoint } from 'react-geocode'
import { ReactNode, useCallback, useEffect } from 'react'

import { GOOGLE_API_KEY } from 'constants/API'
import { lookupPlaceFromAddress } from 'utils/helpers/lookUpPlaceFromAddress'
import { useAddressMap } from './AddressMap.context'

Geocode.setApiKey(GOOGLE_API_KEY)
// Geocode.enableDebug()

export interface Props {
  /** Center point of the map and initial marker position if not specified */
  center?: ReactGeocodePoint
  /** Map container height */
  height: number | string
  /** The initial address input string */
  initialAddress?: string
  /** Initial marker position */
  initialMarker?: ReactGeocodePoint
  /** Initial zoom status */
  initialZoom?: number
  /** How long it takes for new props to populate the state */
  timeoutAfterPropsChange?: number
  /** Action called when address changes */
  handleChange: (place: ReactGeocodePlace | null, isInitialLookup: boolean) => void
  /** Whether the map should try to obtain current location of the user */
  geolocation?: boolean
  /** Whether the map pin is draggable */
  draggable?: boolean
  /** Language used to initialize the google script */
  language?: string
  /** Country code used to specify where to search for addresses */
  countryCode?: string
  /** Optional slot for rendering circles/rectangles or other markers */
  mapObject?: ReactNode
}

/**
 * @component
 * AdressMap component responsible for handling the address selection step in the purchase flow.
 *
 * @example
 * <AdressMap />
 */
export const AdressMap: React.FC<Props> = ({
  center,
  height,
  initialAddress,
  initialMarker,
  initialZoom,
  handleChange,
  geolocation,
  language,
  countryCode,
  mapObject,
  draggable = true,
  timeoutAfterPropsChange
}) => {

  const {
    zoom,
    mapCenter,
    markerPosition,
    setZoom,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    updateStateFromPlace,
  } = useAddressMap()

  const handleSetStateFromPlace = useCallback((place: ReactGeocodePlace | null, isInitialLookup: boolean = false) => {
    updateStateFromPlace(place)
    handleChange(place, isInitialLookup)
  }, [handleChange, updateStateFromPlace])

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param e
   */
  const onMarkerDragEnd = useCallback(async (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng?.lat() ?? 0
    const lng = e.latLng?.lng() ?? 0

    if (!e.latLng) console.error('latLng is null')

    setMarkerPosition({ lat, lng })
    setMapCenter({ lat, lng })

    try {
      const response = await Geocode.fromLatLng(lat, lng)
      let place_object: ReactGeocodePlace | null = null
      if (response.results.length > 0) {
        place_object = response.results[0]
      }
      handleSetStateFromPlace(place_object)
    } catch (error) {
      console.error(error)
      handleSetStateFromPlace(null)
    }

  }, [handleSetStateFromPlace, setMapCenter, setMarkerPosition])

  /** Geocode API lokup place from lat, lon geoposition */
  const lookupPlaceLatLng = useCallback(async (lat: number, lng: number) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng)
      if (response.results.length === 0) return null
      return response.results[0]
    } catch (error) {
      console.error(error)
      return null
    }
  }, [])

  /** Handles the success of the geolocation request and updates the component's state
   *  based on the latitude and longitude information.
   */
  const handleGeolocationSuccess = useCallback(async (GeolocationPosition: GeolocationPosition) => {
    const { latitude, longitude } = GeolocationPosition.coords

    try {
      const response = await lookupPlaceLatLng(latitude, longitude)
      handleSetStateFromPlace(response, true)
    } catch (error) {
      console.error(error)
      handleSetStateFromPlace(null, true)
    }

  }, [handleSetStateFromPlace, lookupPlaceLatLng])

  /** Lookup current position via browser geolocation */
  const lookupFromCurrentPosition = useCallback(async () => {
    if (!window.navigator.geolocation) return

    window.navigator.geolocation.getCurrentPosition((GeolocationPosition) => {
      (async () => {
        await handleGeolocationSuccess(GeolocationPosition)
      })()
    })

  }, [handleGeolocationSuccess])

  /** Set initial place either from the the initial address string or geolocation lookup */
  const setInitialPlace = useCallback(() => {

    if (initialAddress) {
      const tryLookupAddress = async () => {
        try {
          const place = await lookupPlaceFromAddress(initialAddress ?? '')
          handleSetStateFromPlace(place, true)
        } catch (error) {
          console.error(error)
          handleSetStateFromPlace(null, true)
        }
      }
      tryLookupAddress()
    }

    if (geolocation) {
      lookupFromCurrentPosition()
    }

  }, [geolocation, handleSetStateFromPlace, initialAddress, lookupFromCurrentPosition])

  useEffect(() => {
    if (language) Geocode.setLanguage(language)
    if (countryCode) Geocode.setRegion(countryCode)

    setInitialPlace()

    // Set the initial language and country code if it is provided
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (center) {
      setMapCenter(center)
    }

    if (initialMarker) {
      setMarkerPosition(initialMarker)
    }

    // Set the initial marker position and center if it is provided
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Compare props and call API for initial address or coordinate search if necessary */
  useEffect(() => {

    setTimeout(() => {
      if (initialAddress) setAddress(initialAddress)
      if (initialMarker) setMarkerPosition(initialMarker)
      if (initialMarker) setMapCenter(initialMarker)
      if (initialZoom) setZoom(initialZoom)
      setInitialPlace()
    }, timeoutAfterPropsChange ?? 0)

    if (language) Geocode.setLanguage(language)
    if (countryCode) Geocode.setRegion(countryCode)

  }, [countryCode, initialAddress, initialMarker, initialZoom, language, setAddress, setInitialPlace, setMapCenter, setMarkerPosition, setZoom, timeoutAfterPropsChange])

  return (
    <DefaultGoogleMap
      mapContainerStyle={{
        height,
        borderRadius: '8px',
        boxSizing: 'border-box',
        boxShadow: BOX_SHADOW_MD,
        border: `2px solid ${WHITE}`,
      }}
      zoom={zoom}
      center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
    >
      {markerPosition &&
        <Marker
          draggable={draggable}
          onDragEnd={onMarkerDragEnd}
          position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        />
      }
      {mapObject}
    </DefaultGoogleMap>
  )
}
