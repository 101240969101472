import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Box, Typography } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { EnergeticMeasure, RenewableEnergySource, RenewableEnergySourceUsage } from 'constants/purchaseFlow'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUINumberField } from 'components/common/MUINumberField'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import { useConsumptionInformation } from './ConsumptionInformation.context'
import { useTranslation } from 'react-i18next'

/**
 * Content for the ConsumptionInformation in the ConsumptionCertificateStepController.
 * 
 * @example
 * <ConsumptionInformation />
 */
export const ConsumptionInformation: FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'consumption_certificate_step.consumption_information_page' })

  const {
    area,
    setArea,
    systemType,
    setSystemType,
    useType,
    setUseType,
    energeticConditionType,
    setEnergeticConditionType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasPropertyVacant,
    setHasPropertyVacant
  } = useConsumptionInformation()

  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  const { selectedCategory } = usePurchaseFlowConfig()

  const [showError, setShowError] = useState<boolean>(false)

  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  /** Available energetic states mapped to objects */
  const energeticStateToggleValues: ToggleFilterValue<EnergeticMeasure>[] = useMemo(
    () => {
      const availableEnergeticStates = Object.values(EnergeticMeasure)

      return availableEnergeticStates.map((state) => {
        return {
          value: state,
          displayName: t(`energetic_condition_type.${state}`),
        }
      })
      // [t] is not a dependency because it is a constant function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  /** Available use types mapped to objects */
  const useTypeToggleValues: ToggleFilterValue<RenewableEnergySourceUsage>[] = useMemo(
    () => {
      let availableUseTypes = Object.values(RenewableEnergySourceUsage)

      availableUseTypes = availableUseTypes.filter(type =>
        systemType !== RenewableEnergySource.NONE ? type !== RenewableEnergySourceUsage.NONE : type === RenewableEnergySourceUsage.NONE
      )

      return availableUseTypes.map((type) => {
        return {
          value: type,
          displayName: t(`use_type.${type}`),
        }
      })
      // [t] is not a dependency because it is a constant function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemType]
  )

  useEffect(() => {
    if (systemType === RenewableEnergySource.NONE && useType !== RenewableEnergySourceUsage.NONE) {
      setUseType(RenewableEnergySourceUsage.NONE)
    }
  }, [setUseType, systemType, useType])


  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.OPENS_CONSUMPTION_INFORMATION, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })
    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      {/* Vacancy */}
      <SectionedBorderBox title={t('vacancy')}>
        <Stack gap="1.8rem" width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">

          <Stack gap=".6rem">

            <Label text={t('has_property_been_vacant')} />

            <Stack gap=".8rem" flexDirection="row">

              <ToggleButton
                sx={{ width: '6.4rem', justifyContent: 'center' }}
                isSelected={hasPropertyVacant === true}
                label={t('yes')}
                onClick={() => setHasPropertyVacant(true)}
              />

              <ToggleButton
                sx={{ width: '6.4rem', justifyContent: 'center' }}
                isSelected={hasPropertyVacant === false}
                label={t('no')}
                onClick={() => setHasPropertyVacant(false)}
              />

            </Stack>

          </Stack>

          {hasPropertyVacant &&
            <>
              <Stack gap=".6rem">

                <Label text={t('period_of_vacancy')} />

                <Stack flexDirection="row" gap={1} alignItems="center" flexWrap="wrap">

                  <MUIDatePicker
                    required
                    value={startDate}
                    views={['year', 'month', 'day']}
                    isError={showError && !startDate}
                    onInputBlur={handleOnInputBlur}
                    onChange={(newValue) => setStartDate(newValue)}
                  />

                  <Typography variant='text-md' color={GRAY_900}>
                    {t('common:to')}
                  </Typography>

                  <MUIDatePicker
                    required
                    value={endDate}
                    views={['year', 'month', 'day']}
                    isError={showError && !endDate}
                    onInputBlur={handleOnInputBlur}
                    onChange={(newValue) => setEndDate(newValue)}
                  />

                </Stack>

              </Stack>

              <Box width={{ xs: '80%', lg: '45%' }}>
                <MUINumberField
                  min={0}
                  required
                  suffix='m2'
                  inputBoxWidth='100%'
                  label={t('area')}
                  value={area}
                  isError={showError && !area}
                  onBlur={handleOnInputBlur}
                  onChange={(value) => setArea(Number(value))}
                />
              </Box>
            </>
          }
        </Stack>
      </SectionedBorderBox>

      {/* Renewable energies */}
      <SectionedBorderBox title={t('renewable_energies')}>
        <Stack gap="1.8rem" width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">

          <MUIDropdown
            sx={{ width: '100%' }}
            button={(isOpen, action) => (
              <MUIDropdownInput
                readOnly
                label={t('system')}
                type={DropdownInputTypes.SELECTOR}
                value={t(!!systemType ? `system_type.${systemType}` : 'select_system_type_option')}
                onClick={action}
              />
            )}
          >
            {Object.values(RenewableEnergySource).map(type => (
              <MUIDropdownItem key={type} onClick={() => setSystemType(type)}>
                <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                  {t(`system_type.${type}`)}
                </Typography>
              </MUIDropdownItem>
            ))}
          </MUIDropdown>

          {systemType !== RenewableEnergySource.NONE &&
            <Stack gap=".6rem">

              <Label text={t('use')} />

              <SelectableButtonsGrid
                values={useTypeToggleValues}
                selectedValue={useType}
                onSelect={type => setUseType(type)}
                onUnselect={_ => setUseType(RenewableEnergySourceUsage.NONE)}
                showRadio
                activeButtonClickable
              />

            </Stack>
          }

        </Stack>
      </SectionedBorderBox>

      {/* Energetic current state */}
      <SectionedBorderBox title={t('energetic_state')}>
        <Stack gap="1.8rem" position="relative">
          <Stack gap=".6rem">

            <Label text={t('applicable_points')} />

            <SelectableButtonsGrid
              values={energeticStateToggleValues}
              selectedValue={Array.from(energeticConditionType)}
              onSelect={type => setEnergeticConditionType(new Set([...energeticConditionType, type]))}
              onUnselect={type => setEnergeticConditionType(new Set([...energeticConditionType].filter(item => item !== type)))}
              showCheckbox
              activeButtonClickable
            />

          </Stack>
        </Stack>
      </SectionedBorderBox>
    </Fragment>
  )
}
