import { FC } from 'react'
import { PageLayout } from 'components/common/PageLayout'
import { Stack } from '@mui/material'

export const PropertyController: FC = () => {

  return (
    <PageLayout>
      <Stack width="100%" padding="8rem">
        Property controller
      </Stack>
    </PageLayout >
  )
}
