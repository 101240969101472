import { usePurchaseFlowConfig, usePurchaseFlowRealEstateProperty } from 'components/pages/PurchaseFlow/_main/contexts'

import { AutocompleteInput } from 'components/common/AdressMap/AutocompleteInput/AutocompleteInput.component'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { GRAY_900 } from 'constants/styling/theme'
import { MUIInputField } from 'components/common/MUIInputField'
import { ReactGeocodePlace } from 'react-geocode'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface Props {
  /** Is address valid */
  addressIsValid: boolean
  /** OnClose function to be called */
  onClose: () => void
  /** Action called when address changes */
  handleMapOnChange: (place: ReactGeocodePlace | null, isInitialLookup: boolean) => void
}

/**
 * @component Create property component in Address step
 * @example <CreateProperty />
 */
export const CreateProperty: React.FC<Props> = ({
  addressIsValid,
  onClose,
  handleMapOnChange,
}) => {
  const { t } = useTranslation(['order'], { keyPrefix: 'step_address' })

  const {
    propertyName,
    setPropertyName
  } = usePurchaseFlowRealEstateProperty()

  const {
    selectedAssignmentPlace,
    setSelectedAssignmentPlace,
  } = usePurchaseFlowConfig()

  const [showError, setShowError] = useState<boolean>(false)

  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox
      title={t('new_property')}
      onIconClick={onClose}
      customIcon={<CloseRoundedIcon fontSize='large' sx={{ color: GRAY_900 }} />}
    >
      <Stack gap="1.6rem">

        <MUIInputField
          label={t('property_name')}
          value={propertyName}
          isError={showError && !propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          onBlur={handleOnInputBlur}
        />

        <AutocompleteInput
          inputPosition="below"
          handleChange={(newGooglePlace, isInitialLookup) => handleMapOnChange(newGooglePlace, isInitialLookup)}
          onInputChange={e => setSelectedAssignmentPlace(null)}
          error={selectedAssignmentPlace && !addressIsValid ? t('address_not_selected') : undefined}
        />

      </Stack>
    </SectionedBorderBox>
  )
}
