import { Orientations, Styles } from '../FloorPlanPreview/floorPlan3DConstants'
import { ProductKind, ProductKindsFloorPlan } from 'constants/product'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, usePurchaseFlowRealEstateProperty, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { FeatureFlag } from 'utils/featureFlags'
import { FloorPlanColors } from '../FloorPlanPreview/basicFloorPlanConstants'
import { FloorPlanFormat } from '../FloorPlanLayout'
import { Language } from 'translations/Language'
import { Nullable } from 'models/helpers'
import constate from 'constate'
import { isEditingCategory } from 'utils/validators'
import { useFlag } from '@unleash/proxy-client-react'

export enum FloorPlanRenderType {
  RENDER_3D = 'RENDER_3D',
  RENDER_2D = 'RENDER_2D'
}

export enum FloorPlanProductType {
  PRODUCT_EDITING = 'EDITING',
  PRODUCT_BASIC = 'BASIC'
}

export enum FloorPlanSelectionType {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM'
}

export const [FloorPlanConfigProvider, useFloorPlanConfig] = constate(() => {
  const { selectedCategory, extraCatalogueProducts, toCatalogueOptionProduct } = usePurchaseFlowConfig()
  const { targetUser } = useTargetOrderUser()
  const { selectedProducts, selectOptionProduct, selectedProductOptions, unselectOptionProduct } = usePurchaseFlowProducts()
  const { selectedPropertyType, setSelectedPropertyType } = usePurchaseFlowRealEstateProperty()

  const allowFloorPlanFlow = useFlag(FeatureFlag.ALLOW_FLOOR_PLAN_CONFIG_FLOW)
  const allowDimensions = useFlag(FeatureFlag.ALLOW_EACH_ROOM_DIMENSIONS)

  const [currentFloorPlanRenderType, setCurrentFloorPlanRenderType] = useState<FloorPlanRenderType>(FloorPlanRenderType.RENDER_2D)

  const currentFloorPlanProductType: FloorPlanProductType = useMemo(() => {
    if (selectedCategory && isEditingCategory(selectedCategory)) return FloorPlanProductType.PRODUCT_EDITING
    return FloorPlanProductType.PRODUCT_BASIC
  }, [selectedCategory])

  // DESIGN
  const [selectedColor, setSelectedColor] = useState<FloorPlanColors>(FloorPlanColors.BLACK)
  const [showFixtures, setShowFixtures] = useState<boolean>(false)
  const [showFurniture, setShowFurniture] = useState<boolean>(false)

  const [selectedStyle, setSelectedStyle] = useState<Styles>(Styles.STYLE_1)
  const [selectedOrientation, setSelectedOrientation] = useState<Orientations>(Orientations.BIRD_VIEW)

  // LAYOUT
  const [showRoomNames, setShowRoomNames] = useState<boolean>(false)
  const [showScalebar, setShowScalebar] = useState<boolean>(false)
  const [showCompass, setShowCompass] = useState<boolean>(false)
  const [isActiveDimensions, setIsActiveDimensions] = useState<boolean>(false)
  const [showEachRoomDimensions, setShowEachRoomDimensions] = useState<boolean>(false)
  const [showDimensionChain, setShowDimensionChain] = useState<boolean>(true)
  const [showRoomArea, setShowRoomArea] = useState<boolean>(true)
  const [outputLanguage, setOutputLanguage] = useState<Language>(Language.EN)
  const [floorPlanFormat, setFloorPlanFormat] = useState<Record<string, FloorPlanFormat>>({ [FloorPlanFormat.JPG]: FloorPlanFormat.JPG })
  const [isActiveLogo, setIsActiveLogo] = useState<boolean>(false)
  const [companyLogo, setCompanyLogo] = useState<Nullable<string>>(null)
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)
  const [disclaimerText, setDisclaimerText] = useState<string>('')

  // TEMPLATES
  const [selectedTemplateId, setSelectedTemplateId] = useState<Nullable<string>>(null)
  const [floorPlanTemplateType, setFloorPlanTemplateType] = useState<Nullable<FloorPlanSelectionType>>(null)

  const is3DRenderType = useMemo(() => {
    return currentFloorPlanProductType === FloorPlanProductType.PRODUCT_EDITING && currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D
  }, [currentFloorPlanProductType, currentFloorPlanRenderType])

  // DATA
  const getFloorPlanRequestData = useCallback(() => {
    if (!allowFloorPlanFlow) return undefined

    return {
      // Has to be updated when 3D option is available for the new Floor Plan configuration design
      style: is3DRenderType ? selectedStyle : selectedColor,
      orientation: is3DRenderType ? selectedOrientation : undefined,
      fixtures: showFixtures,
      furniture: showFurniture,
      roomNames: showRoomNames,
      scaleBar: showScalebar,
      compass: showCompass,
      companyLogo,
      outputFileFormat: Object.keys(floorPlanFormat),
      outputFileLanguage: outputLanguage,
      dimensions: {
        // fail safe
        showEachRoomDimensions: !allowDimensions ? false : showEachRoomDimensions,
        showDimensionChain,
        showEachRoomArea: showRoomArea
      },
      disclaimer: showDisclaimer ? disclaimerText : undefined,
    }
  }, [allowDimensions, allowFloorPlanFlow, companyLogo, disclaimerText, floorPlanFormat, is3DRenderType, outputLanguage, selectedColor, selectedOrientation, selectedStyle, showCompass, showDimensionChain, showDisclaimer, showEachRoomDimensions, showFixtures, showFurniture, showRoomArea, showRoomNames, showScalebar])

  const getSelectedOptionsAnalytics = useCallback(() => {
    const options = []

    if (is3DRenderType) options.push(selectedOrientation)
    if (showFixtures) options.push('fixtures')
    if (showFurniture) options.push('furniture')
    if (showRoomNames) options.push('roomNames')
    if (showScalebar) options.push('scalebar')
    if (showCompass) options.push('compass')
    if (showEachRoomDimensions) options.push('eachRoomDimensions')
    if (showDimensionChain) options.push('dimensionChain')
    if (showRoomArea) options.push('roomArea')
    if (isActiveLogo) options.push('logo')
    if (selectedPropertyType) options.push(selectedPropertyType)
    if (showDisclaimer && disclaimerText) options.push('disclaimer')

    options.push(...Object.keys(floorPlanFormat))
    options.push(outputLanguage)

    return options
  }, [is3DRenderType, showFixtures, showFurniture, showRoomNames, showScalebar, showCompass, showEachRoomDimensions, showDimensionChain, showRoomArea, isActiveLogo, selectedPropertyType, showDisclaimer, disclaimerText, floorPlanFormat, outputLanguage, selectedOrientation])

  const resetConfiguration = useCallback(() => {
    setSelectedTemplateId(null)
    setSelectedColor(FloorPlanColors.BLACK)
    setSelectedPropertyType(null)
    setShowFixtures(false)
    setShowFurniture(false)
    setSelectedStyle(Styles.STYLE_1)
    setSelectedOrientation(Orientations.BIRD_VIEW)
    setShowRoomNames(false)
    setShowScalebar(false)
    setShowCompass(false)
    setIsActiveDimensions(false)
    setShowEachRoomDimensions(false)
    setShowDimensionChain(false)
    setShowRoomArea(false)
    setOutputLanguage(Language[(targetUser.language as Language)] ?? Language.EN)
    setFloorPlanFormat({ [FloorPlanFormat.JPG]: FloorPlanFormat.JPG })
    setIsActiveLogo(false)
    setCompanyLogo(null)
    setShowDisclaimer(false)
    setDisclaimerText('')

  }, [setSelectedPropertyType, targetUser.language])

  const allSelectedFloorPlanProducts = useMemo(() => {
    // Is there some FP product in the main product selection
    const mainProducts = Object.values(selectedProducts).filter((product) => ProductKindsFloorPlan.has(product.kind))
    // Or as option of the main product then return main product
    const optionalProductsToMainProduct = Object.keys(selectedProductOptions)
      .map((idAsKey) => parseInt(idAsKey))
      .filter((mainProductId) => Object.values(selectedProductOptions[mainProductId]).filter((product) => ProductKindsFloorPlan.has(product.kind)))
      .map((mainProductId) => selectedProducts[mainProductId])

    // Return only main products even they are not FP
    return [...mainProducts, ...optionalProductsToMainProduct]
  }, [selectedProducts, selectedProductOptions])

  /**
   * Find the extra 3D floor plan product based on the following conditions:
   *
   * 1) Find an extra product that has parent ID matching the selected product
   * 2) If no such product exists, find an extra product with no parent product defined
   */
  const floorPlan3dProduct = useMemo(() => {
    const productId = allSelectedFloorPlanProducts[0]?.id
    if (productId === undefined) return

    const extra3DProducts = extraCatalogueProducts(ProductKind.FLOOR_PLAN_EDITING_3D)
    const extra3DProduct = extra3DProducts
      .find((it) => it.parentProductIds.includes(productId))

    if (!!extra3DProduct) return toCatalogueOptionProduct(extra3DProduct)

    return toCatalogueOptionProduct(extra3DProducts.find(it => it.parentProductIds === null || it.parentProductIds === undefined || it.parentProductIds.length === 0))
    // Omit toCatalogueOptionProduct
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedFloorPlanProducts, extraCatalogueProducts])

  useEffect(() => {
    if (!allSelectedFloorPlanProducts?.length || !floorPlan3dProduct) return

    for (const { id } of allSelectedFloorPlanProducts) {

      const isSelected = !!selectedProductOptions?.[id]?.[floorPlan3dProduct.id]

      if (currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D && !isSelected) {
        selectOptionProduct(id, floorPlan3dProduct, 1)
      }

      if (currentFloorPlanRenderType !== FloorPlanRenderType.RENDER_3D) {
        unselectOptionProduct(id, floorPlan3dProduct.id)
      }
    }
    // Omit everything to prevent loop and only trigger when the render type changes (works, because default is 2D)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloorPlanRenderType])

  return {
    is3DRenderType,
    currentFloorPlanProductType,
    currentFloorPlanRenderType,
    selectedColor,
    selectedStyle,
    selectedOrientation,
    selectedPropertyType,
    showFixtures,
    showFurniture,
    showRoomNames,
    showScalebar,
    showCompass,
    isActiveDimensions,
    isActiveLogo,
    companyLogo,
    showEachRoomDimensions,
    showDimensionChain,
    showRoomArea,
    outputLanguage,
    floorPlanFormat,
    floorPlanTemplateType,
    selectedTemplateId,
    showDisclaimer,
    setSelectedTemplateId,
    setFloorPlanTemplateType,
    setCurrentFloorPlanRenderType,
    setSelectedColor,
    setSelectedStyle,
    setSelectedOrientation,
    setSelectedPropertyType,
    setShowFixtures,
    setShowFurniture,
    setShowRoomNames,
    setShowScalebar,
    setShowCompass,
    setOutputLanguage,
    setFloorPlanFormat,
    setIsActiveDimensions,
    setIsActiveLogo,
    setCompanyLogo,
    setShowEachRoomDimensions,
    setShowDimensionChain,
    setShowRoomArea,
    getFloorPlanRequestData,
    resetConfiguration,
    getSelectedOptionsAnalytics,
    setShowDisclaimer,
    setDisclaimerText,
    disclaimerText,
  }
})
