import { FC, useCallback, useMemo, useState } from 'react'
import { useAddFavoriteVisual, useDeleteFavoriteVisual } from 'dataQueries'
import { useGalleryAssignment, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisuals } from '../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { ClientGalleryFloatingToolBar } from '../ClientGalleryFloatingToolBar'
import { ClientGalleryPhotoSection } from '../ClientGalleryPhotoSection'
import { Direction } from 'components/pages/VisualsEditing'
import { ExpandedVisualPopup } from 'components/pages/VisualsEditing/ExpandedVisualPopup'
import { GalleryUpsellPayment } from 'components/common/Gallery/GalleryUpsellPayment'
import { GalleryVisualDownloadPopup } from 'components/common/Gallery/GalleryVisualDownloadPopup'
import classNames from 'classnames'
import styles from './ClientGalleryPhoto.module.sass'
import { useClientGallery } from '../_main/contexts'
import { useDebouncedMemo } from 'utils/helpers'
import { useImageRegex } from 'utils/hooks'

enum UpdateFavoriteType {
  DELETE = 'DELETE',
  ADD = 'ADD',
}

/**
 * Client gallery photo content.
 * 
 * @example <ClientGalleryPhoto />
 */
export const ClientGalleryPhoto: FC = () => {

  const { webType } = useGalleryVisualType()

  const {
    downloadVisuals,
    downloadVisualsKeys,
    downloadVisualsCount,
    favoritedVisuals,
    visualMetadataMap,
    setFavoritedVisuals
  } = useGalleryVisuals()

  const {
    selected,
    toggleSelectOnImage,
  } = useGalleryVisualSelection()

  const {
    assignmentId,
    setIsToolBarOpen,
  } = useClientGallery()

  const {
    logGalleryEvent,
  } = useGalleryAssignment()

  const imageNameReplacePattern = useImageRegex(assignmentId)

  const addFavoriteVisual = useAddFavoriteVisual()
  const deleteFavoriteVisual = useDeleteFavoriteVisual()

  const [isExpandedVisualOpen, setIsExpandedVisualOpen] = useState<boolean>(false)
  const [expandedVisualName, setExpandedVisualName] = useState<string | undefined>(undefined)

  const onUpdateFavoriteVisuals = useDebouncedMemo(() =>
    (type: UpdateFavoriteType, filename: string) => {
      switch (type) {
        case UpdateFavoriteType.ADD:
          addFavoriteVisual.mutate({ assignmentId, visuals: [filename] }, {
            onError: () => {
              favoritedVisuals.delete(filename)
              setFavoritedVisuals(new Set(favoritedVisuals))
            }
          })
          break
        case UpdateFavoriteType.DELETE:
          deleteFavoriteVisual.mutate({ assignmentId, visuals: [filename] }, {
            onError: () => {
              favoritedVisuals.add(filename)
              setFavoritedVisuals(new Set(favoritedVisuals))
            }
          })
          break

        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [], 500, 700)

  const isFavoriteVisualsDisabled = addFavoriteVisual.isPending || deleteFavoriteVisual.isPending

  const toggleFavoriteOnImage = useCallback((filename?: string) => {
    if (!filename) return
    if (isFavoriteVisualsDisabled) return

    if (favoritedVisuals.has(filename)) {
      favoritedVisuals.delete(filename)
      setFavoritedVisuals(new Set(favoritedVisuals))
      onUpdateFavoriteVisuals(UpdateFavoriteType.DELETE, filename)
      logGalleryEvent(AnalyticsEvent.GALLERY_UNMARK_FAVORITE)
    } else {
      favoritedVisuals.add(filename)
      setFavoritedVisuals(new Set(favoritedVisuals))
      onUpdateFavoriteVisuals(UpdateFavoriteType.ADD, filename)
      logGalleryEvent(AnalyticsEvent.GALLERY_MARK_FAVORITE)
    }
  }, [favoritedVisuals, onUpdateFavoriteVisuals, logGalleryEvent, setFavoritedVisuals, isFavoriteVisualsDisabled])

  const switchExpandedImage = useCallback((direction: Direction) => {
    if (!expandedVisualName) return
    if (!downloadVisuals) return

    const isDirectionNext = direction === Direction.RIGHT

    if (downloadVisualsCount === 0) return
    let chooseThisOne = false

    for (let i = isDirectionNext ? 0 : downloadVisualsCount - 1; isDirectionNext ? i < downloadVisualsCount : i >= 0; isDirectionNext ? i++ : i--) {
      if (chooseThisOne) return setExpandedVisualName(downloadVisualsKeys[i])
      if (expandedVisualName === downloadVisualsKeys[i]) chooseThisOne = true
    }

    return setExpandedVisualName(isDirectionNext ? downloadVisualsKeys[0] : downloadVisualsKeys[downloadVisualsCount - 1])
  }, [expandedVisualName, downloadVisuals, downloadVisualsCount, downloadVisualsKeys])

  const expandedVisual = useMemo(() => {
    if (!expandedVisualName) return undefined
    if (!downloadVisuals?.[expandedVisualName]) return undefined
    return downloadVisuals[expandedVisualName]?.[webType]
  }, [downloadVisuals, expandedVisualName, webType])

  const expandedImageName = useMemo(() => expandedVisual?.file?.name, [expandedVisual?.file?.name])

  return (
    <div className={classNames(styles.clientGalleryPhoto)}>

      <ClientGalleryPhotoSection
        favorited={favoritedVisuals}
        setIsExpandedVisualOpen={setIsExpandedVisualOpen}
        setExpandedVisualName={setExpandedVisualName}
        toggleFavoriteOnImage={toggleFavoriteOnImage}
        isFavoriteDisabled={isFavoriteVisualsDisabled}
        visualsMetadata={visualMetadataMap}
      />

      <ClientGalleryFloatingToolBar />

      <GalleryVisualDownloadPopup />

      <GalleryUpsellPayment />

      <ExpandedVisualPopup
        isOpen={isExpandedVisualOpen}
        isSelectable
        isFavoriteSelectable
        isSelected={selected.has(expandedImageName ?? '')}
        isFavorite={favoritedVisuals.has(expandedImageName ?? '')}
        visualMetadata={visualMetadataMap[expandedImageName ?? ''] ?? null}
        signedUrl={expandedVisual?.signedUrl}
        filename={expandedImageName}
        label={expandedImageName?.replace(imageNameReplacePattern, '')}
        switchExpandedVisual={switchExpandedImage}
        toggleFavoriteOnImage={toggleFavoriteOnImage}
        onClose={() => setIsExpandedVisualOpen(false)}
        onSelect={() => {
          setIsToolBarOpen(true)
          toggleSelectOnImage(expandedImageName)
        }}
      />

    </div>
  )
}
