import { APIRequestErrorType, APIRequestState } from 'constants/API'
import { Color, IconType } from 'constants/assets'
import { EntityKeys, QueryType, queryClient } from 'utils/reactQuery'
import { FC, Fragment, ReactNode, useCallback, useMemo } from 'react'
import { ProductKind, ProductType } from 'constants/product'
import { useUpsellPayment, useUpsellPurchaseConfiguration } from './contexts'

import { APIRequest } from 'models/API'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { CheckCard } from 'components/common/ExpandableCard'
import { FeatureFlag } from 'utils/featureFlags/featureFlags'
import Icon from 'components/common/Icon/Icon'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Modal from 'components/common/Modals/Modal/Modal'
import { PreferredPaymentMethodSwitch } from 'components/common/PreferredPaymentMethodSwitch'
import ReactLoading from 'react-loading'
import { RequestStatus } from 'components/common/RequestStatus'
import { StripePaymentWrapper } from '../StripePaymentWrapper'
import { Tag } from 'components/common/Tags'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { formatPrice } from 'utils/price'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import { purgeUpdatePreferredPaymentMethod } from 'redux/Individual/User/UpdatePreferredPaymentMethod'
import styles from './UpsellPayment.module.sass'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { userMe } from 'redux/Individual/User/UserMe'

export const UpsellPaymentController: FC<{
  children?: ReactNode
}> = ({
  children
}) => {

    const { t, i18n } = useTranslation(['upsell_purchase_modal', 'product', 'product_kind', 'product_kind_disclaimer'])
    const dispatch = useDispatch()
    const { showConfirm } = useActionPopup()
    const { roles } = useAuth0()

    const {
      isOpen,
      productId,
      productType,
      productQuantity,
      total,
      successTitle,
      beforeConfirmActionState,
      beforeConfirmationActionRequest,
      successNode,
      afterClosed,
      onClose,
      beforeConfirmAction,
    } = useUpsellPurchaseConfiguration()

    const {
      paymentIntent,
      isStripeUsed,
      selectedPaymentProduct,
      paymentProducts,
      isForeignPurchase,
      isPaymentElementValid,
      isStripeProcessing,
      stripeError,
      isPurchaseReady,
      updatePreferredPaymentMethodState,
      isReceiptEmailValid,
      selectedSavedPaymentMethod,
      isPaymentLoading,
      setSelectedPaymentProduct,
      setIsStripeConfirmed,
      setStripeError,
    } = useUpsellPayment()

    const allowRepeatedUpsellPayment = useFlag(FeatureFlag.ALLOW_REPEATED_UPSELL_PAYMENT)

    const { userCanPayByInvoice } = useUserData()

    const isSubmitProcessing = useMemo(() => beforeConfirmActionState === APIRequestState.RUNNING || (isStripeUsed && isStripeProcessing), [beforeConfirmActionState, isStripeProcessing, isStripeUsed])
    const isLoading = useMemo(() => !isPurchaseReady || isSubmitProcessing, [isPurchaseReady, isSubmitProcessing])
    const isPurchaseFinished = useMemo(() => !isStripeUsed && beforeConfirmActionState === APIRequestState.OK, [beforeConfirmActionState, isStripeUsed])

    const isSubmitDisabled = useMemo(() => {
      // Either submit is already processing or no payment product has been selected
      if (isSubmitProcessing) return true
      if (!selectedPaymentProduct) return true

      // Case of stripe not being used
      if (!isStripeUsed) return false

      // Stripe is being used
      if (selectedSavedPaymentMethod === undefined) return true
      if (!isReceiptEmailValid) return true
      if (selectedSavedPaymentMethod === null && !isPaymentElementValid) return true

      return false
    }, [isSubmitProcessing, selectedPaymentProduct, isStripeUsed, selectedSavedPaymentMethod, isReceiptEmailValid, isPaymentElementValid])

    const isPreferredPaymentMethodVisible = useMemo(() => {
      return roles.isClient && paymentProducts.length > 1
    }, [paymentProducts.length, roles.isClient])

    const foreignPurchasePrompt = useMemo(() => {
      switch (productType) {
        case ProductType.VIRTUAL_VISIT:
          return 'foreign_purchase_prompt_matterport'
        default:
          return 'foreign_purchase_prompt'
      }
    }, [productType])

    // Request error for stripe error or beforeConfirmAction error
    const isRequestError = useMemo(() => beforeConfirmActionState === APIRequestState.ERROR || !!stripeError, [beforeConfirmActionState, stripeError])

    // Hardcoded request error for stripe error
    const stripeErrorRequest: APIRequest<unknown> | undefined = useMemo(() => {
      if (!stripeError) return undefined

      return new APIRequest(
        APIRequestState.ERROR,
        APIRequestErrorType.NONE,
        {},
        {
          data: {
            message: stripeError.message,
            status: stripeError.code,
            timestamp: new Date().toISOString()
          }
        }
      )
    }, [stripeError])

    // Either stripe error or beforeConfirmationAction error request
    const submitErrorRequest = useMemo(() => {
      if (!!stripeError) return stripeErrorRequest

      return beforeConfirmationActionRequest
    }, [beforeConfirmationActionRequest, stripeError, stripeErrorRequest])

    const handleClose = useCallback(async () => {
      if (!onClose) return

      // Ask close confirmation when user tries to exit modal after ordering without finishing purchase
      // Typically occurs when 3d secure fails
      if (
        allowRepeatedUpsellPayment
        && beforeConfirmActionState === APIRequestState.OK
        && stripeError
        && !(await showConfirm(
          t('close_warning_text'),
          {
            title: t('close_warning_title'),
            confirmDenyText: t('close_warning_deny'),
            confirmAcceptText: t('close_warning_confirm')
          }))
      ) return

      onClose()
    }, [allowRepeatedUpsellPayment, beforeConfirmActionState, onClose, showConfirm, stripeError, t])

    /** Carries out set beforeConfirmAction */
    const handleSubmit = useCallback(async () => {
      if (!productId || !productQuantity || !beforeConfirmAction) throw new Error('Purchase metadata are not set')
      if (isStripeUsed && !paymentIntent) throw new Error('Payment intent has not been fetched')
      if (!selectedPaymentProduct?.id) throw new Error('Billing product id not set')

      if (
        roles.isClient
        && isForeignPurchase
        && !(await showConfirm(t(foreignPurchasePrompt),
          {
            title: t('foreign_purchase_reminder'),
            confirmDenyText: t('foreign_purchase_deny'),
            confirmAcceptText: t('foreign_purchase_confirm')
          }))
      ) return

      if (beforeConfirmActionState !== APIRequestState.OK) {
        beforeConfirmAction(paymentIntent?.id, selectedPaymentProduct?.id, productId, productQuantity)
      }
      setIsStripeConfirmed(false)
    }, [roles, isForeignPurchase, showConfirm, t, beforeConfirmAction, beforeConfirmActionState, isStripeUsed, paymentIntent, productId, productQuantity, selectedPaymentProduct?.id, setIsStripeConfirmed, foreignPurchasePrompt])

    /** Purges stored billing configuration, resets payment method and carries out afterClosed action if set */
    const resetPurchase = useCallback(() => {
      dispatch(purgeUpdatePreferredPaymentMethod())
      queryClient.resetQueries({ queryKey: [EntityKeys.UPSELL_CONFIG, QueryType.GET] })
      setSelectedPaymentProduct(undefined)
      setIsStripeConfirmed(false)
      setStripeError(null)
      if (updatePreferredPaymentMethodState === APIRequestState.OK) dispatch(userMe())

      if (!!afterClosed) afterClosed(stripeError, beforeConfirmActionState)
    }, [afterClosed, beforeConfirmActionState, dispatch, setIsStripeConfirmed, setSelectedPaymentProduct, setStripeError, stripeError, updatePreferredPaymentMethodState])

    // Data safeguard
    if (!onClose || !total) return <Fragment></Fragment>

    return (
      <Modal
        className={styles.upsellModal}
        modalContentClassName={`${styles.upsellPaymentModalContent} ${isLoading ? styles.isMinified : ''}`}
        isOpen={isOpen}
        onClickOutside={handleClose}
        afterClosed={resetPurchase}
      >

        {!isPurchaseFinished &&
          <div className={`${styles.contentWrap} ${isLoading ? styles.hidden : ''}`}>

            <div className={styles.header}>

              <h3 className={styles.product}>
                {productQuantity}x {t(`product:p_${productId}`)}
              </h3>

              <h1 className={styles.total}>
                {formatPrice(total)}
              </h1>

              {!!children &&
                <div className={styles.info}>
                  {children}
                </div>
              }

            </div>

            {(allowRepeatedUpsellPayment || !stripeError) &&
              <Fragment>

                <div className={styles.payment}>

                  <h3 className={styles.title}>
                    {t('choose_method')}
                  </h3>

                  <div className={styles.paymentProducts}>
                    {paymentProducts.map((product) =>
                      <CheckCard
                        key={product.kind}
                        className={styles.product}
                        checkbox="circle"
                        isRadio={true}
                        isHeaderClickable={true}
                        checked={product === selectedPaymentProduct}
                        highlighted={product === selectedPaymentProduct}
                        disabled={(product !== selectedPaymentProduct && beforeConfirmActionState === APIRequestState.OK) || (product.kind === ProductKind.INVOICE_BY_EMAIL && !userCanPayByInvoice)}
                        onCheck={() => setSelectedPaymentProduct(product)
                        }
                      >
                        <div className={styles.container}>

                          <div className={styles.left}>

                            <span className={styles.name}>
                              {t(`product_kind:${product.kind}`)}
                            </span>

                            {i18n.exists(`product_kind_disclaimer:${product.kind}`) &&
                              <span className={styles.disclaimer}>
                                {t(`product_kind_disclaimer:${product.kind}`)}
                              </span>
                            }

                          </div>

                          {isPreferredPaymentMethodVisible && productKindIsPreferredPaymentMethod(product.kind) && (product.kind !== ProductKind.INVOICE_BY_EMAIL || userCanPayByInvoice) &&
                            <div className={styles.right}>
                              <PreferredPaymentMethodSwitch
                                paymentMethod={product.kind}
                                directlyUpdateUserMe={false}
                              />
                            </div>
                          }

                          {product.kind === ProductKind.INVOICE_BY_EMAIL && !userCanPayByInvoice &&
                            <Tag color="red" materialIcon={<LockOutlinedIcon />}>
                              <span>{t('preferred_payment_method:badge_only_subscribed')}</span>
                            </Tag>
                          }

                        </div>
                      </CheckCard>
                    )}
                  </div>

                  {!!isStripeUsed &&
                    <Fragment>

                      {/* Payment element */}
                      {!!paymentIntent &&
                        <div className={styles.paymentWrapper}>
                          <StripePaymentWrapper />
                        </div>
                      }

                      {/* Loading for payment element */}
                      {(!paymentIntent || isPaymentLoading) &&
                        <ReactLoading color={Color.GRAY_TEXT} className={styles.loading} type="cylon" />
                      }

                    </Fragment>
                  }

                </div>

              </Fragment>
            }

            {/* Before confirm success if payment fails */}
            <TransitionAppear visible={beforeConfirmActionState === APIRequestState.OK && !!stripeError}>
              {beforeConfirmActionState === APIRequestState.OK && !!stripeError &&
                <BlockInfo className={styles.successWithError} color="green">
                  <h4>{successTitle || t('success')}</h4>
                </BlockInfo>
              }
            </TransitionAppear>

            {/* Stripe error */}
            <TransitionAppear visible={!!stripeError}>
              {!!stripeError &&
                <div className={styles.errorBox}>
                  <BlockInfo color="red">
                    <h4>{t('payment_error')}</h4>
                    <p>{t(allowRepeatedUpsellPayment ? 'stripe_error_text_retry' : 'stripe_error_text_no_retry')}</p>
                  </BlockInfo>
                </div>
              }
            </TransitionAppear>

            {/* Request error from beforeConfirmationAction or stripe */}
            <TransitionAppear visible={isRequestError}>
              {isRequestError &&
                <RequestStatus
                  spaceBottomRem={2}
                  request={submitErrorRequest}
                  errorMessage={submitErrorRequest?.error?.message}
                  showStates={[APIRequestState.ERROR]}
                />
              }
            </TransitionAppear>

            <div className={styles.actions}>

              <Button type="secondary" onClick={handleClose}>
                {t('cancel')}
              </Button>

              {(allowRepeatedUpsellPayment || !stripeError) &&
                <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
                  {t('submit', { fee: formatPrice(total) })}
                </Button>
              }

            </div>

          </div>
        }

        {/* Success screen for orders paid by invoice. Uses either provided JSX Element or default with optional custom title */}
        {isPurchaseFinished &&
          <Fragment>
            {!!successNode ?
              successNode
              :
              <div className={styles.successBox}>
                <Icon className={styles.icon} color={Color.SECONDARY_GREEN_DARK} icon={IconType.CHECK} />
                <h2>{successTitle || t('success')}</h2>
                <Button type="secondary" onClick={onClose}>
                  {t('close')}
                </Button>
              </div>
            }
          </Fragment>
        }

        {/* Component-wide global loading with state descriptors */}
        {isLoading &&
          <div className={styles.loadingBox}>
            <ReactLoading color={Color.GRAY_TEXT} className={styles.loading} type="cylon" />

            {!isSubmitProcessing && <p className={styles.loadingMessage}>{t('loading_states.setup')}</p>}
            {beforeConfirmActionState === APIRequestState.RUNNING && <p className={styles.loadingMessage}>{t('loading_states.action')}</p>}
            {isStripeProcessing && <p className={styles.loadingMessage}>{t('loading_states.stripe')}</p>}
          </div>
        }
      </Modal>
    )
  }
