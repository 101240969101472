import { AnalyticsEvent, logAnalyticsEvent, trackIntercomEvent } from 'utils/analytics'
import { AssignmentDTO, CreativesUnavailableListDTO } from 'models/assignment'
import { EmojiValue, OrderAssignmentStatusFilter, RatingType, SortDirection } from 'constants/misc'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'
import { ISODateString, PageableResponse } from 'models/misc'
import { ProductDTO, ProductKindDTO } from 'models/product'
import { QueryClient, useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { SearchedCreativeDTO, SuggestedCreativeDTO } from 'models/creative'

import { AssignmentChangelogDTO } from 'constants/assignment/assignmentChangelog'
import { AxiosResponse } from 'axios'
import { DealDTO } from 'models/deal'
import { DeclineAssignmentReason } from 'constants/assignment'
import { LateShootingScheduleReason } from 'components/common/DealAssignmentCard/ScheduleMeetingPopup'
import { Nullable } from 'models/helpers'
import { StatusResponse } from 'models/redux'
import { useAPI } from 'utils/API'
import { useMemo } from 'react'

export enum AssignmentQueryKeys {
  UNAVAILABLE_CTS = 'UNAVAILABLE_CTS',
  SUGGEST_CREATIVES = 'SUGGEST_CREATIVES',
  CALCULATE_REMUNERATION = 'CALCULATE_REMUNERATION',
  GET_ASSIGNMENT_CHANGELOG = 'GET_ASSIGNMENT_CHANGELOG',
  SEARCH_CREATIVE = 'SEARCH_CREATIVE'
}

enum Endpoints {
  AUTOMATE_ASSIGNMENT = '/assignment/{assignmentId}/automate',
  CREATIVE_ACCEPT_ASSIGNMENT = '/assignment/{assignmentId}/accept',
  CREATIVE_ARRANGE_DATETIME_ASSIGNMENT = '/assignment/{assignmentId}/arrangeDateTime',
  CREATIVE_CALCULATE_REMUNERATION = '/assignment/{assignmentId}/creative/{creativeId}/calculateRemuneration',
  CREATIVE_DECLINE_ASSIGNMENT = '/assignment/{assignmentId}/decline',
  EDIT_DELETE_ASSIGNMENT_DATETIME = '/assignment/{assignmentId}/dateTime',
  GET = '/assignment/{assignmentId}',
  GET_ASSIGNMENT_CHANGELOG = '/assignment/{assignmentId}/events',
  GET_SEARCH_CREATIVE = '/assignment/{assignmentId}/searchCreatives',
  GET_SUGGEST_CREATIVES = '/assignment/{assignmentId}/suggestCreatives',
  LIST = '/assignment/all',
  LIST_UNAVAILABLE_CTS = '/assignment/{assignmentId}/creativesUnavailability',
  STOP_AUTOMATED_ASSIGNMENT = '/assignment/{assignmentId}/stopAutomation',
  SUBMIT_ASSIGNMENT = '/assignment/{assignmentId}/visual/submit',
  SUBMIT_DOCUMENT_ASSIGNMENT = '/assignment/{assignmentId}/documents/submit',
  SUBMIT_RATING = '/assignment/{assignmentId}/rating',
  UPDATE_CT_REMUNERATION = '/assignment/{assignmentId}/creative/{creativeId}/updateRemuneration',
  UPSELL_PRODUCTS = '/assignment/{assignmentId}/upsellProducts',
  SYNC = '/assignment/{assignmentId}/sync',
}

const _invalidateOrder = (client: QueryClient, orderId?: number) => {
  client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId }] })
}

const _invalidateAssignment = (client: QueryClient, assignmentId?: string) => {
  client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }] })
  client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.LIST] })
  client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.INFINITE_LIST] })
}

// QUERIES
export function useGetAssignment(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }],
    queryFn: () => api.get<AssignmentDTO>(
      Endpoints.GET,
      { assignmentId: assignmentId.toString() },
      true
    )
  })
}

export function useListUnavailableCTs(assignmentId: Nullable<AssignmentDTO['id']>, enabled: boolean) {
  const api = useAPI<Endpoints>()

  return useQuery<CreativesUnavailableListDTO[], { assignmentId: string }>({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.LIST, AssignmentQueryKeys.UNAVAILABLE_CTS, { assignmentId }],
    queryFn: () => api.get(
      Endpoints.LIST_UNAVAILABLE_CTS,
      { assignmentId: assignmentId?.toString() || '' },
      false
    ),
    enabled
  })
}

export function useGetUpsellProducts(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }],
    queryFn: () => api.get<ProductKindDTO>(
      Endpoints.UPSELL_PRODUCTS,
      { assignmentId },
      true
    ),
    enabled: !!assignmentId
  })
}

export function useGetSuggestCreatives(assignmentId: string, addToSuggestionHistory = true) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.SUGGEST_CREATIVES, { assignmentId }],
    queryFn: () => api.get<SuggestedCreativeDTO[]>(
      Endpoints.GET_SUGGEST_CREATIVES,
      { assignmentId },
      true,
      {
        params: {
          addToSuggestionHistory
        },
      }
    ),
    enabled: false
  })
}

export const useCalculateCreativeRenumeration = (assignmentId: string, creativeId: string) => {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.CALCULATE_REMUNERATION, { assignmentId, creativeId }],
    queryFn: () => api.get<SuggestedCreativeDTO>(
      Endpoints.CREATIVE_CALCULATE_REMUNERATION,
      {
        assignmentId,
        creativeId
      },
      true
    ),
    enabled: !!creativeId
  })
}

export function useGetCreativeSearch(assignmentId: string, name: string, page: number = 0, size: number = 10) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.SEARCH_CREATIVE, { assignmentId }],
    queryFn: () => api.get<PageableResponse<SearchedCreativeDTO[]>>(
      Endpoints.GET_SEARCH_CREATIVE,
      { assignmentId },
      true,
      {
        params: {
          name,
          page: page.toString(),
          size: size.toString(),
        },
      }
    ),
    enabled: false,
  })
}

export function useGetAssignmentChangelog(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.GET_ASSIGNMENT_CHANGELOG, { assignmentId }],
    queryFn: () => api.get<AssignmentChangelogDTO>(
      Endpoints.GET_ASSIGNMENT_CHANGELOG,
      { assignmentId },
      true
    ),
    enabled: !!assignmentId
  })
}

// FILTER QUERIES

export interface AssignmentListFilters {
  status: OrderAssignmentStatusFilter
  userIds: string[]
}

export interface AssignmentListPayload extends AssignmentListFilters {
  page: number
  size: number
  key: string
  sort: SortDirection
  search: Nullable<string>
}

const defaultParams: AssignmentListPayload = {
  page: 0,
  size: 10,
  key: 'KEY',
  sort: SortDirection.DESCENDING,
  status: OrderAssignmentStatusFilter.ALL,
  userIds: [],
  search: null,
}

const _serializeParams = (params: AssignmentListFilters & Partial<AssignmentListPayload>) => {

  const finalParams = {
    ...defaultParams,
    ...params,
  }

  return {
    page: (finalParams.page || 0).toString(),
    size: finalParams.size.toString(),
    sort: finalParams.sort.toString(),
    filter: finalParams.status.toString(),
    query: finalParams.search || undefined,
  }
}

export const useListAssignments = (listKey: string, params: AssignmentListFilters & Partial<AssignmentListPayload>) => {
  const api = useAPI<Endpoints>()

  const serializedParams = useMemo(() => _serializeParams(params), [params])

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.LIST, listKey, { ...serializedParams }],
    queryFn: () => api.get<PageableResponse<DealDTO[]>>(
      Endpoints.LIST,
      {},
      true,
      {
        params: serializedParams,
      }
    )
  })
}

export const useInfiniteListAssignments = (listKey: string, params: AssignmentListFilters) => {
  const api = useAPI<Endpoints>()

  const serializedParams = useMemo(() => _serializeParams(params), [params])

  const fetchFnc = (page: number) => api.get<PageableResponse<DealDTO[]>>(
    Endpoints.LIST,
    {},
    true,
    {
      params: {
        ...serializedParams,
        page,
      }
    }
  )

  return useInfiniteQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.INFINITE_LIST, listKey, { ...serializedParams }],
    queryFn: ({ pageParam }) => fetchFnc(pageParam),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => !lastPage.data.last ? lastPage.data.pageable.pageNumber + 1 : undefined,
  })
}

// MUTATIONS

interface UpdateCTRemunerationPayload {
  baseRemuneration: number
  transportRemuneration: number
  bonusRemuneration: number
  creativeId: string
  assignmentId: string
}

export function useUpdateCTRemuneration() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, UpdateCTRemunerationPayload>(
    ({ assignmentId, creativeId, baseRemuneration, bonusRemuneration, transportRemuneration }) => api.post(
      Endpoints.UPDATE_CT_REMUNERATION,
      {
        assignmentId: assignmentId.toString(),
        creativeId,
      },
      {
        baseRemuneration,
        transportRemuneration,
        bonusRemuneration,
      },
      false
    ),
    (client, { assignmentId }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }] })
    }
  )
}

export function useAcceptAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, { assignmentId: string }>(
    ({ assignmentId }) => api.post(
      Endpoints.CREATIVE_ACCEPT_ASSIGNMENT,
      { assignmentId: assignmentId.toString() },
      {},
      true
    ),
    (client, { assignmentId }) => {
      logAnalyticsEvent(AnalyticsEvent.CREATIVE_ACCEPTS_ASSIGNMENT, { assignmentId })
      logAnalyticsEvent(AnalyticsEvent.GUIDELINES_VALIDATED, { assignmentId })

      _invalidateAssignment(client, assignmentId)
    }
  )
}

interface DeclinePayload {
  assignmentId: string
  reason: DeclineAssignmentReason
  reasonFreetext: Nullable<string>
  unavailabilityInterval: Nullable<{
    from: ISODateString
    to: ISODateString
  }>
}

export function useDeclineAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, DeclinePayload>(
    ({ assignmentId, ...body }) => api.put(
      Endpoints.CREATIVE_DECLINE_ASSIGNMENT,
      { assignmentId: assignmentId.toString() },
      body,
      true
    ),
    (client, { assignmentId }) => {
      logAnalyticsEvent('creative_declines_assignment', { assignmentId })

      _invalidateAssignment(client, assignmentId)
    }
  )
}

interface SchedulePayload {
  assignmentId: string
  dateTime: ISODateString,
  arrangeAllAssignments: boolean
  lateSchedulingReason?: LateShootingScheduleReason
  lateSchedulingComment?: string
}

export function useArrangeDateTimeAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, SchedulePayload>(
    ({ assignmentId, ...body }) => api.put(
      Endpoints.CREATIVE_ARRANGE_DATETIME_ASSIGNMENT,
      { assignmentId },
      body,
      true
    ),
    (client, { assignmentId }) => {
      _invalidateAssignment(client, assignmentId)
    }
  )
}

export interface SubmitPayload {
  assignmentId: string
  filenames: Array<string>
  creativeComment: Nullable<string>
  minUploadCount: number
  product: ProductDTO
}

export function useSubmitAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, SubmitPayload>(
    ({ assignmentId, filenames, creativeComment }) => api.post(
      Endpoints.SUBMIT_ASSIGNMENT,
      { assignmentId },
      {
        visualsFilenames: filenames,
        creativeCommentForBackbone: creativeComment || undefined
      },
      true
    ),
    (client, { assignmentId, product, minUploadCount, filenames }) => {
      trackIntercomEvent('creative_visuals_delivery', {
        productKind: product.kind,
        productType: product.type,
        productCategory: product.category,
        assignmentId,
        numberOfVisualsRequired: minUploadCount,
        numberOfVisualsSent: filenames.length,
      })

      _invalidateAssignment(client, assignmentId)
    }
  )
}

export function useSubmitDocumentAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, SubmitPayload>(
    ({ creativeComment, assignmentId }) => api.post(
      Endpoints.SUBMIT_DOCUMENT_ASSIGNMENT,
      { assignmentId },
      { creativeCommentForBackbone: creativeComment },
      true
    ),
    (client, { assignmentId, product, minUploadCount, filenames }) => {
      trackIntercomEvent('creative_visuals_delivery', {
        productKind: product.kind,
        productType: product.type,
        productCategory: product.category,
        assignmentId,
        numberOfVisualsRequired: minUploadCount,
        numberOfVisualsSent: filenames.length,
      })

      _invalidateAssignment(client, assignmentId)
    }
  )
}

interface EditAssignmentDateTimePayload {
  assignmentId: string
  orderId: number
  dateTime: string
}

export function useEditAssignmentDateTime() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, EditAssignmentDateTimePayload>(
    ({ assignmentId, dateTime }) => api.put(
      Endpoints.EDIT_DELETE_ASSIGNMENT_DATETIME,
      { assignmentId },
      {
        dateTime
      },
      true
    ),
    (client, { assignmentId, orderId }) => {
      _invalidateAssignment(client, assignmentId)
      _invalidateOrder(client, orderId)
    }
  )
}

interface DeleteAssignmentDateTimePayload {
  assignmentId: string
  orderId: number
}

export function useDeleteAssignmentDateTime() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, DeleteAssignmentDateTimePayload>(
    ({ assignmentId }) => api.delete(
      Endpoints.EDIT_DELETE_ASSIGNMENT_DATETIME,
      {
        assignmentId,
      },
      false,
      {
        params: {
          missionId: assignmentId,
        }
      }
    ),
    (client, { assignmentId, orderId }) => {
      _invalidateAssignment(client, assignmentId)
      _invalidateOrder(client, orderId)
    }
  )
}

export function useStopAutomatedAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, { assignmentId: string, orderId: number }>(
    ({ assignmentId }) => api.post(
      Endpoints.STOP_AUTOMATED_ASSIGNMENT,
      { assignmentId },
      {},
      false
    ),
    (client, { assignmentId, orderId }) => {
      _invalidateAssignment(client, assignmentId)
      _invalidateOrder(client, orderId)
    }
  )
}

interface RatingPayload {
  assignmentId: string
  type: RatingType
  rating: EmojiValue
  ratingText: string
  isUpdate: boolean
}

export function useSubmitRating() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, RatingPayload>(
    ({ assignmentId, type, rating, ratingText, isUpdate }) => api.post(
      Endpoints.SUBMIT_RATING,
      { assignmentId },
      {
        type,
        rating: !isUpdate ? rating : undefined,
        ratingText: ratingText || undefined
      },
      true
    ),
    (client, { assignmentId }) => {
      _invalidateAssignment(client, assignmentId)
    }
  )
}

interface AutomateAssignmentPayload {
  assignmentId: string
  orderId: number
}

export function useAutomateAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AutomateAssignmentPayload>(
    ({ assignmentId }) => api.post(
      Endpoints.AUTOMATE_ASSIGNMENT,
      { assignmentId },
      {},
      false
    ),
    (client, { assignmentId, orderId }) => {
      _invalidateAssignment(client, assignmentId)
      _invalidateOrder(client, orderId)
    }
  )
}

export function useSyncAssignment() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<DealDTO>, { assignmentId: string, orderId: number }>(
    ({ assignmentId }) => api.post(
      Endpoints.SYNC,
      { assignmentId },
      {},
      true
    ),
    (client, { assignmentId, orderId }) => {
      _invalidateAssignment(client, assignmentId)
      _invalidateOrder(client, orderId)
    }
  )
}
