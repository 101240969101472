import { CatalogueOptionProduct, CatalogueProduct } from 'dataQueries/purchase.query'
import { Fragment, useCallback, useMemo } from 'react'
import { GermanyRegionCodes, RestrictedRegions } from 'constants/country'
import { KampfmittelauskunftProductId, ProductKind, ProductType } from 'constants/product'

import { Collapse } from '@mui/material'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { MUICheckCard } from 'components/common/MUICards/MUICheckCard'
import { MUIIntegerCard } from 'components/common/MUICards'
import { ProductCardContent } from '../ProductCardContent/ProductCardContent'
import { ProductCardHeader } from '../ProductCardHeader/ProductCardHeader.component'
import { ProductListHeader } from '../ProductListHeader'
import Stack from '@mui/material/Stack'
import { SystemMessage } from 'components/common/SystemMessage'
import { TransitionGroup } from 'react-transition-group'
import { bigFromFee } from 'utils/price'
import { isIntegerProduct } from 'utils/product'
import { standardDebouncedLogAnalyticsEvent } from 'utils/analytics'
import { standardTimeoutConfig } from 'utils/animations'
import { usePurchaseFlowConfig } from '../../_main/contexts/PurchaseFlowConfig.context'
import { usePurchaseFlowProducts } from '../../_main/contexts/PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

const productComparison = (prodA: CatalogueProduct, prodB: CatalogueProduct) => bigFromFee(prodA.feePrice).minus(bigFromFee(prodB.feePrice)).toNumber()

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether to display type heading element */
  displayTypeHeading?: boolean
  /** Whether to display only selected types, products and options */
  onlySelected?: boolean
  /** Whether to disabled selecting/deselecting */
  disabledSelection?: boolean
  /** Whether the product listing is disabled and grayed out */
  disabled?: boolean
}

/**
 * @component Component which lists all products in purchase flow
 * @example
 * <ProductListing />
 */
export const ProductListing: React.FC<Props> = ({
  displayTypeHeading = true,
  onlySelected = false,
  disabledSelection = false,
  disabled = false,
}) => {
  const { t } = useTranslation(['order', 'product_type', 'product_type_description', 'segment', 'product_kind_description', 'step_product'])

  const {
    getProductCatalogue,
    availableProductTypesPerSegment,
    selectedCategory,
    catalogueCurrency,
    catalogueDiscount,
    regionCode,
  } = usePurchaseFlowConfig()
  const {
    selectedFilterTypes,
    selectedSegment,
    selectedProducts,
    selectedProductOptions,
    selectProduct,
    selectOptionProduct,
    unselectOptionProduct,
    unselectProduct,
  } = usePurchaseFlowProducts()

  const handleLogOptionSelection = useCallback((quantity: number, productKind: ProductKind, parentProductKind: ProductKind) => {
    standardDebouncedLogAnalyticsEvent('option_kind_selected', { quantity, productKind, parentProductKind })
  }, [])

  const handleLogProductSelection = useCallback((quantity: number, productKind: ProductKind, productType: ProductType) => {
    standardDebouncedLogAnalyticsEvent('product_selected', { quantity, productKind, productType })
  }, [])

  const isSelectedRegionRestricted = useMemo(() => {
    return RestrictedRegions.has(regionCode as GermanyRegionCodes)
  }, [regionCode])

  const productTypesToRender = useMemo(() => {
    if (!selectedSegment) return []

    const toRender = [
      ...(availableProductTypesPerSegment[selectedSegment]?.nonBundle || []),
      ...(availableProductTypesPerSegment[selectedSegment]?.bundle || [])
    ].filter(({ productType }) => selectedFilterTypes.has(productType))

    return toRender
  }, [availableProductTypesPerSegment, selectedFilterTypes, selectedSegment])

  return (
    <DynamicQueryContent
      query={getProductCatalogue}
    >
      <Fragment>
        {!!selectedSegment &&
          <Fragment>
            {productTypesToRender
              .map(({ productType, products }) => {
                return (
                  <Fragment key={productType}>

                    {/* PRODUCT TITLE */}
                    {displayTypeHeading &&
                      <ProductListHeader
                        title={t(`product_type:${productType}`)}
                      />
                    }

                    {/* PRODUCT LIST */}
                    <Stack gap={2} marginTop={2}>
                      {products
                        .filter(product => (onlySelected && !!selectedProducts[product.id]) || !onlySelected)
                        .sort(productComparison)
                        .map((product) => {
                          const isInteger = !!selectedCategory && isIntegerProduct(selectedCategory, product.kind)
                          const hasOptions = Object.values(product.options ?? {}).length > 0
                          const isProductPreview = disabledSelection && onlySelected
                          const selectedQuantity = selectedProducts[product.id]?.quantity

                          const handleNumberChange = (value: number, option: CatalogueOptionProduct) => {
                            const normalizedValue = Math.min(100, Math.max(0, value || 0))

                            if (normalizedValue === 0) unselectOptionProduct(product.id, option.id)
                            else selectOptionProduct(product.id, option, normalizedValue)

                            handleLogOptionSelection(normalizedValue, option.kind, product.kind)
                          }

                          const handleCheckboxChange = (checked: boolean, option: CatalogueOptionProduct) => {
                            if (checked) selectOptionProduct(product.id, option, 1)
                            else unselectOptionProduct(product.id, option.id)

                            handleLogOptionSelection(checked ? 1 : 0, option.kind, product.kind)
                          }

                          const handleQuantityChange = (quantity?: number) => {
                            if (disabledSelection) return
                            if (!quantity || quantity <= 0) unselectProduct(product.id)
                            else selectProduct(product, quantity)

                            handleLogProductSelection(quantity ?? 0, product.kind, productType)
                          }

                          const handleCheckCardChange = () => {
                            if (disabledSelection) return

                            const willBeUnchecked = selectedQuantity && selectedQuantity > 0

                            if (willBeUnchecked) unselectProduct(product.id)
                            else selectProduct(product, 1)

                            // For construction files, only one can be selected at a time
                            if (productType === ProductType.CONSTRUCTION_FILE) {
                              const selectedConstructionProducts = Object.values(selectedProducts).filter(
                                ({ type }) => type === ProductType.CONSTRUCTION_FILE
                              )

                              if (selectedConstructionProducts.length > 0) {
                                const [{ id: productToUnselectId }] = selectedConstructionProducts
                                unselectProduct(productToUnselectId)
                              }
                            }

                            handleLogProductSelection(willBeUnchecked ? 0 : 1, product.kind, productType)
                          }

                          const expandableContent = (
                            <ProductCardContent
                              product={product}
                              onlySelected={onlySelected}
                              segmentKey={selectedSegment}
                              typeOrKindKey={productType}
                              discount={catalogueDiscount}
                              currency={catalogueCurrency}
                              disabledSelection={disabledSelection}
                              onNumberInputChange={handleNumberChange}
                              onCheckboxChange={handleCheckboxChange}
                            />
                          )

                          const getExpandableContent = () => {
                            if (isProductPreview && !!selectedProductOptions[product.id]) return expandableContent
                            if (!isProductPreview && hasOptions) return expandableContent
                            return undefined
                          }

                          return (
                            <TransitionGroup component={null} key={`${selectedSegment}_${productType}_${product.id}`}>
                              <Collapse
                                in={true}
                                timeout={standardTimeoutConfig}
                              >
                                <Stack>
                                  {!isInteger &&
                                    <MUICheckCard
                                      disabled={disabled}
                                      showSelectElement={!isProductPreview}
                                      onCheck={handleCheckCardChange}
                                      checked={selectedQuantity > 0}
                                      isHeaderClickable={!disabledSelection}
                                      disabledSelection={disabledSelection}
                                      isRadio={productType === ProductType.CONSTRUCTION_FILE}
                                      expandableContent={getExpandableContent()}
                                    >

                                      {!!catalogueCurrency && !!selectedCategory &&
                                        <ProductCardHeader
                                          product={product}
                                          disabled={disabled}
                                          typeKey={productType}
                                          category={selectedCategory}
                                          currency={catalogueCurrency}
                                          displayPrice={!isProductPreview}
                                          quantity={isProductPreview ? selectedQuantity : undefined}
                                        />
                                      }

                                      {isSelectedRegionRestricted && product.id === KampfmittelauskunftProductId &&
                                        <SystemMessage
                                          message={t('step_product.region_warning')}
                                          variant="warning"
                                          sx={{ marginTop: 1 }}
                                        />
                                      }

                                    </MUICheckCard>
                                  }

                                  {isInteger &&
                                    <MUIIntegerCard
                                      inputPosition="right"
                                      disabled={disabled}
                                      defaultQuantity={selectedQuantity}
                                      hideCounterInput={!isProductPreview}
                                      onQuantityChange={handleQuantityChange}
                                      expandableContent={getExpandableContent()}
                                    >
                                      {!!catalogueCurrency && !!selectedCategory &&
                                        <ProductCardHeader
                                          product={product}
                                          disabled={disabled}
                                          typeKey={productType}
                                          category={selectedCategory}
                                          currency={catalogueCurrency}
                                          displayPrice={isProductPreview}
                                          quantity={isProductPreview ? selectedQuantity : undefined}
                                        />
                                      }
                                    </MUIIntegerCard>
                                  }

                                </Stack>
                              </Collapse>
                            </TransitionGroup>
                          )
                        })}
                    </Stack>
                  </Fragment>
                )
              })}
          </Fragment>
        }
      </Fragment>
    </DynamicQueryContent>
  )
}
