import Geocode from 'react-geocode'

export async function lookupPlaceFromAddress(address: string) {
  try {
    const response = await Geocode.fromAddress(address)
    if (response.results.length === 0) return null
    return response.results[0]
  } catch (error) {
    console.error(error)
    return null
  }
}
