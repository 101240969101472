import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { FeatureFlag } from 'utils/featureFlags'
import { Path } from 'constants/router'
import Stack from '@mui/material/Stack'
import { TabButton } from 'components/common/TabButton'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'

/** Enum representing the navigation paths for the TopBar component. */
enum NavigationPaths {
  PROPERTIES_DASHBOARD = 'PROPERTIES_DASHBOARD',
  ORDERS = 'ORDERS',
}

/**
 * @component
 * TopBarNavigation renders a navigation bar with tabs.
 * 
 * @example
 *  <TopBarNavigation />
 */
export const TopBarNavigation: React.FC = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['topbar'])

  const allowPropertySelectionStep = useFlag(FeatureFlag.ALLOW_PROPERTY_SELECTION_STEP)

  const activeTab = useMemo(() => {
    // Set the active tab based on the current location
    if (location.pathname === Path.INDEX) return NavigationPaths.ORDERS

    // Here we can add more conditions to set the active tab based on the current location
    if (location.pathname === Path.PROPERTIES_DASHBOARD) return NavigationPaths.PROPERTIES_DASHBOARD

    return null
  }, [location.pathname])

  const tabs = useMemo(() => {
    return Object.values(NavigationPaths).map((path) => ({
      label: path,
      path,
    }))
  }, [])

  /** Handles the tab click event by redirecting to the selected tab. */
  const handleTabClick = useCallback((path: NavigationPaths) => {
    if (path === NavigationPaths.ORDERS && location.pathname !== Path.INDEX) {
      navigate(Path.INDEX)
    }

    if (path === NavigationPaths.PROPERTIES_DASHBOARD && location.pathname !== Path.PROPERTIES_DASHBOARD) {
      navigate(Path.PROPERTIES_DASHBOARD)
    }
  }, [location.pathname, navigate])

  return (
    <Stack flex={1} justifyContent="center" direction="row" gap={1.2}>
      {tabs.map(({ path, label }) => {
        if (!allowPropertySelectionStep && path === NavigationPaths.PROPERTIES_DASHBOARD) return <></>

        return (
          <TabButton
            key={path}
            label={t(`navigation_tab.${label}`)}
            isActive={activeTab === path}
            onClick={() => handleTabClick(path)}
          />
        )
      })}
    </Stack>
  )
}
