import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { FC } from 'react'
import { MUIButton } from 'components/common/MUIButton'
import { PageActions } from 'components/common/Page/PageActions'
import { useTranslation } from 'react-i18next'

/**
 * Properties dashboard page action.
 * 
 * @example <PropertiesDashboardPageAction />
 */
export const PropertiesDashboardPageAction: FC = () => {
  const { t } = useTranslation(['properties_dashboard'])

  return (
    <PageActions>

      <MUIButton
        type="defaultPrimary"
        onClick={() => console.log('clicked')}
        startIcon={<AddRoundedIcon fontSize='large' />}
      >
        {t('add_property')}
      </MUIButton>

    </PageActions >
  )
}
