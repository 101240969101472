import { FC, ReactNode } from 'react'

import styles from './Workspace.module.sass'

/**
 * Container component for interactive content of page.
 * Responsively handles vertical overflow.
 * @example
 *  <Workspace>
 *    <div>interactive page content</div>
 *  </Workspace>
 */
export const Workspace: FC<{
  children?: ReactNode
}> = ({
  children
}) => {
    return (
      <div className={styles.workspace}>
        {children}
      </div>
    )
  }
