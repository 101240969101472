import { DeleteOptions, FileAPIEntry, FileDeleteFnc, LoadOptions, LoadUrlResolver, ReUploadOptions, ReUploadUrlResolver, UploadOptions, UploadUrlResolver } from './fileAPI.types'
import { useEffect, useMemo } from 'react'

import { RecursivePartial } from 'models/helpers'
import { useFileAPIController } from './FileAPIController.context'
import { useOnUnmountWithDeps } from 'utils/helpers'

export const EMPTY_FILE_TAG_KEY = 'EMPTY_TAG'

export interface Options<MetadataType extends { [key: string]: any } = {}> {
  /** Axios function that calls delete endpoint for file */
  deleteHandler?: FileDeleteFnc<MetadataType>
  /** Axios function calling BE to obtain signed url for loading files */
  loadUrlResolver?: LoadUrlResolver<MetadataType>
  /** Axios function calling BE to obtain signed url for uploading files */
  uploadUrlResolver?: UploadUrlResolver<MetadataType>
  /** Axios function calling BE to obtain signed url for uploading files */
  reUploadUrlResolver?: ReUploadUrlResolver<MetadataType>
  /** Whether all entries of scope should be purged on hook destroy (provided default = true) */
  purgeOnDestroy?: boolean
}

/** Consumer hook for interacting with FileAPI */
export const useFileAPI = <MetadataType extends { [key: string]: any } = {}>(
  scope: string,
  options: Options<MetadataType> = {}
) => {

  const { deleteHandler, uploadUrlResolver, loadUrlResolver, reUploadUrlResolver } = options

  const ctrl = useFileAPIController()

  const files = useMemo(() => (ctrl.fileInventory[scope] || {}) as Record<string, FileAPIEntry<MetadataType>>, [ctrl.fileInventory, scope])

  const allFilesArray = useMemo(() => Object.values(ctrl.fileInventory[scope] ?? []) as Array<FileAPIEntry<MetadataType>>, [ctrl.fileInventory, scope])

  const filesByTag = useMemo(
    () => Object.values(files)
      .reduce(
        (acc, file) => ({
          ...acc,
          [file.tag ?? EMPTY_FILE_TAG_KEY]: [
            ...(acc[file.tag ?? EMPTY_FILE_TAG_KEY] || []),
            file
          ]
        }),
        {} as Record<string, Array<FileAPIEntry<MetadataType>>>
      ),
    [files]
  )

  /** Function that takes partial file entries and either merges them with existing files or creates full new entry and adds it to scope */
  const mergeAddFiles = (partialFiles: Array<Pick<FileAPIEntry<MetadataType>, 'id'> & Partial<FileAPIEntry<MetadataType>>>) => {
    const filesToInit: Array<FileAPIEntry<MetadataType>> = []

    for (const file of partialFiles) {
      if (!!files[file.id]) {
        filesToInit.push({
          ...files[file.id],
          ...file,
        })
      } else {
        filesToInit.push(ctrl.createFileAPIEntry(file))
      }
    }

    return ctrl.initFiles<MetadataType>(
      scope,
      filesToInit
    )
  }

  /** Function that edits existing file with partial updates */
  const editFile = (fileId: string, partialFile: RecursivePartial<FileAPIEntry<MetadataType>>) => {
    return ctrl.updateFileEntry<MetadataType>(scope, fileId, partialFile)
  }

  /** Obtains signedUrl for each file and proceeds with parallel upload of all files updating progress. Optional tag for potential grouping of files etc. */
  const uploadFiles = (files: FileList | Array<File>, uploadOptions: UploadOptions<MetadataType> = {}) => {
    const resolver = uploadOptions.uploadUrlResolverFnc ?? uploadUrlResolver

    if (!resolver) {
      console.error('No uploadUrlResolver function provided, aborting')
      return
    }

    ctrl.uploadFiles<MetadataType>(scope, files, resolver, uploadOptions)
  }

  /** Obtains replace signedUrl for file and proceeds with upload of file whilst updating progress. Optional tag for potential grouping of files etc. */
  const reUploadFile = (file: File, replaceId: string, reUploadOptions: ReUploadOptions<MetadataType> = {}) => {
    const resolver = reUploadOptions.reUploadUrlResolverFnc ?? reUploadUrlResolver

    if (!resolver) {
      console.error('No reUploadUrlResolver function provided, aborting')
      return
    }

    ctrl.reUploadFile<MetadataType>(scope, replaceId, file, resolver, reUploadOptions)
  }

  /** Deletes provided files from BE and then from inventory. */
  const deleteFiles = (ids: Array<string>, deleteOptions: DeleteOptions<MetadataType> = {}) => {
    const handler = deleteOptions.deleteHandlerFnc ?? deleteHandler

    if (!handler) {
      console.error('No delete function provided, aborting')
      return
    }

    ctrl.deleteFiles<MetadataType>(scope, ids, handler)
  }

  /** Obtains signedUrl for showing images and preloads them into browser cache in background using workers */
  const loadFiles = (fileIds: Array<string>, loadOptions: LoadOptions<MetadataType> = {}) => {
    const handler = loadOptions.loadUrlResolverFnc ?? loadUrlResolver

    if (!handler) {
      console.error('No load url resolver function provided, aborting')
      return
    }

    ctrl.loadFiles<MetadataType>(scope, fileIds, handler)
  }

  // Initialize scope
  useEffect(
    () => {
      ctrl.initScope(scope)
    },
    // Init only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useOnUnmountWithDeps(
    () => {
      if (options.purgeOnDestroy !== false) {
        ctrl.purgeScope(scope)
      }
    },
    [scope, options.purgeOnDestroy]
  )

  return {
    uploadFiles,
    deleteFiles,
    loadFiles,
    files,
    filesByTag,
    allFilesArray,
    createEntry: ctrl.createFileAPIEntry,
    reUploadFile,
    purgeFilesScope: ctrl.purgeScope,
    mergeAddFiles,
    editFile,
  }
}

export type UseFileAPIReturn = ReturnType<typeof useFileAPI>
