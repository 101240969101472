import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, useEffect } from 'react'
import { FileType, supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'
import { Trans, useTranslation } from 'react-i18next'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../_main/contexts'

import { EnergyPassReason } from 'constants/purchaseFlow/sharedEnergyCertificateTypes'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { Label } from 'components/common/Label'
import { MUIInputField } from 'components/common/MUIInputField'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import { useAdditionalInformation } from './AdditionalInformation.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Allow open construction upload section or not */
  allowConstructionUpload?: boolean
}

/**
 * Content for the AdditionalInformation for both the ConsumptionCertificateStepController and the EnergyCertificateStepController.
 * 
 * @example
 * <AdditionalInformation />
 */
export const AdditionalInformation: FC<Props> = ({
  allowConstructionUpload = false
}) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'additional_information_page' })

  const {
    message,
    setMessage,
    energyCertificateReason,
    setEnergyCertificateReason,
    exteriorViewUpload,
    heaterNameplateUpload,
    constructionDescriptionUpload,
  } = useAdditionalInformation()

  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  const { selectedCategory } = usePurchaseFlowConfig()

  const maximumUploads = 1

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.OPENS_ADDITIONAL_INFORMATION, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })
    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SectionedBorderBox title={t('title')}>
      <Stack gap="1.6rem" position="relative">

        <Stack gap=".6rem">

          <Label text={t('reason_for_issue_certificate')} />

          <Stack flexDirection="row" gap={1} alignItems="center" flexWrap="wrap">
            {Object.values(EnergyPassReason).map(reason => (
              <ToggleButton
                key={reason}
                sx={{ height: '4rem', justifyContent: 'center' }}
                isSelected={energyCertificateReason === reason}
                label={t(`energy_certificate_reason.${reason}`)}
                onClick={() => setEnergyCertificateReason(reason)}
              />
            ))}
          </Stack>

        </Stack>

        <FileAPIUpload
          title={<Trans
            t={t}
            i18nKey="exterior_view"
            values={{ uploadCount: exteriorViewUpload.allFilesArray.length, maximumUploads }}
          />}
          maxNumberOfFiles={1}
          files={exteriorViewUpload.allFilesArray}
          acceptedFileTypes={[FileType.JPEG, FileType.PNG]}
          isDraggingFile={false}
          uploadHandler={(acceptedFiles) => exteriorViewUpload.uploadFiles(acceptedFiles)}
          onDelete={(fileId) => exteriorViewUpload.deleteFiles([fileId])}
        />

        <FileAPIUpload
          title={<Trans
            t={t}
            i18nKey="heater_nameplate"
            values={{ uploadCount: heaterNameplateUpload.allFilesArray.length, maximumUploads }}
          />}
          maxNumberOfFiles={1}
          files={heaterNameplateUpload.allFilesArray}
          acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
          isDraggingFile={false}
          uploadHandler={(acceptedFiles) => heaterNameplateUpload.uploadFiles(acceptedFiles)}
          onDelete={(fileId) => heaterNameplateUpload.deleteFiles([fileId])}
        />

        {allowConstructionUpload &&
          <FileAPIUpload
            title={<Trans
              t={t}
              i18nKey="construction_description"
              values={{ uploadCount: constructionDescriptionUpload.allFilesArray.length, maximumUploads }}
            />}
            maxNumberOfFiles={1}
            files={constructionDescriptionUpload.allFilesArray}
            acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
            isDraggingFile={false}
            uploadHandler={(acceptedFiles) => constructionDescriptionUpload.uploadFiles(acceptedFiles)}
            onDelete={(fileId) => constructionDescriptionUpload.deleteFiles([fileId])}
          />
        }

        {/* Might be deleted - just workaround for multiupload images
          * when this is removed, also remove translation key "additional_files"
          */}
        {/* <FileAPIUpload
          title={<Trans
            t={t}
            i18nKey="additional_files"
            values={{ uploadCount: additionalFilesUpload.allFilesArray.length, maximumUploads }}
          />}
          maxNumberOfFiles={1}
          files={additionalFilesUpload.allFilesArray}
          acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
          isDraggingFile={false}
          uploadHandler={(acceptedFiles) => additionalFilesUpload.uploadFiles(acceptedFiles)}
          onDelete={(fileId) => additionalFilesUpload.deleteFiles([fileId])}
        /> */}

        <MUIInputField
          label={t('message_the_expert')}
          placeholder={t('message_the_expert_placeholder')}
          isMultiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

      </Stack>
    </SectionedBorderBox>
  )
}
