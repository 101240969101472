import '../../Dashboard/Dashboard.sass'
import './AdministratorDashboard.sass'

import { Box, Stack } from '@mui/material'
import { OrderAssignmentStatusFilter, Params } from 'constants/misc'
import { useInfiniteListAssignments, useInfiniteListOrders } from 'dataQueries'

import { AdminAssignmentStatusFilters } from './AdminAssignmentStatusFilters.module'
import { AdminInfiniteListHeader } from './AdminInfiniteListHeader.module'
import { AdminInfiniteListItem } from './AdminInfiniteListItem.module'
import { AssignmentDTO } from 'models/assignment'
import { BEIGE_300 } from 'constants/styling/theme'
import { ControlPanel } from 'components/pages/Dashboard/ControlPanel'
import { DealDTO } from 'models/deal'
import { InfiniteList } from 'components/common/InfiniteList/InfiniteList.component'
import { PageTransition } from 'utils/animations'
import { SearchFilter } from 'components/pages/Dashboard/SearchFilter'
import { standardDebouncedLogAnalyticsEvent } from 'utils/analytics'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useDebouncedMemo } from 'utils/helpers'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

export enum Type {
  ASSIGNMENT = 'ASSIGNMENT',
  ORDER = 'ORDER',
}

const ADMIN_INFINITE_LIST_COMPONENT_STYLES = {
  boxSizing: 'border-box',
  width: '95%',
  maxWidth: '90rem',
}

/**
 * Page listing all assignments and orders with filters.   
 * @example <AdminDashboard />
 */
export const AdminDashboard: React.FC = () => {
  const { t } = useTranslation(['dashboard_administrator', 'dashboard'])
  const { adminData } = useUserData()
  const params = useBkbnSearchParams()

  // SEARCH DEBOUNCE HANDLING
  const search = useMemo(() => params.get(Params.SEARCH) || '', [params])
  const debouncedSearch = useDebouncedMemo(() => search, [search], 300)

  // FILTERS
  const filters = useMemo(() => ({
    status: params.get(Params.STATUS) as OrderAssignmentStatusFilter || OrderAssignmentStatusFilter.ONGOING,
    userIds: params.getArray(Params.USERS),
    type: params.get(Params.TYPE) || Type.ORDER,
    search: debouncedSearch || null,
  }), [params, debouncedSearch])

  // ORDERS
  const orderList = useInfiniteListOrders(
    'ADMIN_INFINTE_ASSIGNMENT_LIST',
    filters
  )

  // ASSIGNMENTS
  const assignmentList = useInfiniteListAssignments(
    'ADMIN_INFINTE_ASSIGNMENT_LIST',
    filters
  )

  return (
    <PageTransition>
      <Stack
        direction="row"
        alignItems="stretch"
        sx={{
          position: 'absolute',
          top: '6.4rem',
          left: 0,
          bottom: 0,
          width: '100%',
          height: 'calc(100vh - 6.4rem)',
          overflow: 'hidden',
          background: BEIGE_300,
        }}
      >

        <Box flex="0 0 auto">
          <ControlPanel title={t('dashboard:filters')}>
            <div className='dashboard-filters'>

              <SearchFilter
                placeholder={t('search_order_or_assignment')}
                value={search}
                onChange={(value) => {
                  standardDebouncedLogAnalyticsEvent('OPS Filter - Search Bar', {
                    userEmail: adminData?.email,
                  })
                  params.set([Params.SEARCH, value])
                }}
              />

              <AdminAssignmentStatusFilters filters={filters} />

            </div>
          </ControlPanel>
        </Box>

        <Stack flex="1 1 100%" alignItems="center" sx={{ overflowY: 'auto', paddingTop: '4rem' }}>

          {/* ORDER INFINITE LIST */}
          {filters.type === Type.ORDER &&
            <InfiniteList<DealDTO>
              query={orderList}
              header={<AdminInfiniteListHeader filters={filters} />}
              renderItem={(data) => <AdminInfiniteListItem key={data.id} data={data} />}
              sx={ADMIN_INFINITE_LIST_COMPONENT_STYLES}
            />
          }

          {/* ASSIGNMENT INFINITE LIST */}
          {filters.type === Type.ASSIGNMENT &&
            <InfiniteList<AssignmentDTO>
              query={assignmentList}
              header={<AdminInfiniteListHeader filters={filters} />}
              renderItem={(data) => <AdminInfiniteListItem key={data.id} data={data} />}
              sx={ADMIN_INFINITE_LIST_COMPONENT_STYLES}
            />
          }

        </Stack>

      </Stack>
    </PageTransition>
  )
}
