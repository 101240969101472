import { Dispatch, FC, Fragment, SetStateAction, useCallback, useMemo } from 'react'
import { GRAY_700, GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import { RoomItemPopupType, useStyleSelection } from '../main/contexts'

import { AnalyticsEvent } from 'utils/analytics'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { DecorationsSelectionPopup } from './DecorationsSelectionPopup.component'
import { FurnitureItemSelectionPopup } from './FurnitureTemplateSelectionPopup.component'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { RenovationsSelectionPopup } from './RenovationsSelectionPopup.component'
import { RoomItemsButtons } from './RoomItemsButtons.component'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import Stack from '@mui/material/Stack'
import { StagingType } from 'models/virtualStaging'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import { VirtualFurnitureRoomRemovableOption } from 'constants/virtualFurniture'
import { VisualStyleSelectionContainerState } from '../VisualStyleSelectionsContainer'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTranslation } from 'react-i18next'

/** Props for the RoomItems component. */
interface RoomItemsProps {
  /** Control the step of the room selection. */
  setVisualStyleSelectionStep: Dispatch<SetStateAction<VisualStyleSelectionContainerState>>
}

/** 
 * @component
 * RoomItems display options for selecting different room items.
 * 
 * @example
 * <RoomItems />
 */
export const RoomItems: FC<RoomItemsProps> = ({ setVisualStyleSelectionStep }) => {
  const { t } = useTranslation(['style_selection_order_step'])

  const { updateRoom } = useRoomAPI()

  const {
    activeRoom,
    selectedRemovableObject,
    roomItemPopupType,
    setIsSpecialRequestPopupOpen,
    stagingFlowAnalytics
  } = useStyleSelection()

  const onOpenSpecialRequestPopup = useCallback(() => {
    stagingFlowAnalytics(AnalyticsEvent.STAGING_OPEN_COMMENT_POPUP, {})
    setIsSpecialRequestPopupOpen(true)
  }, [setIsSpecialRequestPopupOpen, stagingFlowAnalytics])

  const resetRoomItems = useCallback(() => {
    updateRoom(activeRoom.key, {
      styleCodeMap: activeRoom.styleCodeMap.clear(),
      decorations: activeRoom.decorations.clear(),
      renovations: activeRoom.renovations.clear(),
    })
  }, [activeRoom.decorations, activeRoom.key, activeRoom.renovations, activeRoom.styleCodeMap, updateRoom])

  const backToRoomTypes = useCallback(() => {
    setVisualStyleSelectionStep(VisualStyleSelectionContainerState.ROOM_TYPES_AND_REMOVABLE_OPTIONS)
    resetRoomItems()
  }, [resetRoomItems, setVisualStyleSelectionStep])

  const optionsStagingType: ToggleFilterValue<StagingType>[] = useMemo(() => {
    return Object.values(StagingType).map((stagingType: StagingType) => ({
      value: stagingType,
      displayName: t(`staging_type.${stagingType}`),
    }))
  }, [t])

  const onSelectStagingType = useCallback((item: StagingType) => {
    const analyticStagingType = item === StagingType.BKBN ? AnalyticsEvent.LET_BKBN_DECIDE_SELECTED : AnalyticsEvent.CUSTOMISE_MYSELF_SELECTED
    stagingFlowAnalytics(analyticStagingType, {})

    updateRoom(activeRoom.key, { type: item })
    resetRoomItems()
  }, [activeRoom.key, resetRoomItems, stagingFlowAnalytics, updateRoom])

  return (
    <Stack justifyContent="space-between" alignItems="center" position="relative">

      {/** GO BACK BUTTON */}
      <MUIButton
        isIconButton
        type="secondaryNoBorder"
        size="xs"
        startIcon={<ArrowBackRoundedIcon />}
        onClick={backToRoomTypes}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      >
        {t('style_selection_order_step:back')}
      </MUIButton>


      {/** SELECTED ROOM TYPES AND REMOVABLE OBJECTS */}
      <Stack
        maxWidth="70%"
        gap={1}
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        mb={2}
      >

        {activeRoom.roomTypes.map(roomType => (
          <MUIBadge
            label={t(`virtual_furniture_room_type:${roomType}`)}
            key={roomType}
            size="lg"
            color="gray"
            sx={{ backgroundColor: WHITE }}
          />
        ))}

        {selectedRemovableObject && selectedRemovableObject !== VirtualFurnitureRoomRemovableOption.NONE &&
          <MUIBadge
            label={t(`virtual_furniture_room_removable_option:${selectedRemovableObject}`)}
            size="lg"
            color="gray"
            sx={{ backgroundColor: WHITE, marginLeft: 1 }}
          />
        }

      </Stack>

      {/** STAGING TYPE, default type is CUSTOM */}
      <Stack alignItems="center" gap={2}>
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('staging_type_question')}
        </Typography>

        <SelectableButtonsGrid
          values={optionsStagingType}
          selectedValue={activeRoom?.type}
          onSelect={onSelectStagingType}
          icons={{
            [StagingType.BKBN]: (
              <MUITooltip content={t('staging_type_tooltip')}>
                <Stack>
                  <InfoOutlinedIcon sx={{ color: GRAY_800, fontSize: '1.8rem' }} />
                </Stack>
              </MUITooltip>
            )
          }}
        />
      </Stack>

      {/** ROOM ITEMS */}
      {activeRoom?.type &&
        <Fragment>

          <RoomItemsButtons />

          <FurnitureItemSelectionPopup isOpen={roomItemPopupType === RoomItemPopupType.FURNITURE} />
          <DecorationsSelectionPopup isOpen={roomItemPopupType === RoomItemPopupType.DECORATION} />
          <RenovationsSelectionPopup isOpen={roomItemPopupType === RoomItemPopupType.WALL || roomItemPopupType === RoomItemPopupType.FLOOR} />

          {/** SPECIAL REQUEST */}
          <Stack width="100%" gap=".4rem" flexDirection="row" justifyContent="center" padding="2.4rem 0 1rem 0">
            <Typography variant="text-sm" color={GRAY_700}>
              {t('special_request.need_special_request')}
            </Typography>

            <Typography
              variant="text-sm"
              fontWeight={600}
              color={GRAY_900}
              sx={{ textDecorationLine: 'underline', cursor: 'pointer' }}
              onClick={onOpenSpecialRequestPopup}
            >
              {t('special_request.tell_us')}
            </Typography>
          </Stack>

        </Fragment>
      }

    </Stack>
  )
}
