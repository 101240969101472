import { BEIGE_500, GRAY_200, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { useMemo } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper'
import Typography from '@mui/material/Typography'

/** @interface Props for the TabButton component. */
export interface Props {
  /** The label to be displayed on the button. */
  label: string
  /** Whether the button is active. */
  isActive?: boolean
  /** Whether the button is disabled. */
  disabled?: boolean
  /** Onclick action to be called. */
  onClick?: () => void
}

/**
 * @component
 * `TabButton` renders a button with customizable label, active state, and disabled state.
 * 
 * @example
 *  <TabButton label="Tab 1" isActive={true} onClick={() => console.log('Tab 1 clicked')} />
 */
export const TabButton: React.FC<Props> = ({ label, isActive = false, disabled = false, onClick }) => {

  const backgroundColor = useMemo(() => {
    if (disabled) return GRAY_200
    return isActive ? BEIGE_500 : 'white'
  }, [disabled, isActive])

  return (
    <BorderBoxWrapper
      elevation="none"
      borderRadius="0.4rem"
      borderColor={'transparent'}
      onClick={e => onClick?.()}
      sx={{
        gap: '1.2rem',
        display: 'flex',
        height: '3.6rem',
        cursor: !disabled ? 'pointer' : 'not-allowed',
        position: 'relative',
        alignItems: 'center',
        padding: '0.8rem 1.6rem',
        backgroundColor,
        '&:hover': {
          backgroundColor: !disabled ? BEIGE_500 : GRAY_200
        }
      }}
    >

      <Typography
        variant='text-sm'
        fontWeight={isActive ? 600 : 500}
        color={isActive ? GRAY_900 : GRAY_700}
      >
        {label}
      </Typography>

    </BorderBoxWrapper>
  )
}
