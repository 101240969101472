import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { FC, Fragment, ReactNode } from 'react'

import ActionPopupStyles from './ActionPopup.module.sass'
import { ActionPopupTypes } from 'redux/Individual/ActionPopup'
import { Close } from '@mui/icons-material'
import { MUIButton } from 'components/common/MUIButton'
import classnames from 'classnames'
import { createPortal } from 'react-dom'
import { multilineText } from 'utils/formatting'
import { useActionPopup } from 'utils/hooks'
import { useTranslation } from 'react-i18next'

/**
 * @component Display overlay popup requiring user's interaction, normally use in confirmation and notification purpose.
 * Controlled by showConfirm in useActionPopup() in global way. Can be used as independent component by setting disableGlobalActionPopup.
 * @example <ActionPopup isGlobal={false}>
 *  <span>Some text here</span>
 * </ActionPopup>
 */
export const ActionPopup: FC<{
  /** Decides if the ActionPopup is global (top-level) */
  isGlobal?: boolean
  /** Decided if the Confirm button is disabled */
  isConfirmDisabled?: boolean
  children?: ReactNode
}> = ({
  children,
  isGlobal = false,
  isConfirmDisabled = false,
}) => {
    const { t } = useTranslation(['action_popup'])
    const {
      onConfirmAccept,
      onConfirmDeny,
      onAlertClose,
      actionPopupState
    } = useActionPopup()
    const {
      type,
      body,
      isShown,
      options: {
        title,
        actionsAdditionalSlot,
        alertCloseText,
        confirmDenyText,
        confirmAcceptText,
        confirmDisabledText,
        disableGlobalActionPopup,
        hideSecondaryButton,
      }
    } = actionPopupState

    const actionPopup: React.ReactNode = (
      <TransitionGroup component={null}>
        {isShown && !(isGlobal && disableGlobalActionPopup) &&
          <CSSTransition
            in={isShown}
            timeout={150}
            classNames={{
              enter: ActionPopupStyles.popupEnter,
              enterActive: ActionPopupStyles.popupEnterActive,
              exit: ActionPopupStyles.popupExit,
              exitActive: ActionPopupStyles.popupExitActive,
            }}
          >
            <div className={ActionPopupStyles.backdrop} onClick={(e) => e.stopPropagation()}>
              <div className={ActionPopupStyles.content}>

                <MUIButton
                  type="secondaryBorder"
                  isIconButton={true}
                  endIcon={<Close fontSize="large" />}
                  sx={{
                    position: 'absolute',
                    top: '2rem',
                    right: '2rem',
                    width: '3.5rem',
                    height: '3.5rem',
                  }}
                  onClick={() => onConfirmDeny()}
                />

                {title && <div className={ActionPopupStyles.title}>{title}</div>}
                <div className={ActionPopupStyles.body}>
                  {!!children
                    ? children
                    : typeof body === 'string'
                      ? multilineText(body)
                      : body
                  }
                </div>

                {type === ActionPopupTypes.ALERT &&
                  <div className={ActionPopupStyles.actions}>
                    {actionsAdditionalSlot}
                    <MUIButton
                      size="md"
                      className={ActionPopupStyles.button}
                      onClick={(e) => onAlertClose()}
                    >
                      {alertCloseText || t('action_popup:close')}
                    </MUIButton>
                  </div>
                }

                {type === ActionPopupTypes.CONFIRM &&
                  <Fragment>

                    <div className={classnames(ActionPopupStyles.actions, { [ActionPopupStyles.hiddenSecondaryButtonActions]: hideSecondaryButton })}>

                      {actionsAdditionalSlot}

                      {!hideSecondaryButton &&
                        <MUIButton
                          className={ActionPopupStyles.button}
                          size="md"
                          type="secondaryNoBorder"
                          onClick={() => onConfirmDeny()}
                        >
                          {confirmDenyText || t('action_popup:deny')}
                        </MUIButton>
                      }

                      <MUIButton
                        className={ActionPopupStyles.button}
                        size="md"
                        disabled={isConfirmDisabled}
                        onClick={() => onConfirmAccept()}
                      >
                        {confirmAcceptText || t('action_popup:confirm')}
                      </MUIButton>

                    </div>

                    {isConfirmDisabled &&
                      <div className={ActionPopupStyles.disabledMessage}>{confirmDisabledText}</div>
                    }

                  </Fragment>
                }
              </div>
            </div>
          </CSSTransition>
        }
      </TransitionGroup>
    )

    return createPortal(actionPopup, document.querySelector('.App') || document.getElementById('root') || document.body)
  }
