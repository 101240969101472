import { MonetaryValueDTO } from 'models/misc/monetaryValue'
import { Nullable } from 'models/helpers'

/** Real estate property type */
export enum PropertyType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL'
}

export enum PropertyFloor {
  BASEMENT = 'BASEMENT',
  GROUND = 'GROUND',
  UPPER = 'UPPER',
  ATTIC = 'ATTIC',
}

/** Real estate property address */
export interface PropertyAddressDTO {
  /** Street name */
  street: string
  /** Building number */
  streetNumber: string
  /** Postal code */
  postalCode: string
  /** City */
  city: string
  /** Country */
  country: string
  /** Region code */
  regionCode: string
  /** GEO coordinates of the address */
  coordinates?: {
    lat: number
    lng: number
  }
}

export interface PropertyFloorInfo {
  floor: PropertyFloor
  description: string
}

/** Real estate property DTO. */
export interface RealEstatePropertyPurchaseFlowDTO {
  /** Type of property - COMMERCIAL or RESIDENTIAL. */
  propertyType?: Nullable<PropertyType>,
  address?: Nullable<PropertyAddressDTO>
}

export interface RealEstatePropertyDTO {
  id: string
  brokerId: string
  title: string
  externalInfo: string
  address: PropertyAddressDTO
  floorInfo: PropertyFloorInfo
  objectReferenceId: string
  propertyType: PropertyType
  price: MonetaryValueDTO
  workspaceId: string
  orderIds: Array<string>
}

export interface PageRealEstatePropertyListItemDTO {
  /** Total number of pages */
  totalPages: number
  /** Total number of elements */
  totalElements: number
  /** Size of the page */
  size: number
  /** List of real estate properties */
  content: RealEstatePropertyListItemDTO[]
  /** Current page number */
  number: number
  /** Sort object */
  sort: SortObject
  /** Is first page */
  first: boolean
  /** Is last page */
  last: boolean
  /** Number of elements */
  numberOfElements: number
  /** Pageable object */
  pageable: PageableObject
  /** Is empty */
  empty: boolean
}

export interface RealEstatePropertyListItemDTO {
  /** Unique identifier of the real estate property */
  id: string
  /** Title of the real estate property */
  title?: string
  /** Address of the real estate property */
  address: PropertyAddressDTO
  /** Real estate property reference id of the client integration */
  objectReferenceId?: string
}

export interface SortObject {
  /** Is empty */
  empty: boolean
  /** Is sorted */
  sorted: boolean
  /** Is unsorted */
  unsorted: boolean
}

export interface PageableObject {
  /** Page offset */
  offset: number
  /** Page sort object */
  sort: SortObject,
  /** Page size */
  pageSize: number,
  /** Page number */
  pageNumber: number,
  /** Is paged */
  paged: boolean,
  /** Is unpaged */
  unpaged: boolean,
}
