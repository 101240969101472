import { FC, KeyboardEvent, ReactNode, useCallback, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import { Badge } from '../Badges'
import Button from '../Button/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { KeyboardEventKey } from 'constants/misc'
import { Nullable } from 'models/helpers'
import classnames from 'classnames'
import isEmail from 'validator/lib/isEmail'
import styles from './MultiEmailInput.module.sass'
import { useTranslation } from 'react-i18next'

interface Props {
  emails: string[]
  /** Emails that have been added previously and cannot be added again */
  previouslyAddedEmails?: string[]
  autofocus?: boolean
  maxEmails?: number
  placeholder?: string
  fieldInfo?: string
  /** If true, emails left hanging in input will be ignored and deleted (default: false) */
  forceEmailConfirmation?: boolean
  onChange: (emails: string[]) => void
  /** Input component title */
  title?: ReactNode
  /** Email input with hint or not */
  withHint?: boolean
  /** Callback for custom validation of email in parent components, returns translation key for rendering custom validation error */
  onEmailValidation?: (inputString: string) => string | undefined
}

const recognizedSpecialKeys: Set<string> = new Set([
  KeyboardEventKey.ENTER,
  KeyboardEventKey.BACKSPACE,
  KeyboardEventKey.SPACE,
  KeyboardEventKey.COMMA,
  KeyboardEventKey.SEMICOLON
])

/**
 * Shows input to type/paste emails in and badges for all validated emails
 * 
 * @example
 * <MultiEmailInput
 *   maxEmails={5}
 *   emails={[]}
 *   onChange={(emails) => console.log(emails)}
 * />
 */
export const MultiEmailInput: FC<Props> = ({
  emails,
  previouslyAddedEmails,
  maxEmails = Number.POSITIVE_INFINITY,
  forceEmailConfirmation = false,
  autofocus = true,
  fieldInfo,
  placeholder,
  onChange,
  title,
  withHint = true,
  onEmailValidation
}) => {

  const { t } = useTranslation(['multi_email_input'])

  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState<Nullable<string>>(null)

  /** Handles email parsing and triggering onChange callback */
  const handleEmailParse = useCallback((inputString: string, isForceConfirm = false) => {
    setError(null)

    const trimmedString = inputString.trim()
    const existingEmails = new Set([...emails])
    const previouslyAddedEmailsSet = new Set(previouslyAddedEmails)

    // Is confirmed by ENTER/COMMA/SPACE/SEMI => should be one email only, paste is handled below
    if (isForceConfirm) {

      // ignore empty string, it is empty, don't kick the lying man
      if (!inputString) return

      if (!isEmail(trimmedString)) {
        setError(t('invalid_email'))
        return
      }

      if (existingEmails.has(trimmedString)) {
        setError(t('duplicated_email'))
        return
      }

      if (previouslyAddedEmailsSet.has(trimmedString)) {
        setError(t('previously_added_email'))
        return
      }

      if (existingEmails.size >= maxEmails) {
        setError(t('max_count'))
        return
      }

      if (!!onEmailValidation) {
        const errorMessageKey = onEmailValidation(inputString)

        if (errorMessageKey) {
          setError(t(errorMessageKey))
          return
        }
      }

      onChange(Array.from(new Set([...emails, trimmedString])))
      setInputValue('')
      return

    }

    const delimitersRegex = /[,; \r\t\n]/g

    // NO DELIMITERS FOUND - can be valid email, but not confirmed with a delimiter
    if (!delimitersRegex.test(trimmedString)) {
      setInputValue(trimmedString)
      return
    }

    // IS ENTIRE STRING EMAIL? (not expected, just in case)
    if (isEmail(trimmedString)) {
      onChange(Array.from(new Set([...emails, trimmedString])))
      setInputValue('')
      return
    }

    // Use Set to remove duplicities
    const resolvedEmails = new Set<string>([])

    // Split the string using delimiters
    const tokens = trimmedString.split(delimitersRegex)

    // Validate tokens and push valid emails into the Set
    for (let token of tokens) {
      const potentialEmail = token.trim()

      if (!isEmail(potentialEmail)) continue
      if (previouslyAddedEmailsSet.has(potentialEmail)) continue

      resolvedEmails.add(potentialEmail)
    }

    // No valid emails found
    if (!resolvedEmails.size) {
      setInputValue(trimmedString)
      setError(t('invalid_email'))
      return
    }

    const allEmails = Array.from(new Set([...emails, ...resolvedEmails]))

    if (allEmails.length > maxEmails) {
      setError(t('max_count'))
      allEmails.length = maxEmails
    }

    setInputValue('')
    onChange(allEmails)
  }, [emails, maxEmails, previouslyAddedEmails, onChange, t, onEmailValidation])

  /** Handles special key presses */
  const handleSpecialKeys = (e: KeyboardEvent<HTMLInputElement>) => {
    const key = e.key

    if (!recognizedSpecialKeys.has(key)) return
    if (key === KeyboardEventKey.BACKSPACE && inputValue !== '') return

    e.preventDefault()

    switch (key) {
      case KeyboardEventKey.BACKSPACE:
        const newEmails = [...emails]
        newEmails.pop()
        onChange(newEmails)
        break

      case KeyboardEventKey.ENTER:
      case KeyboardEventKey.COMMA:
      case KeyboardEventKey.SPACE:
      case KeyboardEventKey.SEMICOLON:
        handleEmailParse(inputValue, true)
        break

      default:
        return
    }
  }

  /** Removes email at specified index and triggers onChange callback with new email array */
  const handleDeleteAt = useCallback((deleteIndex: number) => {
    const newEmails = [...emails]
    newEmails.splice(deleteIndex, 1)
    onChange(newEmails)
  }, [emails, onChange])

  return (
    <div>
      {!!title && title}

      <div className={classnames(
        styles.inputWrap,
        { [styles.hasError]: !!error }
      )}>
        {/* Badges for existing validated emails */}
        {emails.map((email, index) => (
          <Badge key={email} className={styles.emailWrap} color="gray" type="fill">

            {email}

            <Button
              className={styles.deleteButton}
              type="secondary nobackground noborder"
              onClick={() => handleDeleteAt(index)}
            >
              <ClearIcon className={styles.deleteIcon} />
            </Button>

          </Badge>
        ))}

        <input
          id={styles.emailInput}
          className={classnames({ [styles.hasError]: !!error })}
          type="text"
          placeholder={placeholder || t('placeholder')}
          value={inputValue}
          autoFocus={autofocus}
          onBlur={_ => {
            if (forceEmailConfirmation) handleEmailParse('')
            else handleEmailParse(inputValue, true)
          }}
          onKeyDown={e => handleSpecialKeys(e)}
          onChange={e => handleEmailParse(e.target.value)}
        />
      </div>

      {(maxEmails !== Number.POSITIVE_INFINITY || !!error || !!fieldInfo) &&
        <div className={classnames(styles.extras, { [styles.hasError]: !!error })}>

          <span className={styles.info}>
            {error || fieldInfo}
          </span>

          {maxEmails !== Number.POSITIVE_INFINITY &&
            <span className={styles.counter}>
              {emails.length}/{maxEmails}
            </span>
          }

        </div>
      }

      {withHint &&
        <Stack marginTop={0.4}>
          <Typography variant="text-sm" fontWeight="regular">{t('multi_email_hint')}</Typography>
        </Stack>
      }

    </div>
  )
}
