import { Fragment, useCallback, useMemo } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowOrderMeta, usePurchaseFlowProducts, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { BaseInstructionCardPassDownProps } from '../instructionCards/BaseInstructionCard'
import { BookCreativeCard } from '../instructionCards/BookCreativeCard'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { FastDeliveryInstructionCard } from '../instructionCards/FastDeliveryInstructionCard'
import { InputWrap } from 'components/common/InputWrap'
import { InstructionOptionDTO } from 'models/purchaseFlow'
import { InstructionType } from 'constants/purchaseFlow'
import { KeyPickupCard } from '../instructionCards/KeyPickupCard'
import { MeetingInstructionsCard } from '../instructionCards/MeetingInstructionsCard'
import { ProductKind } from 'constants/product'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { Stack } from '@mui/material'
import { StepWrapper } from '../../StepWrapper'
import { SystemMessage } from 'components/common/SystemMessage'
import { bigFromFee } from 'utils/price'
import { isShootingCategory } from 'utils/validators'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether to disable selecting/deselecting */
  disabledSelection?: boolean
  /** Whether to disable filling in the option fields */
  disabledFields?: boolean
}

/**
 * Shows listing of instruction cards grouped by their instructionType ad allows user to select them.
 * 
 * Warning: In case of adding any further conditions in this file for SectionedBorderBox rendering, 
 * update also isInstructionStepSkipped in Purchase flow config context
 * 
 * DEPENDENCIES:
 * - InstructionStepStoreProvider
 * 
 * @example
 * <InstructionListing />
 */
export const InstructionListing: React.FC<Props> = ({
  disabledSelection = false,
  disabledFields = false,
}) => {
  const { t } = useTranslation(['purchase_flow', 'product', 'email_forwarding'])
  const { targetUser } = useTargetOrderUser()
  const { hasMeasurementProduct, selectedProducts } = usePurchaseFlowProducts()

  const {
    getInstructionCatalogue,
    extraServices,
    organizationInstructions,
    selectedCategory,
    isInstructionStepSkipped
  } = usePurchaseFlowConfig()

  const {
    shootingComments,
    setShootingComments,
  } = usePurchaseFlowOrderMeta()

  const isOnlyMeasurementsProduct = useMemo(() => Object.keys(selectedProducts).length === 1 && hasMeasurementProduct, [hasMeasurementProduct, selectedProducts])

  /** Function that assembles returns correct instruction option card */
  const getInstructionCard = useCallback((type: InstructionType, instruction: InstructionOptionDTO) => {
    const instructionTypeKey = type
    const optionKind = instruction.kind

    const baseProps: BaseInstructionCardPassDownProps = {
      instructionOption: instruction,
      instructionType: instructionTypeKey,
      isCardDisabled: disabledFields,
      isSelectionDisabled: disabledSelection,
    }

    switch (true) {
      case optionKind === ProductKind.FAST_DELIVERY:
        return (
          <FastDeliveryInstructionCard
            {...baseProps}
            isSelectionDisabled={disabledSelection || targetUser.isSubscribed}
          />
        )

      case optionKind === ProductKind.FAVOURITE_CREATIVE:
        return <BookCreativeCard {...baseProps} />

      case optionKind === ProductKind.KEYS_PICKUP:
        if (hasMeasurementProduct) return null
        return <KeyPickupCard {...baseProps} />

      case optionKind === ProductKind.MEETING_ON_SITE:
        if (hasMeasurementProduct) return null
        return (
          <MeetingInstructionsCard {...baseProps} />
        )

      case optionKind === ProductKind.ORGANIZATION_THIRD_PARTY:
        return (
          <MeetingInstructionsCard isCardPreselected={hasMeasurementProduct} {...baseProps} />
        )

      default: return null
    }
  }, [disabledFields, disabledSelection, hasMeasurementProduct, targetUser.isSubscribed])

  // In case of adding any further conditions in this file for SectionedBorderBox rendering, 
  // update also isInstructionStepSkipped in Purchase flow config context
  if (isInstructionStepSkipped) return null

  return (
    <StepWrapper
      title={t('instruction_step.section_title')}
      subtitle={t('instruction_step.section_description')}
    >
      <DynamicQueryContent query={getInstructionCatalogue}>
        <Stack gap={2}>

          {/* Choose favourite Creative */}
          {extraServices.FAVOURITE_CREATIVE && !isOnlyMeasurementsProduct &&
            <SectionedBorderBox title={t('instruction_step.book_creative')}>
              {getInstructionCard(InstructionType.CT_BOOKING, extraServices.FAVOURITE_CREATIVE)}
            </SectionedBorderBox>
          }

          {/* Organization */}
          {Object.values(organizationInstructions).length > 0 &&
            <SectionedBorderBox title={t('instruction_step.organization')}>
              <Stack gap={2}>
                {Object.values(organizationInstructions)
                  .sort((instructionA, instructionB) => bigFromFee(instructionA.feePrice).minus(bigFromFee(instructionB.feePrice)).toNumber())
                  .map((instruction) => (
                    <Fragment key={instruction.id}>
                      {getInstructionCard(InstructionType.ORGANIZATION, instruction)}
                    </Fragment>
                  ))
                }
              </Stack>
            </SectionedBorderBox>
          }

          {/* Other services */}
          {extraServices.FAST_DELIVERY && !isOnlyMeasurementsProduct &&
            <SectionedBorderBox title={t('instruction_step.other_services')}>
              {getInstructionCard(InstructionType.DELIVERY, extraServices.FAST_DELIVERY)}
            </SectionedBorderBox>
          }

          {/* Briefing comments */}
          {!!selectedCategory && isShootingCategory(selectedCategory) && !isOnlyMeasurementsProduct &&
            <SectionedBorderBox title={t('instruction_step.order_info')}>
              <Stack gap={.5}>

                <>
                  <InputWrap label={''}>
                    <textarea placeholder={t('instruction_step.briefing_comments_textarea_placeholder')} value={shootingComments} onChange={(e) => setShootingComments(e.target.value)}></textarea>
                  </InputWrap>

                  <SystemMessage message={t('instruction_step.briefing_comments_fieldNote')} variant='warning' />
                </>

              </Stack>
            </SectionedBorderBox>
          }

        </Stack>
      </DynamicQueryContent>
    </StepWrapper>
  )
}
