import { PropertiesDashboardController } from './PropertiesDashboard.controller'
import React from 'react'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'

/**
 * Page representing the overall client properties dashboard content.
 *
 * @example <PropertiesDashboard />
*/
export const PropertiesDashboard: React.FC = () => {

  return (
    <ThemeProvider theme={redesignMUITheme}>

      <PropertiesDashboardController />

    </ThemeProvider>
  )
}
