import { BEIGE_600, BOX_SHADOW_XS, GRAY_700, WHITE } from 'constants/styling/theme'
import { FC, ReactNode } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import { propertiesTableHead } from 'constants/propertiesDashboard'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** Custom table row in the table body. */
  children?: ReactNode
}

/**
 * @component Properties dashboard table
 * @example
 * <PropertiesDashboardTable />
 */
export const PropertiesDashboardTable: FC<Props> = ({ children }) => {
  const { t } = useTranslation(['properties_dashboard'])

  return (
    <Table
      size="medium"
      sx={{
        borderRadius: '.8rem',
        backgroundColor: WHITE,
        border: `.1rem solid ${BEIGE_600}`,
        boxShadow: BOX_SHADOW_XS
      }}
    >

      <TableHead sx={{ backgroundColor: BEIGE_600 }}>
        <TableRow>
          {propertiesTableHead.map(title => (
            <TableCell key={title} sx={{ '&:last-child': { textAlign: 'right' } }}>
              <Typography variant="text-xs" fontWeight={600} color={GRAY_700}>{t(`properties_table.${title}`)}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {children}
      </TableBody>

    </Table>
  )
}
