import { Box, IconButton, Stack, Typography } from '@mui/material'
import { CORAL_600, GRAY_900 } from 'constants/styling/theme'
import { useCallback, useState } from 'react'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import { MUIButton } from '../MUIButton'
import { MultiEmailInput } from '../MultiEmailInput'
import { useTranslation } from 'react-i18next'

/** @interface Props for the EmailForwardning component. */
interface Props {
  /** List of emails to be forwarded. */
  ccEmails: string[]
  /** Handle action click triggered after the emails is added */
  onChange: (emails: string[]) => void
  /** Handle action click triggered for deleting. */
  onDelete: (email: string) => void
}

/**
 * @component
 * Emails forwarding component.
 * 
 * @example
 * <EmailForwardning
 *   ccEmails={ccEmails}
 *   onChange={() => console.log('updated')}
 *   onDelete={() => console.log('removed')}
 * />
 */
export const EmailForwardning: React.FC<Props> = ({
  ccEmails,
  onChange,
  onDelete
}) => {
  const { t } = useTranslation(['email_forwarding'])
  const [emails, setEmails] = useState<string[]>([])

  const handleAddEmails = useCallback(() => {
    onChange([...ccEmails, ...emails])
    setEmails([])
  }, [ccEmails, emails, onChange])

  return (
    <>
      <Stack flexDirection="row" gap="2rem" justifyContent="flex-start" alignItems="flex-start" paddingTop=".4rem">

        <Box width="100%">
          <MultiEmailInput
            emails={emails}
            autofocus={false}
            withHint={false}
            placeholder={t('placeholder')}
            onChange={(emails) => setEmails(emails)}
          />
        </Box>

        <Box paddingTop=".6rem" flexShrink={0}>
          <MUIButton
            onClick={handleAddEmails}
            disabled={!emails.length}
          >
            {t('add')}
          </MUIButton>
        </Box>
      </Stack>

      <Stack gap='.4rem' justifyContent="flex-start" paddingTop=".4rem">
        {ccEmails.map((email) => (
          <Stack key={email} flexDirection="row" gap=".2rem" justifyContent="flex-start" alignItems="center">
            <IconButton
              size='medium'
              onClick={() => onDelete(email)}
            >
              <DeleteOutlineRoundedIcon sx={{ color: CORAL_600, fontSize: 18 }} />
            </IconButton>

            <Typography variant="text-sm" color={GRAY_900}>{email}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  )
}
