import { loadPurchaseSessionVisualWatcher, uploadPurchaseSessionVisualWatcher } from '../Individual/Visual/LoadPurchaseSessionVisual'
import { loadVisualWatcher, uploadVisualWatcher } from '../Individual/Visual/LoadVisual'

import { addWorkspaceMembersWatcher } from 'redux/Individual/Workspace/AddWorkspaceMembers'
import { all } from 'redux-saga/effects'
import { archiveEditingVisualsWatcher } from 'redux/Individual/VisualsEditing/ArchivedEditingVisuals'
import { archiveVisualsWatcher } from 'redux/Individual/Visual/ArchiveVisuals/ArchiveVisuals.sagas'
import { automateOrderWatcher } from 'redux/Individual/Order/AutomateOrder/automateOrder.sagas'
import { changeLocaleWatcher } from '../Individual/User/ChangeLocale'
import { createWorkspaceSubscriptionWacher } from 'redux/Individual/Workspace/CreateWorkspaceSubscription'
import { createWorkspaceWacher } from 'redux/Individual/Workspace/createWorkspace'
import { deleteVirtualVisitWatcher } from '../Individual/Visual/DeleteVirtualVisit'
import { downloadArchiveWatcher } from '../Individual/Visual/DownloadArchive'
import { editMissionAdminCommentsForEditorWatcher } from 'redux/Individual/Admin/EditMissionAdminCommentsForEditor'
import { editMissionCreativeInstructionsFromAdminWatcher } from 'redux/Individual/Admin/EditMissionCreativeInstructionsFromAdmin'
import { editMissionInternalCommentsWatcher } from 'redux/Individual/Admin/EditMissionInternalComments'
import { extendVirtualVisitWatcher } from '../Individual/Visual/ExtendVirtualVisitHosting'
import { getSubscriptionCheckoutLinkWatcher } from 'redux/Individual/Subscriptions/GetCheckoutLink'
import { getUpsellPaymentIntentWatcher } from 'redux/Individual/Mission/GetUpsellPaymentIntent'
import { getUserWorkspacesWatcher } from 'redux/Individual/User/GetUserWorkspaces'
import { listPurchaseSessionVisualsWatcher } from '../Individual/Visual/ListPurchaseSessionVisuals'
import { listVisualsWatcher } from '../Individual/Visual/ListVisuals'
import { loadWatermarkLogoWatcher } from 'redux/Individual/VisualsEditing/LoadWatermarkLogo'
import { processLanguageChangeWatcher } from '../Individual/User/ProcessLanguageChange'
import { searchOrganizationsWatcher } from 'redux/Individual/Organization/SearchOrganizations'
import { selectVisualsForStagingWatcher } from 'redux/Individual/Visual/SelectVisualsForStaging'
import { selectVisualsWatcher } from '../Individual/Visual/SelectVisuals'
import { stopAutomatedOrderWatcher } from 'redux/Individual/Order/StopAutomatedOrder/stopAutomatedOrder.sagas'
import { updatePreferredPaymentMethodWatcher } from 'redux/Individual/User/UpdatePreferredPaymentMethod'
import { updateUserMeWatcher } from 'redux/Individual/User/UpdateUserMe'
import { uploadWatermarkLogoWatcher } from 'redux/Individual/VisualsEditing/UploadWatermarkLogo'
import { userMeAlternativeWatcher } from '../Individual/User/UserMeAlternative'
import { userMeWatcher } from '../Individual/User/UserMe'
import { userMetaWatcher } from '../Individual/User/UserMeta'

/** The root saga */
export function* rootSaga() {
  yield all([
    loadVisualWatcher(),
    uploadVisualWatcher(),
    loadPurchaseSessionVisualWatcher(),
    uploadPurchaseSessionVisualWatcher(),
    listVisualsWatcher(),
    listPurchaseSessionVisualsWatcher(),
    selectVisualsWatcher(),
    userMeWatcher(),
    updateUserMeWatcher(),
    userMeAlternativeWatcher(),
    userMetaWatcher(),
    downloadArchiveWatcher(),
    changeLocaleWatcher(),
    processLanguageChangeWatcher(),
    editMissionCreativeInstructionsFromAdminWatcher(),
    editMissionAdminCommentsForEditorWatcher(),
    editMissionInternalCommentsWatcher(),
    deleteVirtualVisitWatcher(),
    extendVirtualVisitWatcher(),
    getUpsellPaymentIntentWatcher(),
    archiveVisualsWatcher(),
    updatePreferredPaymentMethodWatcher(),
    searchOrganizationsWatcher(),
    selectVisualsForStagingWatcher(),
    getSubscriptionCheckoutLinkWatcher(),
    createWorkspaceWacher(),
    getUserWorkspacesWatcher(),
    addWorkspaceMembersWatcher(),
    createWorkspaceSubscriptionWacher(),
    automateOrderWatcher(),
    stopAutomatedOrderWatcher(),
    loadWatermarkLogoWatcher(),
    uploadWatermarkLogoWatcher(),
    archiveEditingVisualsWatcher(),
  ])
}
