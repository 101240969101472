import { AddMembers, CompleteSubscription, PlanCreation } from 'components/pages/SubscriptionFlow/Steps'
import { AdministratorGallery, AdministratorGalleryController } from 'components/pages/Gallery/_main/AdministratorGallery/_main'
import { CreativeGallery, CreativeGalleryController } from 'components/pages/Gallery/_main/CreativeGallery'
import { Navigate, Route, useLocation } from 'react-router-dom'
import { TestGallery, TestGalleryController } from 'components/pages/Gallery/TestGallery'

import APIDebug from 'components/pages/APIDebug/APIDebug'
import { AvailableAssignmentPage } from 'components/pages/Creative/AvailableAssignment'
import { ClientGallery } from 'components/pages/Gallery/_main/ClientGallery/_main'
import { ClientGalleryController } from 'components/pages/Gallery/_main/ClientGallery/_main/ClientGallery.controller'
import ClientOnboarding from 'components/pages/Administrator/ClientOnboarding/ClientOnboarding'
import CreativeOnboarding from 'components/pages/Administrator/CreativeOnboarding/CreativeOnboarding'
import EmailVerified from 'components/pages/General/EmailVerified/EmailVerified'
import { FC } from 'react'
import { FeatureFlag } from 'utils/featureFlags'
import { GalleryStagingFlow } from 'components/pages/Gallery/pages/GalleryStagingFlow'
import Index from 'components/pages/Index/Index'
import LoadingPage from 'components/pages/General/LoadingPage/LoadingPage'
import Login from 'components/pages/General/Login/Login'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { Path } from 'constants/router'
import { PaymentStatusPage } from 'components/pages/PaymentStatus'
import { PricingPage } from 'components/pages/Client/Pricing/_main/Pricing.page'
import { PrivateRoute } from '../PrivateRoute/PrivateRoute'
import { Profile } from 'components/pages/Profile'
import { Property } from 'components/pages/Property/_main'
import { PropertyController } from 'components/pages/Property/_main/Property.controller'
import { PurchaseFlow } from 'components/pages/PurchaseFlow'
import { SentryRoutes } from 'utils/analytics'
import Signup from 'components/pages/General/Signup/Signup'
import { SignupPortal } from 'components/pages/General/SignupPortal'
import SignupRedirect from 'components/pages/General/SignupRedirect/SignupRedirect'
import { SignupRegistration } from 'components/pages/General/SignupRegistration'
import { SubscriptionFlowPage } from 'components/pages/SubscriptionFlow'
import { SubscriptionStatusPage } from 'components/pages/SubscriptionStatus'
import { TermsAndPrivacyPage } from 'components/pages/TermsAndPrivacy'
import UnverifiedUser from 'components/pages/General/UnverifiedUser/UnverifiedUser'
import { VisualPinturaEditing } from 'components/pages/VisualPinturaEditing'
import { VisualsEditing } from 'components/pages/VisualsEditing'
import { VisualsMarketing } from 'components/pages/VisualsMarketing'
import { WorkspacesProfilePage } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.page'
import { useAppMeta } from '../App/appMeta.context'
import { useAuth0 } from 'utils/auth'
import { useFlag } from '@unleash/proxy-client-react'

export const RouteSwitch: FC = () => {

  const { roles, isAuthenticated } = useAuth0()
  const location = useLocation()
  const allowSignupRedesign = useFlag(FeatureFlag.ALLOW_SIGNUP_REDESIGN)
  const {
    isLoading,
    isUserVerified,
    isUserSet,
  } = useAppMeta()

  const { pathname } = location

  if (isLoading) return <LoadingPage />
  if (!isUserVerified && pathname === Path.EMAIL_VERIFIED) return <EmailVerified />
  if (!isUserVerified) return <UnverifiedUser />
  if (!isUserSet) return allowSignupRedesign ? <SignupRegistration /> : <Signup />

  return (
    <SentryRoutes>
      <Route
        path={Path.INDEX}
        element={<Index />}
      />
      {/* -- GALLERY CLUSTER -- */}
      {roles.isClient &&
        <>
          <Route
            path={Path.GALLERY_NO_SLUG}
            element={<ClientGallery />}
          >
            <Route
              path={Path.GALLERY}
              element={
                <PrivateRoute component={ClientGalleryController} />
              }
            />
            <Route
              path={Path.GALLERY_INFO}
              element={
                <PrivateRoute component={ClientGalleryController} />
              }
            />
            <Route
              path={Path.GALLERY_STAGING}
              element={<PrivateRoute component={GalleryStagingFlow} />}
            />
            <Route
              path={Path.GALLERY_VISUALS_EDIT}
              element={
                <PrivateRoute component={VisualsEditing} />
              }
            />
            <Route
              path={Path.GALLERY_VISUAL_PINTURA_EDITING} // Pintura editing integration page
              element={
                <PrivateRoute component={VisualPinturaEditing} />
              }
            />
            <Route
              path={Path.GALLERY_VISUALS_MARKETING}
              element={
                <PrivateRoute component={VisualsMarketing} />
              }
            />
          </Route>
        </>
      }

      {roles.isAdmin &&
        <>
          <Route
            path={Path.GALLERY_NO_SLUG}
            element={<AdministratorGallery />}
          >
            <Route
              path={Path.GALLERY}
              element={
                <PrivateRoute component={AdministratorGalleryController} />
              }
            />
          </Route>
        </>
      }

      {roles.isCreative &&
        <>
          <Route
            path={Path.GALLERY_NO_SLUG}
            element={<CreativeGallery />}
          >
            <Route
              path={Path.GALLERY}
              element={
                <PrivateRoute component={CreativeGalleryController} />
              }
            />
          </Route>
        </>
      }

      {roles.isAdmin &&
        <>
          <Route
            path={Path.GALLERY_NO_SLUG_TEST}
            element={<TestGallery />}
          >
            <Route
              path={Path.GALLERY_TEST}
              element={
                <PrivateRoute component={TestGalleryController} />
              }
            />
          </Route>
        </>
      }
      {/* -- END OF GALLERY CLUSTER -- */}

      {/* -- PROPERTY CLUSTER -- */}
      {roles.isClient &&
        <>
          <Route
            path={Path.PROPERTY_NO_SLUG}
            element={<Property />}
          >
            <Route
              path={Path.PROPERTY_DETAIL}
              element={
                <PrivateRoute component={PropertyController} />
              }
            />
          </Route>
        </>
      }
      {/* -- END OF PROPERY CLUSTER -- */}

      {/* -- SUBSCRIPTION FLOW CLUSTER -- */}
      {/* clients only */}
      <Route
        path={Path.SUBSCRIPTION}
        element={!isAuthenticated || roles.isClient
          ? <PrivateRoute component={SubscriptionFlowPage} />
          : <NotFound />
        }
      >
        <Route
          index
          element={<Navigate to={Path.SUBSCRIPTION_CREATION} />}
        />
        <Route
          path={Path.SUBSCRIPTION_CREATION}
          element={
            <PrivateRoute component={PlanCreation} />
          }
        />
        <Route
          path={Path.SUBSCRIPTION_MEMBERS}
          element={
            <PrivateRoute component={AddMembers} />
          }
        />
        <Route
          path={Path.SUBSCRIPTION_VALIDATION}
          element={
            <PrivateRoute component={CompleteSubscription} />
          }
        />
        <Route
          path='*'
          element={
            <Navigate to={Path.SUBSCRIPTION} />
          }
        />
      </Route>
      {/* -- END OF SUBSCRIPTION FLOW CLUSTER -- */}
      {/* -- PURCHASE FLOW CLUSTER -- */}
      <Route
        path={Path.PURCHASE_FLOW}
        element={
          <PrivateRoute component={PurchaseFlow} />
        }
      />
      <Route
        path={`${Path.PURCHASE_FLOW}/*`}
        element={<Navigate to={Path.PURCHASE_FLOW} />}
      />
      {/* -- END OF PURCHASE FLOW CLUSTER -- */}
      <Route
        path={Path.PROFILE}
        element={
          <PrivateRoute component={Profile} />
        }
      />
      <Route
        path={Path.WORKSPACES_PROFILE}
        element={
          <PrivateRoute component={WorkspacesProfilePage} />
        }
      />
      <Route
        path={Path.PRICING}
        element={
          <PrivateRoute component={PricingPage} />
        }
      />
      <Route
        path="/API"
        element={
          <PrivateRoute component={APIDebug} />
        }
      />
      <Route
        path={Path.ADMIN_CLIENT_ONBOARDING}
        element={
          <PrivateRoute component={ClientOnboarding} />
        }
      />
      <Route
        path={Path.ADMIN_CREATIVE_ONBOARDING}
        element={
          <PrivateRoute component={CreativeOnboarding} />
        }
      />
      <Route
        path={Path.PAYMENT_STATUS}
        element={
          <PrivateRoute component={PaymentStatusPage} />
        }
      />
      <Route
        path={Path.SUBSCRIPTION_STATUS}
        element={
          <PrivateRoute component={SubscriptionStatusPage} />
        }
      />
      <Route
        path={Path.AVAILABLE_ASSIGNMENT}
        element={
          <PrivateRoute component={AvailableAssignmentPage} />
        }
      />
      <Route
        path={Path.LOGIN}
        element={<Login />}
      />
      <Route
        path={Path.SIGNUP}
        element={<SignupRedirect />}
      />
      <Route
        path={Path.SIGNUP_PORTAL}
        element={<SignupPortal />}
      />
      <Route
        path={Path.EMAIL_VERIFIED}
        element={<EmailVerified />}
      />
      <Route
        path={Path.EMAIL_VERIFICATION}
        element={<UnverifiedUser />}
      />
      <Route
        path={Path.TERMS_AND_PRIVACY}
        element={<TermsAndPrivacyPage />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </SentryRoutes>
  )
}
