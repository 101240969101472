import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import React, { useState } from 'react'

import { GRAY_900 } from 'constants/styling/theme'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUINumberField } from 'components/common/MUINumberField'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { WindowType } from 'constants/purchaseFlow'
import { useConstructionMethod } from './_main/ConstructionMethod.context'
import { useTranslation } from 'react-i18next'

/**
 * @component Window renders options for the user to select the year of manufacture and type of windows.
 *
 * @example
 *   <Window />
 */
export const Window: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    yearOfManufacture,
    setYearOfManufacture,
    windowType,
    setWindowType
  } = useConstructionMethod()

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox
      title={
        < Typography variant='text-md' fontWeight={600} color={GRAY_900} >
          {t('windows_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.2} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          {/* YEAR OF MANUFACTURE */}
          <MUINumberField
            min={0}
            required
            inputBoxWidth='100%'
            label={t('year_of_manufacture')}
            value={yearOfManufacture}
            isError={showError && !yearOfManufacture}
            onChange={(value) => setYearOfManufacture(value ?? 0)}
            onBlur={handleOnInputBlur}
          />

          {/* WINDOW TYPE */}
          <Stack gap="1.8rem" width={{ xs: '100%', lg: '32rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  label={t('window_field')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!windowType ? `window_type.${windowType}` : 'select_window_option')}
                  onClick={action}
                />
              )}
            >
              {Object.values(WindowType).map(type => (
                <MUIDropdownItem key={type} onClick={() => setWindowType(type)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`window_type.${type}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Stack>

        </Stack>
      </Stack>
    </SectionedBorderBox>
  )
}
