import { CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { DescriptionOutlined, ErrorOutlineOutlined } from '@mui/icons-material'
import { FC, useCallback, useMemo } from 'react'
import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'

import { Color } from 'constants/assets'
import { DocumentUploadType } from 'constants/documents'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileState } from 'components/common/FileAPI'
import GalleryImage from 'components/common/GalleryImage/GalleryImage'
import saveAs from 'file-saver'
import styles from './DocumentGalleryContent.module.sass'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryDocumentsContext } from 'components/pages/Gallery/_main/contexts/GalleryDocuments.context'
import { useTranslation } from 'react-i18next'

interface Props {
  /** What upload type is being handled - INPUT / OUTPUT */
  documentUploadType: DocumentUploadType
}

/**
 * Displays upload area for documents and listing of uploaded ones
 * @example <DocumentGalleryContent />
 */
export const DocumentGalleryContent: FC<Props> = ({ documentUploadType }) => {
  const { t } = useTranslation('file_upload_item')
  const { isAssignmentSubmittedByCT } = useGalleryAssignment()
  const { galleryDocumentsFileController, assignmentDocuments } = useGalleryDocumentsContext()
  const { roles } = useAuth0()

  const galleryDocumentFiles = useMemo(() => {
    // If no files are present, return empty array
    if (Object.keys(galleryDocumentsFileController.filesByTag).length === 0) return []

    // If files are present, return files by upload type
    return galleryDocumentsFileController.filesByTag[documentUploadType] ?? []
  }, [documentUploadType, galleryDocumentsFileController.filesByTag])

  const handleDownloadDocument = useCallback((fileUrl: string, fileName: string) => {
    if (!fileUrl || !fileName) return
    saveAs(fileUrl, fileName)
  }, [])

  return (
    <Stack gap={2}>

      {(!isAssignmentSubmittedByCT || roles.isAdmin) &&
        <FileAPIUpload
          files={galleryDocumentsFileController.filesByTag[documentUploadType] ?? []}
          acceptedFileTypes={[...supportedDocumentFileTypes, ...supportedImageFileTypes]}
          disabled={false}
          showFileListing={false}
          onDelete={(fileId) => galleryDocumentsFileController.deleteFiles([fileId])}
          uploadHandler={(files) => {
            galleryDocumentsFileController.uploadFiles(files, {
              tag: documentUploadType,
              onSettled: () => assignmentDocuments.refetch()
            })
          }}
        />
      }

      {/* Gallery image repurposed as listing */}
      <Grid container spacing={1}>
        {!!galleryDocumentFiles.length && galleryDocumentFiles.map((file) => (
          <Grid item key={file.id} xs={12} sm={6} md={4} lg={2}>
            <GalleryImage
              className={styles.galleryImage}
              label={file.originalFilename || file.gcFilename || ''}
              deletable={!isAssignmentSubmittedByCT || roles.isAdmin}
              droppable={!isAssignmentSubmittedByCT || roles.isAdmin}
              downloadable={!isAssignmentSubmittedByCT || roles.isAdmin}
              onDelete={() => galleryDocumentsFileController.deleteFiles([file.id])}
              onDownload={() => handleDownloadDocument(file.displayUrl ?? '', file.gcFilename ?? '')}
              onDrop={(acceptedFiles) => {
                galleryDocumentsFileController.reUploadFile(acceptedFiles[0], file.id, {
                  tag: documentUploadType,
                  onSuccess: () => assignmentDocuments.refetch()
                })
              }}
            >

              {file.state === FileState.ERROR &&
                <ErrorOutlineOutlined fontSize="large" color="error" />
              }

              {[FileState.SUCCESS, FileState.IDLE].includes(file.state) &&
                <DescriptionOutlined fontSize="large" />
              }

              {file.state === FileState.RUNNING &&
                <Stack gap={1} alignItems="center">

                  <Typography variant="text-md" fontWeight={600} color="GrayText">
                    {t(`${file.action}.action_word`)}
                  </Typography>

                  <CircularProgress
                    variant={file.progress ? 'determinate' : 'indeterminate'}
                    value={file.progress}
                    size={30}
                    thickness={5}
                    sx={{
                      color: Color.GRAY_TEXT,
                      borderRadius: '50%',
                      // Background ring for unfilled progress
                      boxShadow: `inset 0 0 0px 3px ${Color.GRAY_BORDER}`
                    }}
                  />
                </Stack>
              }

            </GalleryImage>
          </Grid>
        ))}
      </Grid>

    </Stack>
  )
}
