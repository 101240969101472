import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { PageRealEstatePropertyListItemDTO, RealEstatePropertyDTO, RealEstatePropertyListItemDTO } from 'models/property'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import { AxiosError } from 'axios'
import { PropertyEndpoint } from 'constants/API'
import { Nullable } from 'models/helpers'
import { PageableResponse } from 'models/misc'
import { useMemo } from 'react'
import { useAPI } from 'utils/API'

// -- QUERIES
export function useGetPropertyDetail(propertyId: Nullable<string>, workspaceId: Nullable<string>, allowRetry = true) {
  const api = useAPI<PropertyEndpoint>()

  return useQuery<RealEstatePropertyDTO, AxiosError>({
    queryKey: [EntityKeys.PROPERTY, QueryType.GET_ONE, { propertyId: propertyId!, workspaceId: workspaceId! }],
    queryFn: () => api.get<RealEstatePropertyDTO>(
      PropertyEndpoint.GET_PROPERTY_DETAIL,
      {
        workspaceId: workspaceId!,
        propertyId: propertyId!,
      },
      false
    ),
    enabled: !!propertyId && !!workspaceId,
    retry: allowRetry,
  })
}

export function useGetPropertyDetailWithObjectReferenceId(objectReferenceId: Nullable<string>, workspaceId: Nullable<string>, allowRetry = true) {
  const api = useAPI<PropertyEndpoint>()

  return useQuery<RealEstatePropertyDTO, AxiosError>({
    queryKey: [EntityKeys.PROPERTY, QueryType.GET_ONE, { objectReferenceId: objectReferenceId!, workspaceId: workspaceId! }],
    queryFn: () => api.get<RealEstatePropertyDTO>(
      PropertyEndpoint.GET_PROPERTY_DETAIL_WITH_OBJECT_REFERENCE,
      {
        workspaceId: workspaceId!,
        objectReferenceId: objectReferenceId!,
      },
      false
    ),
    enabled: !!objectReferenceId && !!workspaceId,
    retry: allowRetry,
  })
}

export interface PropertyListParams {
  searchText: string,
  page?: number
  size?: number
}

const defaultParams: PropertyListParams = {
  searchText: '',
  page: 0,
  size: 10,
}

const _serializeParams = (params: PropertyListParams) => {

  const finalParams = {
    ...defaultParams,
    ...params,
  }

  return {
    searchText: finalParams.searchText,
    page: (finalParams.page || 0).toString(),
    size: (finalParams.size || 25).toString(),
  }
}

export const useInfiniteListProperties = (workspaceId: string, params: PropertyListParams) => {
  const api = useAPI<PropertyEndpoint>()

  const serializedParams = useMemo(() => _serializeParams(params), [params])

  const fetchFnc = (page: number) => api.get<PageableResponse<RealEstatePropertyListItemDTO[]>>(
    PropertyEndpoint.GET_SEARCH_PROPERTY,
    { workspaceId },
    true,
    {
      params: {
        ...serializedParams,
        page,
      }
    }
  )

  return useInfiniteQuery({
    queryKey: [EntityKeys.PROPERTY, QueryType.INFINITE_LIST, { ...serializedParams }],
    queryFn: ({ pageParam }) => fetchFnc(pageParam),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => !lastPage.data.last ? lastPage.data.pageable.pageNumber + 1 : undefined,
  })
}

export function useGetSearchProperty(workspaceId: string, params: PropertyListParams) {
  const api = useAPI<PropertyEndpoint>()

  const serializedParams = useMemo(() => _serializeParams(params), [params])

  return useQuery({
    queryKey: [EntityKeys.PROPERTY, QueryType.LIST, { workspaceId }],
    queryFn: () => api.get<PageRealEstatePropertyListItemDTO>(
      PropertyEndpoint.GET_SEARCH_PROPERTY,
      { workspaceId },
      true,
      {
        params: {
          ...serializedParams
        },
      }
    ),
    enabled: false
  })
}
