import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import React, { useEffect } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import { Basement } from '../Basement.component'
import { Floor } from '../Floor.component'
import { Roof } from '../Roof.component'
import { Shape } from '../Shape.component'
import Stack from '@mui/material/Stack'
import { ThermalBridges } from '../ThermalBridges.component'
import { Window } from '../Window.component'

/**
 * @component
 * Content for the ConstructionMethod tab in the EnergyCertificate component.
 *
 * @example
 * <ConstructionMethod />
 */
export const ConstructionMethod: React.FC = () => {

  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  const { selectedCategory } = usePurchaseFlowConfig()

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.OPENS_CONSTRUCTION_METHOD, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })
    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack gap={1.6}>

      {/* SHAPE SECTION */}
      <Shape />

      {/* ROOF SECTION */}
      <Roof />

      {/* FLOORS SECTION */}
      <Floor />

      {/* BASEMENT SECTION */}
      <Basement />

      {/* WINDOWS SECTION */}
      <Window />

      {/* THERMAL BRIDGE TYPE OPTIONS */}
      <ThermalBridges />

    </Stack>
  )
}
