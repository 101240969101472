import { FC, useCallback, useMemo } from 'react'
import { bigFromDecimal, decimalFromBig } from 'utils/price'

import { FeeDTO } from 'models/fee'
import { GalleryStagingFlowStatus } from '../GalleryStagingFlowStatus'
import { Nullable } from 'models/helpers'
import { StripeError } from '@stripe/stripe-js'
import { UpsellPayment } from '../../../common'
import { ZERO_DECIMAL } from 'constants/misc'
import { useClientGallery } from '../../../_main/ClientGallery/_main'
import { useGalleryStagingFlow } from '../_main/contexts'
import { useRenovationProducts } from 'components/common/StagingFlow/main/contexts'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

/**
 * Gallery staging flow upsell payment component for renovation products.
 * @example <GalleryStagingFlowUpsell />
 */
export const GalleryStagingFlowUpsell: FC = () => {
  const { t } = useTranslation(['gallery_staging_flow'])
  const {
    assignmentId,
  } = useClientGallery()

  const {
    stagingRequest,
    stagingRequestState,
    roomsWithRenovationProducts,
    isAdvanceStagingPurchaseModalOpen,
    onStageVisuals,
    setIsAdvanceStagingPurchaseModalOpen,
  } = useGalleryStagingFlow()

  const { renovationCartProduct } = useRenovationProducts()
  const { spawnErrorToast } = useSnackbar()
  const handleAfterPurchaseModalClose = useCallback((stripeError: StripeError | null) => {
    if (stripeError) {
      spawnErrorToast(t('payment_error'), { subtext: stripeError.message })
    }
  }, [spawnErrorToast, t])

  const totalPrice: Nullable<FeeDTO> = useMemo(() => {
    if (!renovationCartProduct) return null
    const currency = renovationCartProduct.feePrice.currency
    const pricePerRenovationProduct = bigFromDecimal(renovationCartProduct.feePrice.value || 0)

    return ({
      currency,
      discountPercentage: ZERO_DECIMAL,
      value: decimalFromBig(pricePerRenovationProduct),
    })
  }, [renovationCartProduct])

  return (
    <UpsellPayment
      isOpen={isAdvanceStagingPurchaseModalOpen}
      assignmentId={assignmentId}
      productId={renovationCartProduct?.id}
      productQuantity={roomsWithRenovationProducts.size}
      total={totalPrice}
      beforeConfirmAction={(paymentIntentId, billingProductId) =>
        onStageVisuals({ paymentIntentId, billingProductId })
      }
      beforeConfirmActionState={stagingRequestState}
      beforeConfirmationActionRequest={stagingRequest}
      onClose={() => setIsAdvanceStagingPurchaseModalOpen(false)}
      afterClosed={handleAfterPurchaseModalClose}
      successNode={
        <GalleryStagingFlowStatus status={stagingRequestState} />
      }
    >
    </UpsellPayment>
  )
}
