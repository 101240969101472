import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics/analyticsUtils'
import { BEIGE_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Box, Fade } from '@mui/material'
import React, { useEffect } from 'react'
import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import { BuildingLayoutWalls } from '../BuildingLayoutWalls.component'
import { BuildingOrientation } from '../../../../common/BuildingOrientation/BuildingOrientation.component'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { GlobalShapeType } from 'constants/purchaseFlow'
import LShapeImg from 'assets/img/energy_certificates/L_shape.webp'
import { Label } from 'components/common/Label'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SimpleImage } from 'components/common/SimpleImage'
import Stack from '@mui/material/Stack'
import TShapeImg from 'assets/img/energy_certificates/T_shape.webp'
import { ToggleButton } from 'components/common/ToggleButton'
import Typography from '@mui/material/Typography'
import UShapeImg from 'assets/img/energy_certificates/U_shape.webp'
import rectangleImg from 'assets/img/energy_certificates/rectangle.webp'
import { useBuildingLayout } from './BuildingLayout.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Content for the BuildingLayout tab in the EnergyCertificate component.
 *
 * @example
 * <BuildingLayout />
 */
export const BuildingLayout: React.FC = () => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.building_layout_page' })

  const {
    globalShapeType,
    setGlobalShapeType,
    buildingShapeUpload,
    orientationType,
    setOrientationType,
  } = useBuildingLayout()

  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  const { selectedCategory } = usePurchaseFlowConfig()

  const getImageUrl = (globalShapeType: GlobalShapeType): string => {
    switch (globalShapeType) {
      case GlobalShapeType.L_SHAPE:
        return LShapeImg
      case GlobalShapeType.U_SHAPE:
        return UShapeImg
      case GlobalShapeType.T_SHAPE:
        return TShapeImg
      default:
        return rectangleImg
    }
  }

  const isGlobalShapeTypeOther = globalShapeType === GlobalShapeType.OTHER


  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.OPENS_BUILDING_LAYOUT, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })
    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack>

      <SectionedBorderBox
        title={
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('building_shape.building_shape_title')}
          </Typography>
        }
      >
        <Stack
          sx={{
            flexDirection: { xs: 'column', lg: 'row' }
          }}
          spacing={2}
          width="100%"
        >

          <Stack alignItems="flex-start" gap={1.2} width={{ xs: '100%', sm: '50%' }} marginBottom={{ xs: '2.8rem', sm: 0 }}>

            <Label color={GRAY_700} text={t('building_shape.global_shape_title')} />

            {/* GLOBAL SHAPE TYPES BUTTONS SECTION */}
            <Stack gap={0.8} flexDirection="row" flexWrap="wrap">
              {Object.values(GlobalShapeType).map((type) => (
                <ToggleButton
                  key={type}
                  isSelected={globalShapeType === type}
                  label={t(`building_shape.global_shape_type.${type}`)}
                  sx={{ width: '12rem', height: '4rem', justifyContent: 'center' }}
                  onClick={() => setGlobalShapeType(type)}
                  typographyVariant='text-md'
                />
              ))}
            </Stack>

            {/* BUILDING ORIENTATION SECTION */}
            <Box marginTop={.8}>
              <BuildingOrientation onClick={setOrientationType} type={orientationType} />
            </Box>

          </Stack>

          {/* GLOBAL SHAPE TYPE PREVIEW */}
          {!isGlobalShapeTypeOther &&
            <Fade in={!isGlobalShapeTypeOther}>
              <Stack alignItems="flex-start" gap={2} width={{ xs: '100%', sm: '50%' }}>
                <Box
                  sx={{
                    position: 'relative',
                    width: '40.4rem',
                    height: '28rem',
                    backgroundColor: BEIGE_500,
                    borderRadius: '.8rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SimpleImage
                    url={getImageUrl(globalShapeType)}
                    sx={{
                      width: '75%',
                      height: '75%',
                    }}
                  />
                </Box>
              </Stack>
            </Fade>
          }

          {/* UPLOADER - SHOWN INSTEAD OF PREVIEW AND WALLS SECTION, IF GLOBAL TYPE OTHER IS SELECTED */}
          {isGlobalShapeTypeOther &&
            <Fade in={isGlobalShapeTypeOther}>
              <Stack alignItems="flex-start" gap={2} width={{ xs: '100%', sm: '50%' }}>
                <FileAPIUpload
                  title={t('upload_title')}
                  files={buildingShapeUpload.allFilesArray}
                  acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
                  isDraggingFile={false}
                  uploadHandler={(acceptedFiles) => buildingShapeUpload.uploadFiles(acceptedFiles)}
                  onDelete={(fileId) => buildingShapeUpload.deleteFiles([fileId])}
                />
              </Stack>
            </Fade>
          }
        </Stack>

      </SectionedBorderBox>


      {/* WALLS */}
      {!isGlobalShapeTypeOther && <BuildingLayoutWalls />}

    </Stack>
  )
}
