import { APIRequestState } from 'constants/API'

/** Set of request states that are considered finished */
export const FINISHED_REQUEST_STATES = new Set([APIRequestState.OK, APIRequestState.ERROR])

/** Set of request states that are considered as unfinished */
export const UNFINISHED_REQUEST_STATES = new Set([APIRequestState.BEFORE_START, APIRequestState.RUNNING])

/** Set of request states that are considered as unfinished for react query */
export const UNFINISHED_QUERY_REQUEST_STATES = new Set(['idle', 'loading'])

/** Set of request states that are considered as non blocking for dependent actions */
export const NON_BLOCKING_REQUEST_STATES = new Set([APIRequestState.BEFORE_START, APIRequestState.OK])

/** Set of request states that are considered as non blocking for dependent actions */
export const NON_BLOCKING_QUERY_STATES = new Set(['idle', 'success'])

/** Set of query states that are considered finished */
export const FINISHED_QUERY_STATES = new Set(['success', 'error'])
