import { DocumentCategory, EditingCategory, ProductKind, ProductSegment, ProductType, ShootingCategory } from 'constants/product'
import { Nullable, NullableStringIndexSignature } from 'models/helpers'
import { ProductCategory, ProductKindDTO } from 'models/product'
import { AddressWithCoordinates, Coordinates, FavouriteCreative, InstructionOptionDTO, PaymentInstructionDTO, PlaceOrderBody, ProductQuantityDTO, TravelCostProduct } from 'models/purchaseFlow'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Country } from 'constants/country'
import { Currency } from 'constants/misc'
import { PaymentMethodTypes } from 'constants/payments'
import { InstructionType } from 'constants/purchaseFlow'
import { FeeDTO } from 'models/fee'
import { DecimalDTO } from 'models/misc'
import { PaymentIntentDTO } from 'models/stripe'
import { useAPI } from 'utils/API'

enum Endpoints {
  ORDER_CONFIGURATION_CATEGORY = '/order/configuration/category',
  ORDER_CONFIGURATION_ADDRESS = '/order/configuration/address',
  ORDER_CONFIGURATION_PRODUCT = '/order/configuration/product',
  ORDER_CONFIGURATION_RENOVATION = '/order/configuration/product/renovation',
  ORDER_CONFIGURATION_INSTRUCTION = '/order/configuration/instruction',
  PLACE_ORDER = '/order/',
  GET_UPDATE_PF_INTENT = '/order/paymentIntent',
  CALCULATE_TRAVEL_COST = '/order/configuration/keyPickup/calculateTravelCost',
  GET_AVAILABLE_CTS = '/order/configuration/creatives',
  GET_UPSELL_BILLING_CONFIGURATION = '/order/configuration/upsellBilling/{assignmentId}',
  GET_EXTRA_PRODUCTS = '/order/configuration/product/extra'
}

interface GetTravelCostPayload {
  pickupAddress: AddressWithCoordinates
  assignmentAddress: AddressWithCoordinates
  country: Country
  customerAuth0Id?: string
}

// QUERIES
export function useGetKeyPickupTravelCost(sessionId: Nullable<string>, payload: Partial<GetTravelCostPayload>) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.TRAVEL_COST, QueryType.GET, { sessionId, ...payload }],
    queryFn: () => api.post<{ sessionId: string, data: TravelCostProduct }>(
      Endpoints.CALCULATE_TRAVEL_COST,
      {},
      {
        sessionId,
        data: payload,
      },
      true
    ),
    enabled: !!sessionId && !!payload.assignmentAddress && !!payload.country && !!payload.pickupAddress
  })
}

interface GetPurchaseProductRenovationPayload {
  email: string
  category: ProductCategory
  countryCode: string
  regionCode: string
  postalCode: string
  city: Nullable<string>
}

export function useGetRenovationProducts(sessionId: Nullable<string>, payload: Partial<GetPurchaseProductRenovationPayload>) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ORDER, QueryType.GET, { sessionId, ...payload }],
    queryFn: () => api.post<{ sessionId: string, data: ProductKindDTO }>(
      Endpoints.ORDER_CONFIGURATION_RENOVATION,
      {},
      {
        sessionId,
        data: payload
      },
      true
    ),
    enabled: !!sessionId && !!payload.category && !!payload.email
  })
}

export function useGetUpsellBillingConfiguration(assignmentId?: Nullable<string>, enabled?: boolean) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.UPSELL_CONFIG, QueryType.GET, { assignmentId }],
    queryFn: () => api.get<PaymentInstructionDTO>(
      Endpoints.GET_UPSELL_BILLING_CONFIGURATION,
      { assignmentId: assignmentId ?? '' },
      true
    ),
    enabled: !!assignmentId && enabled,
  })
}

// MUTATIONS

type PurchaseSessionWrapper<T> = AxiosResponse<{
  sessionId: string
  data: T
}>

interface CategoryConfig {
  defaultCategory: ShootingCategory
  shootingCategories: ShootingCategory[]
  editingCategories: EditingCategory[]
  documentCategories: DocumentCategory[]
  countryCode: Country
  visible: boolean
}

export function useGetCategoryConfig$() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<CategoryConfig>, { email: string | undefined }>(
    (body) => api.post(
      Endpoints.ORDER_CONFIGURATION_CATEGORY,
      {},
      body,
      true
    )
  )
}

interface AddressConfig {
  visible: boolean
  defaultAddress: Nullable<string>
  defaultCountryCode: Nullable<Country>
  defaultCoordinates: Nullable<Coordinates>
}

interface AddressConfigPayload {
  sessionId: Nullable<string>
  email?: string
}

export function useGetAddressConfig$() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<AddressConfig>, AddressConfigPayload>(
    ({ sessionId, ...data }) => api.post(
      Endpoints.ORDER_CONFIGURATION_ADDRESS,
      {},
      {
        sessionId,
        data,
      },
      true
    )
  )
}

export enum OptionProductType {
  INTEGER = 'INTEGER',
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  DATETIME = 'DATETIME',
}

export interface CatalogueOptionProduct {
  id: number
  duration: number
  feePrice: FeeDTO
  price: number
  kind: ProductKind
  value: number
  type: OptionProductType
}

export interface CatalogueProduct {
  id: number
  kind: ProductKind
  type: ProductType
  feePrice: FeeDTO
  originalPrice: FeeDTO
  price: number
  shootingDuration: number
  version: string
  options: Array<CatalogueOptionProduct>
  isBestSeller: boolean
  isLandRegistrationNeeded: boolean
  parentProductIds: number[]
}

interface PurchaseProductCatalogue {
  visible: boolean
  currency: Currency
  decimalVat: DecimalDTO
  decimalDiscount: DecimalDTO
  segments: NullableStringIndexSignature<
    {
      key: ProductSegment
      productTypes: Partial<Record<ProductType, {
        key: ProductType
        products: Array<Omit<CatalogueProduct, 'type'>>
      }>>
    }
  >
}

interface GetPurchaseProductCataloguePayload {
  sessionId: Nullable<string>
  email: Nullable<string>
  category: Nullable<ProductCategory>
  countryCode: Nullable<string>
  city: Nullable<string>
  regionCode: Nullable<string>
  postalCode: Nullable<string>
}

export function useGetProducts$() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<PurchaseProductCatalogue>, GetPurchaseProductCataloguePayload>(
    ({ sessionId, ...data }) => api.post(
      Endpoints.ORDER_CONFIGURATION_PRODUCT,
      {},
      {
        sessionId,
        data,
      },
      true
    )
  )
}

export interface PFInstructionOption {
  id: number
  kind: ProductKind
  isPrimary: boolean
  price: number
  feePrice: FeeDTO
  fields: Array<{
    key: string
    type: string
    value: string
  }>
}

interface InstructionsConfig {
  visible: boolean
  currency: Currency
  billingAddress: string
  timezone: string
  instructionTypes: Partial<Record<InstructionType, {
    key: InstructionType
    instructionOptions: Array<InstructionOptionDTO>
  }>>
}

interface GetInstructionConfigPayload {
  sessionId: Nullable<string>
  email: Nullable<string>
  countryCode: Nullable<string>
  category: Nullable<ProductCategory>
  coordinates: Nullable<Nullable<Coordinates>>
  productKinds: Nullable<ProductKind[]>
  productTypes: Nullable<ProductType[]>
  products: Nullable<object[]>
  area: Nullable<string>
  city: Nullable<string>
}

export function useGetInstructionConfig$() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<InstructionsConfig>, GetInstructionConfigPayload>(
    ({ sessionId, ...data }) => api.post(
      Endpoints.ORDER_CONFIGURATION_INSTRUCTION,
      {},
      {
        sessionId,
        data,
      },
      true
    )
  )
}

interface GetAvailableCreativesPayload {
  sessionId: string
  selectedProductIds: number[]
  address: string
  coordinates: Coordinates
  clientUserId: string
}

interface GetAvailableCreativesResponse {
  availableCreatives: FavouriteCreative[]
  personalCreatives: FavouriteCreative[]
  workspaceCreatives: FavouriteCreative[]
}

export function useGetAvailableCTs$() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<GetAvailableCreativesResponse>, GetAvailableCreativesPayload>(
    ({ sessionId, ...data }) => api.post(
      Endpoints.GET_AVAILABLE_CTS,
      {},
      {
        sessionId,
        data,
      },
      true
    )
  )
}

export function usePlaceOrder() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<{ id: number }>, PlaceOrderBody>(
    (payload) => api.post(
      Endpoints.PLACE_ORDER,
      {},
      payload,
      true
    )
  )
}

interface CreateUpdatePFIntentPayload {
  sessionId: string
  isUpdate: boolean
  products: ProductQuantityDTO[]
  paymentMethod: PaymentMethodTypes
}

export function useCreateUpdatePFIntent() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<PaymentIntentDTO>, CreateUpdatePFIntentPayload>(
    ({ sessionId, isUpdate, ...data }) => {

      if (isUpdate) {
        return api.put(
          Endpoints.GET_UPDATE_PF_INTENT,
          {},
          {
            sessionId,
            data,
          },
          true
        )
      } else {
        return api.post(
          Endpoints.GET_UPDATE_PF_INTENT,
          {},
          {
            sessionId,
            data: {
              ...data,
              paymentMethodTypes: [data.paymentMethod]
            },
          },
          true
        )
      }
    }
  )
}

interface ExtraProductsPayload {
  sessionId: string
  email?: string,
  category: ProductCategory,
  countryCode?: string,
  regionCode: string,
  postalCode: string,
  city?: string
}

export function useGetExtraProducts$() {
  const api = useAPI<Endpoints>()

  return getMutation<PurchaseSessionWrapper<Array<CatalogueProduct>>, ExtraProductsPayload>(
    ({ sessionId, ...data }) => api.post(
      Endpoints.GET_EXTRA_PRODUCTS,
      {},
      {
        sessionId,
        data,
      },
      true
    )
  )
}
