import { BEIGE_600, GRAY_100, GRAY_400, GRAY_500, GRAY_700, GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import { BorderBoxWrapper, ShadowElevation } from 'components/common/BorderBoxWrapper'
import { FileAPIEntry, FileState } from '../FileAPI'
import { FileRejection, useDropzone } from 'react-dropzone'
import { FileType, fileExtensionToMimetypeMap } from 'constants/misc'
import { Fragment, ReactNode, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { CircleIcon } from 'components/common/CircleIcon'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UploadLimitError } from 'components/common/UploadVisuals'
import { UploadVisualAPIItem } from '../UploadVisuals/UploadVisualAPIItem.component'
import { useSnackbar } from 'components/contexts/SnackbarService.context'

/**
 * @interface Props for the FileAPIUpload component.
 */
interface Props {
  /** All files belonging to this file upload instance*/
  files: FileAPIEntry[]
  /** Title to be displayed in the container. */
  title?: string | ReactNode
  /** File types which can be uploaded. */
  acceptedFileTypes: FileType[]
  /** Maximum number of uploaded files. (default = infinite) */
  maxNumberOfFiles?: number
  /** Whether file action listing should be displayed or not */
  showFileListing?: boolean
  /** Whether dropzone and upload is disabled */
  disabled?: boolean
  /** Whether a file is being dragged over the browser window (default = false) */
  isDraggingFile?: boolean
  /** Custom description for displaying file size check failed */
  fileSizeCheckFailedWarning?: string
  /** Elevation of the container border box */
  boxElevation?: ShadowElevation
  /** Color of the container border box */
  borderBoxColor?: string
  /** Function to trigger when file checks are successful */
  uploadHandler: (acceptedFiles: File[]) => void
  /** Callback for deleting of one listed file */
  onDelete: (fileId: string) => void
  /** Callback for checking file size and return boolean */
  onCheckFileSize?: (fileSize: number) => boolean
}

/**
 * @component
 * Renders a file upload component for uploading power of attorney PDF files.
 *
 * @example
 * <FileAPIUpload isDraggingFile={false} />
 */
export const FileAPIUpload: React.FC<Props> = ({
  title,
  showFileListing = true,
  isDraggingFile = false,
  acceptedFileTypes,
  maxNumberOfFiles = Number.POSITIVE_INFINITY,
  disabled,
  files,
  borderBoxColor = BEIGE_600,
  boxElevation = 'sm',
  fileSizeCheckFailedWarning,
  uploadHandler,
  onDelete,
  onCheckFileSize
}) => {
  const { t } = useTranslation(['upload_files'])
  const { spawnWarningToast } = useSnackbar()

  const acceptedMimeTypes = useMemo(() => {
    const acceptedTypes: Record<string, string[]> = {}

    for (const extension of acceptedFileTypes) {
      acceptedTypes[fileExtensionToMimetypeMap[extension]] = []
    }

    return acceptedTypes
  }, [acceptedFileTypes])

  const isDropzoneDisabled = useMemo(() => {
    if (disabled !== undefined) return disabled
    if (files.length >= maxNumberOfFiles) return true

    // any action is in progress
    if (files.some((file) => file.state !== FileState.IDLE)) return true
  }, [files, disabled, maxNumberOfFiles])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (isDropzoneDisabled) return

    if (fileRejections && fileRejections.length > 0) {
      if (fileRejections.length > 1) {
        spawnWarningToast(
          t('unsupported_upload_max_number_files', { maxNumberOfFiles }),
          { timeout: 5000 }
        )
        return
      }

      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }
      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      spawnWarningToast(
        `${t('unsupported_file_format')}\n${formatsString}`,
        { timeout: 5000 }
      )
    }

    const duplicateFileNames: string[] = []
    const okFiles: File[] = []

    for (let file of acceptedFiles) {
      if (!files.find(foundFile => foundFile.originalFilename === file.name)) {

        const isFileSizeValid = onCheckFileSize ? onCheckFileSize(file.size) : true
        if (!isFileSizeValid) {
          spawnWarningToast(
            fileSizeCheckFailedWarning || t('file_size_check_failed'),
            { timeout: 5000 }
          )

          return
        }

        okFiles.push(file)
      } else duplicateFileNames.push(file.name)
    }

    if (duplicateFileNames.length > 0) {
      spawnWarningToast(
        t('duplicate_file_alert', { files: duplicateFileNames.join(', ') }),
        { timeout: 5000 }
      )
      return
    }

    uploadHandler(okFiles)
  }, [fileSizeCheckFailedWarning, files, isDropzoneDisabled, maxNumberOfFiles, onCheckFileSize, spawnWarningToast, t, uploadHandler])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedMimeTypes,
    maxFiles: maxNumberOfFiles,
    disabled: isDropzoneDisabled
  })

  return (
    <BorderBoxWrapper elevation={boxElevation} padding={2} borderColor={borderBoxColor}>

      {/** TITLE */}
      {title &&
        <Stack marginBottom={1} gap={0.5} alignItems="center" direction="row">
          <Typography variant='text-sm' color={GRAY_700} fontWeight={500}>
            {title}
          </Typography>
        </Stack>
      }

      {/** UPLOAD ZONE */}
      <div {...getRootProps()}>
        <BorderBoxWrapper
          padding={4}
          elevation='none'
          textAlign="center"
          backgroundColor={isDropzoneDisabled ? GRAY_100 : undefined}
          sx={{ cursor: isDropzoneDisabled ? 'not-allowed' : 'pointer' }}
          border={`2px dashed ${GRAY_400}`}
        >

          {/** UPLOAD INPUT */}
          <input {...getInputProps()} />

          {/** SUBMIT & DROP */}
          <Stack gap={1} direction="column" alignItems="center">
            <CircleIcon
              size="5rem"
              icon={<CloudUploadOutlinedIcon fontSize="large" sx={{ color: isDropzoneDisabled ? GRAY_500 : GRAY_800 }} />}
              circleColor={isDropzoneDisabled ? WHITE : BEIGE_600}
            />

            {isDragActive || isDraggingFile
              ? (
                <Typography variant="text-sm" color={GRAY_900} fontWeight={500}>
                  {t('drop_here')}
                </Typography>
              )
              : (
                <Fragment>
                  <Box>
                    <Link variant="text-sm" color={GRAY_900} fontWeight={500}>
                      {t('upload_dropzone_click')}
                    </Link>
                    <Typography variant="text-sm" color={GRAY_900}>
                      {` ${t('upload_dropzone_drag_drop')}`}
                    </Typography>
                  </Box>

                  <Typography variant="text-sm" color={GRAY_700}>
                    <Trans t={t} i18nKey="upload_dropzone_suggestion" values={{ fileTypes: acceptedFileTypes.join(', ') }}>
                      <Typography variant='text-sm' color={GRAY_900}></Typography>
                    </Trans>
                  </Typography>
                </Fragment>
              )
            }
          </Stack>
        </BorderBoxWrapper>
      </div>

      {/** UPLOADED FILES */}
      {showFileListing && files.length > 0 && files.sort((fileA, fileB) => fileA.order - fileB.order).map(fileEntry => (
        <UploadVisualAPIItem
          key={fileEntry.id}
          fileEntry={fileEntry}
          onDelete={(id) => onDelete(id)}
        />
      ))}

      {/** ERROR (visuals limit exceeded) */}
      <div>
        <Collapse in={files.length > maxNumberOfFiles}>
          <UploadLimitError />
        </Collapse>
      </div>

    </BorderBoxWrapper>
  )
}
