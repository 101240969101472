import { BLUE_100, BLUE_600, GRAY_400, GRAY_900 } from 'constants/styling/theme'
import { FC, ReactNode } from 'react'
import { FormControlLabel, styled } from '@mui/material'
import Switch, { SwitchProps, switchClasses } from '@mui/material/Switch'

import { Label } from '../Label'

export type LabelPlacementType = 'top' | 'start' | 'bottom' | 'end'
export type MUISwitchSize = 'small' | 'medium'

interface Props {
  /** Additional classname for FormControlLabel */
  className?: string
  /** Label for MUISwitch */
  label?: string | ReactNode
  /** Position of label */
  labelPlacement?: LabelPlacementType
  /** Whether MUISwitch is toggled or not */
  checked: boolean
  /** Control MUISwitch toggled */
  onChange: () => void
  /** Control MUISwitch size */
  size?: MUISwitchSize
  /** Control MUISwitch marginTop */
  mt?: number
}

/**
 * MUISwitch component with custom styles
 */
const StyledSwitch = styled(({ size, ...props }: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, size }) => {

  const adjustSize = (value: number) => {
    if (size === 'small') {
      return value / 1.5
    } else {
      return value // Return the original value for medium size
    }
  }

  return {
    width: adjustSize(48),
    height: adjustSize(24),
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
      padding: 0,
      margin: adjustSize(2),
      transitionDuration: '300ms',
      [`&.${switchClasses.checked}`]: {
        transform: size === 'small' ? 'translateX(16px)' : 'translateX(24px)',
        color: '#fff',
        [`& + .${switchClasses.track}`]: {
          backgroundColor: BLUE_600,
          opacity: 1,
          border: 0,
        },
        [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
          opacity: 0.5,
        },
      },
      [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
        opacity: 0.7,
      },
    },
    [`& .${switchClasses.thumb}`]: {
      boxSizing: 'border-box',
      width: adjustSize(20),
      height: adjustSize(20),
      border: `1px solid ${BLUE_100}`,
      boxShadow: '0px 1px 4px rgba(19, 24, 29, 0.1)'
    },
    [`& .${switchClasses.track}`]: {
      borderRadius: 24 / 2,
      backgroundColor: GRAY_400,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }
})

/**
 * MUISwitch component for toggle actions.
 * 
 * @example
 * <MUISwitch 
 *  label="Dark mode"
 *  labelPlacement="start"
 *  checked={true}
 *  size="small" // default is medium
 * />
 */
export const MUISwitch: FC<Props> = ({
  className,
  label,
  labelPlacement,
  checked,
  onChange,
  size = 'medium',
  mt = 0
}) => {

  return (
    <FormControlLabel
      className={className}
      sx={{
        margin: 0,
        gap: '1rem',
        marginTop: mt,
        '.MuiFormControlLabel-label': {
          color: GRAY_900,
          fontSize: size === 'medium' ? '1.4rem' : '1.2rem',
          lineHeight: 1,
        }
      }}
      control={<StyledSwitch size={size} />}
      label={typeof label === 'string' ? <Label text={label} /> : label}
      labelPlacement={labelPlacement}
      checked={checked}
      onChange={onChange}
    />
  )
}
