import { AdditionalVisualPriceDTO, UrlDTO, VisualListResponse } from 'models/visuals'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { AxiosResponse } from 'axios'
import { ESProduct } from 'constants/product'
import { FeeDTO } from 'models/fee'
import { RoleMimetype } from 'constants/API'
import { StatusResponse } from 'models/redux'
import { VisualFileType } from 'constants/visual'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

enum Endpoints {
  VISUAL_FAVORITE = '/assignment/{assignmentId}/visual/favorite',
  ADMIN_SEND_ASSIGNMENT_VISUALS_TO_CLIENT = '/assignment/{assignmentId}/deliver',
  ADMIN_SEND_ASSIGNMENT_VISUALS_TO_EDITOR = '/assignment/{assignmentId}/visual/edit',
  VISUAL_ADDITIONAL_PRICE = '/assignment/{assignmentId}/additionalVisualPrice',
  VISUAL_GET_VIRTUAL_VISIT_EXTENSION_PRICE = '/assignment/{assignmentId}/virtualVisit/extendExpiration/price',
  LIST_VISUALS = '/assignment/{assignmentId}/visual/all',
  GET_VISUAL_BY_NAME = '/assignment/{assignmentId}/visual/{filename}'
}

interface VirtualVisitExtensionPriceDTO {
  fee: FeeDTO
  productId: number
}

// QUERIES

export function useGetAssignmentVisuals(assignmentId: string, visualType: VisualFileType) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT_VISUAL, QueryType.LIST, { assignmentId, visualType }],
    queryFn: () => {
      return api.get<VisualListResponse>(
        Endpoints.LIST_VISUALS,
        { assignmentId },
        true,
        {
          params: {
            type: visualType,
          },
        }
      )
    },
    enabled: !!assignmentId && !!visualType,
  })
}

export function useGetAdditionalVisualPrice(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT_VISUAL_PRICE, QueryType.GET_ONE, { assignmentId }],
    queryFn: () => api.get<AdditionalVisualPriceDTO>(
      Endpoints.VISUAL_ADDITIONAL_PRICE,
      { assignmentId },
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT // TODO: Fix on BE so we can send proper accept header
        }
      }
    ),
  })
}

export function useGetVirtualVisitExtensionPrice(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.VIRTUAL_VISIT_EXTENSION_PRICE, QueryType.GET_ONE, { assignmentId }],
    queryFn: () => api.get<VirtualVisitExtensionPriceDTO>(
      Endpoints.VISUAL_GET_VIRTUAL_VISIT_EXTENSION_PRICE,
      { assignmentId },
      true,
      {
        headers: {
          Accept: RoleMimetype.CLIENT // TODO: Fix on BE so we can send proper accept header
        }
      }
    ),
  })
}

// MUTATIONS

export interface AdminSendVisualsToEditorPayload {
  assignmentId: string,
  filenames: string[],
  product: ESProduct,
  comment: string,
}

/** Mutation for adding favorite visual */
export function useAddFavoriteVisual() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, { assignmentId: string, visuals: string[], }>(
    ({ assignmentId, visuals }) => api.post(
      Endpoints.VISUAL_FAVORITE,
      {
        assignmentId: assignmentId.toString(),
      },
      {
        visuals
      },
      false
    )
  )
}

/** Mutation for removing favorite visual */
export function useDeleteFavoriteVisual() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, { assignmentId: string, visuals: string[] }>(
    ({ assignmentId, visuals }) => api.delete(
      Endpoints.VISUAL_FAVORITE,
      {
        assignmentId: assignmentId.toString(),
      },
      false,
      {
        data: {
          visuals
        }
      }
    )
  )
}

/** Mutation for send assignment visuals to client */
export function useSendVisualsToClient() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, { assignmentId: string }>(
    ({ assignmentId }) => api.post(
      Endpoints.ADMIN_SEND_ASSIGNMENT_VISUALS_TO_CLIENT,
      {
        assignmentId,
      },
      {},
      false
    )
  )
}

/** Mutation for send assignment visuals to editor */
export function useSendVisualsToEditor() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AdminSendVisualsToEditorPayload>(
    ({ assignmentId, filenames, product, comment }) => api.post(
      Endpoints.ADMIN_SEND_ASSIGNMENT_VISUALS_TO_EDITOR,
      {
        assignmentId,
      },
      {
        filenames,
        product,
        comment,
        visualType: VisualFileType.RAW
      },
      false
    )
  )
}

export interface VisualByTypeNamePayload {
  assignmentId: string,
  visualType: VisualFileType,
  visualName: string
}

/** Mutation for getting one specific visual by type and name */
export function useGetAssignmentVisualByTypeName() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<UrlDTO>, VisualByTypeNamePayload>(
    ({ assignmentId, visualType, visualName }) => api.post(
      Endpoints.GET_VISUAL_BY_NAME,
      {
        assignmentId,
        filename: visualName,
      },
      {},
      true,
      { params: { type: visualType } }
    )
  )
}
