import '@pqina/pintura/pintura.css'

import { MUIButton } from 'components/common/MUIButton'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'

/** interface @Props for the VisualPinturaEditingFooter component. */
interface Props {
  /** onClose callback */
  onClose: () => void
  /** onSave callback */
  onSave: () => void
  /** Whether the Pintura is processing image */
  isProcessing: boolean
}

/**
 * Visual Pintura editing Footer component
 * @example <VisualPinturaEditingFooter/>
 */
export const VisualPinturaEditingFooter: React.FC<Props> = ({ onClose, onSave, isProcessing }) => {
  const { t } = useTranslation(['visual_pintura_editing'])

  return (
    <Stack
      gap={2}
      height="100%"
      marginRight={4}
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-end"
    >

      <MUIButton
        type="secondaryNoBorder"
        onClick={onClose}
        disabled={isProcessing}
      >
        {t('cancel')}
      </MUIButton>

      <MUIButton
        type="defaultPrimary"
        variant="outlined"
        onClick={onSave}
        disabled={isProcessing}
        isLoading={isProcessing}
      >
        {t('save')}
      </MUIButton>

    </Stack>
  )
}
