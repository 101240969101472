import { Box, Stack } from '@mui/material'
import { EditCalendar, PersonOutlineOutlined } from '@mui/icons-material'

import { FC } from 'react'
import { MUITooltip } from 'components/common/MUITooltip'
import classnames from 'classnames'
import i18n from 'translations/i18n'
import moment from 'moment'
import styles from './OrderCreator.module.sass'
import { useGalleryDeal } from '../../../contexts'
import { useTranslation } from 'react-i18next'

export const OrderCreator: FC = () => {
  const { t } = useTranslation(['gallery'])

  const { dealData } = useGalleryDeal()

  if (!dealData) return <></>

  return (
    <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap="1rem">
      <Stack flexDirection="row" alignItems="center">

        <PersonOutlineOutlined className={classnames(styles.icon, styles.person)} />

        <span className={styles.owner}>{dealData.customer.name}</span>

        <MUITooltip
          content={t('placed_on', { date: moment(dealData.createdAt).locale(i18n.language).format('MMMM Do YYYY, h:mm A') })}
          type="secondary"
        >
          <Box alignItems='center' display='flex'>
            <EditCalendar className={classnames(styles.icon, styles.date)} />
            <span className={styles.owner}>{moment(dealData.createdAt).locale(i18n.language).format('MMMM Do YYYY, h:mm A')}</span>
          </Box>
        </MUITooltip>

      </Stack>
    </Stack>
  )
}
