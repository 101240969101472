import { Box, Stack } from '@mui/material'
import { FC, useMemo } from 'react'
import { usePurchaseFlowConfig, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { AdressMap } from 'components/common/AdressMap'
import { AutocompleteInput } from 'components/common/AdressMap/AutocompleteInput'
import { Country } from 'constants/country'
import { InstructionOptionFieldValueType } from 'models/purchaseFlow'
import { InstructionOptionFieldValueTypeIsReactGeocodePlace } from 'utils/typeguards/instructionTypeguards'
import { Nullable } from 'models/helpers'
import { ReactGeocodePlace } from 'react-geocode'
import i18n from 'translations/i18n'

interface Props {
  /** Selected address for meeting instruction value */
  selectedAddress: InstructionOptionFieldValueType
  /** Handler for address change*/
  onAddressChange: (address: Nullable<ReactGeocodePlace>) => void
  /** Hook for address input blur */
  onInputBlur: () => void
}

/** 
 * Renders Address field for Organization Meeting Instructions KEYS_PICKUP
 * Required field
 *
 * When Date is deleted by user, Time is deleted by us as well
 *
 * DEPENDENCIES:
 * - Auth0Provider
 * - TargetOrderUserProvider
 * 
 * @example
 * <MeetingInstructionsAddressField
 *   handleFieldChange={handleFieldChange}
 *   selectedDate={selectedDate}
 *   requiredDateFieldError={false}
 * />
*/
export const MeetingInstructionsAddressField: FC<Props> = ({
  selectedAddress,
  onAddressChange,
  onInputBlur
}) => {
  const { targetUser } = useTargetOrderUser()
  const { selectedAssignmentPlace, selectedCountryCode } = usePurchaseFlowConfig()

  const initialCenter = useMemo(() => {
    if (!selectedAddress || !InstructionOptionFieldValueTypeIsReactGeocodePlace(selectedAddress)) return selectedAssignmentPlace?.geometry.location

    return selectedAddress.geometry.location
  }, [selectedAddress, selectedAssignmentPlace?.geometry.location])

  return (
    <Stack>

      <Box width="100%" marginBottom="1rem">
        <AutocompleteInput
          inputPosition="below"
          inputStyles={{
            marginBottom: '1rem',
            borderRadius: '8px',
            width: '100%',
          }}
          handleChange={(newGooglePlace, isInitialLookup) => {
            if (!isInitialLookup) onAddressChange?.(newGooglePlace)
          }}
          onInputBlur={onInputBlur}
        />
      </Box>

      <Stack width="100%">
        <AdressMap
          height={'30rem'}
          center={initialCenter}
          language={i18n.language}
          countryCode={selectedCountryCode || targetUser.country || Country.CH}
          initialAddress={InstructionOptionFieldValueTypeIsReactGeocodePlace(selectedAddress) ? selectedAddress.formatted_address : undefined}
          initialMarker={InstructionOptionFieldValueTypeIsReactGeocodePlace(selectedAddress) ? selectedAddress.geometry.location : undefined}
          handleChange={(newGooglePlace, isInitialLookup) => {
            if (!isInitialLookup) onAddressChange?.(newGooglePlace)
          }}
        />
      </Stack>

    </Stack>
  )
}
