import { useGetPropertyDetail, useGetPropertyDetailWithObjectReferenceId, useGetSearchProperty } from 'dataQueries'
import { PropertyType, RealEstatePropertyListItemDTO } from 'models/property'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useUserData } from 'components/contexts/UserDataContext'
import constate from 'constate'
import { Nullable } from 'models/helpers'
import { GoogleAPIPlace } from 'models/purchaseFlow'
import { useDebouncedEffect } from 'utils/helpers'
import { lookupPlaceFromAddress } from 'utils/helpers/lookUpPlaceFromAddress'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { getPropertyAddressFromGooglePlace } from 'utils/location/googlePlaceUtils'

export const [PurchaseFlowRealEstatePropertyContextProvider, usePurchaseFlowRealEstateProperty] = constate(() => {

  const { currentUserWorkspace, personalUserWorkspaces } = useUserData()

  const params = useBkbnSearchParams()

  const requestedPropertyId = useMemo(() => params.get('realtyId') ?? null, [params])
  const objectReferenceId = useMemo(() => params.get('objectReferenceId') ?? null, [params])
  const propertyWorkspaceId = useMemo(() => (currentUserWorkspace ?? personalUserWorkspaces[0])?.id, [currentUserWorkspace, personalUserWorkspaces])

  const getPropertyDetailQuery = useGetPropertyDetail(requestedPropertyId, propertyWorkspaceId, false)
  const getPropertyObjectReferenceQuery = useGetPropertyDetailWithObjectReferenceId(objectReferenceId, propertyWorkspaceId, false)

  // We want to use one query preferably with internal real estate property id
  const getPropertyQuery = useMemo(() => {
    if (requestedPropertyId) {
      return getPropertyDetailQuery
    } else {
      return getPropertyObjectReferenceQuery
    }
  }, [getPropertyDetailQuery, getPropertyObjectReferenceQuery, requestedPropertyId])

  // We want to use one property data preferably with internal real estate property id
  const requestedPropertyData = useMemo(() => {
    if (requestedPropertyId) {
      return getPropertyDetailQuery.data
    } else {
      return getPropertyObjectReferenceQuery.data
    }
  }, [getPropertyDetailQuery.data, getPropertyObjectReferenceQuery.data, requestedPropertyId])

  const [realEstatePropertyId, setRealEstatePropertyId] = useState<string | null>(null)
  const [isPropertyPrefilled, setIsPropertyPrefilled] = useState<boolean | null>(null)
  const [selectedPropertyType, setSelectedPropertyType] = useState<Nullable<PropertyType>>(null)
  const [selectedAssignmentPlace, setSelectedAssignmentPlace] = useState<GoogleAPIPlace | null>(null)
  const [searchProperty, setSearchProperty] = useState<string>('')
  const [selectedProperty, setSelectedProperty] = useState<Nullable<RealEstatePropertyListItemDTO>>(null)
  const [propertyName, setPropertyName] = useState<string>('') // TODO: Send this to the backend, currently it's not used in the backend

  const getSearchProperty = useGetSearchProperty(propertyWorkspaceId, searchProperty)
  const searchPropertyData = useMemo(() => getSearchProperty.data?.data, [getSearchProperty.data])

  const realEstateProperty = useMemo(() => {
    let address: GoogleAPIPlace | null = null
    if (selectedAssignmentPlace) address = selectedAssignmentPlace

    return {
      propertyType: selectedPropertyType,
      address: selectedProperty ? selectedProperty.address : getPropertyAddressFromGooglePlace(address ?? null),
    }
  }, [selectedAssignmentPlace, selectedProperty, selectedPropertyType])

  const resetRealEstateProperty = useCallback(() => {
    setSearchProperty('')
    setSelectedProperty(null)
    setRealEstatePropertyId(null)
    setSelectedAssignmentPlace(null)
  }, [])

  // prefill data on realtyId
  useEffect(() => {
    if (isPropertyPrefilled !== null) return

    if (!requestedPropertyId && !objectReferenceId) {
      setIsPropertyPrefilled(false)
      return
    }

    if (getPropertyQuery.isError) {
      setIsPropertyPrefilled(false)
      return
    }

    if (getPropertyQuery.isSuccess && requestedPropertyData) {
      setSelectedPropertyType(requestedPropertyData.propertyType)
      setRealEstatePropertyId(requestedPropertyData.id)

      setIsPropertyPrefilled(true)
      setSelectedProperty(requestedPropertyData)
    }

    // Omit "isPropertyPrefilled"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPropertyQuery.isError, getPropertyQuery.isSuccess, objectReferenceId, requestedPropertyData, requestedPropertyId])

  const getSelectedPropertyPlace = useCallback(async () => {
    if (!selectedProperty) return
    const { street, streetNumber, city, postalCode, country } = selectedProperty.address

    const place = await lookupPlaceFromAddress(`${street} ${streetNumber}, ${city}, ${postalCode}, ${country}`)
    setSelectedAssignmentPlace(place)
  }, [selectedProperty])

  // Set real estate property id when selected property changes
  useEffect(() => {
    if (selectedProperty) {
      getSelectedPropertyPlace()
      setRealEstatePropertyId(selectedProperty.id)
      setPropertyName('')
      setSelectedAssignmentPlace(null)
    }
  }, [getSelectedPropertyPlace, selectedProperty])

  // Debounce search property
  useDebouncedEffect(() => {
    if (!searchProperty) return

    getSearchProperty.refetch()
  }, [searchProperty], 250)

  return {
    selectedPropertyType,
    setSelectedPropertyType,
    selectedAssignmentPlace,
    setSelectedAssignmentPlace,
    realEstateProperty,
    realEstatePropertyId,
    isPropertyPrefilled,
    requestedPropertyData,
    getPropertyQuery,
    searchProperty,
    setSearchProperty,
    getSearchProperty,
    selectedProperty,
    setSelectedProperty,
    searchPropertyData,
    propertyName,
    setPropertyName,
    setRealEstatePropertyId,
    resetRealEstateProperty
  }
})
