import { ActionOpenActionPopup } from './actionPopup.actions'
import { ActionPopupStore } from './actionPopup.interfaces'
import { ActionString } from 'models/redux'
import { ActionStringType } from 'constants/redux'

/** Default state of action popup store */
export const defaultActionPopupState: ActionPopupStore = {
  body: undefined,
  type: undefined,
  isShown: false,
  options: {
    title: undefined,
    actionsAdditionalSlot: undefined,
    alertCloseText: undefined,
    confirmDenyText: undefined,
    confirmAcceptText: undefined,
    confirmDisabledText: undefined,
    disableGlobalActionPopup: false,
    hideSecondaryButton: false,
  },
}

/** Redux data reducer for action popup */
export const actionPopupReducer = (state = defaultActionPopupState, action: ActionString) => {
  if (Array.isArray(action.type as unknown)) return state

  switch (action.type) {
    case ActionStringType.OPEN_ACTION_POPUP:
      const openAction = action as ActionOpenActionPopup

      return {
        body: openAction.payload.body,
        type: openAction.payload.type,
        isShown: true,
        options: {
          ...state.options,
          ...openAction.payload.options
        }
      }
    case ActionStringType.CLOSE_ACTION_POPUP:
      return {
        ...defaultActionPopupState
      }
    default:
      return state
  }
}
