import '@pqina/pintura/pintura.css'
import './VisualPinturaEditingStylesOverrides.css'

import * as Sentry from '@sentry/react'

import { ENVIRONMENT, EnvironmentValue } from 'constants/application'
import { FC, useCallback, useRef, useState } from 'react'
import {
  PinturaNode,
  PinturaNodeOptions,
  findNode,
  getEditorDefaults
} from '@pqina/pintura'

import { ActionPage } from 'components/common/ActionPage/ActionPage.component'
import { AnalyticsEvent } from 'utils/analytics'
import { Path } from 'constants/router'
import { PinturaEditor } from '@pqina/react-pintura'
import { VisualPinturaEditingFooter } from './VisualPinturaEditingFooter.module'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'
import { useVisualPinturaEditing } from './VisualPinturaEditing.context'

const PINTURA_UTILS = ['crop', 'finetune', 'filter', 'annotate', 'sticker', 'fill', 'redact', 'frame', 'resize']

const PINTURA_EXPORT_BTN_ID = 'export'
const PINTURA_REVERT_BTN_ID = 'revert'

const PINTURA_EDITOR_DEFAULTS = getEditorDefaults({
  stickers: ['😎', '🎉', '😄', '👍', '👎'],
})

/**
 * Visual Pintura editing controller for individual visual selected from Gallery
 * @example <VisualPinturaEditingController />
 */
export const VisualPinturaEditingController: FC = () => {
  const editorRef = useRef<PinturaEditor>(null)

  const navigate = useNavigate()
  const { t } = useTranslation(['visual_pintura_editing'])
  const { spawnSuccessToast, spawnErrorToast } = useSnackbar()

  const [isProcessingImage, setIsProcessingImage] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const {
    logEditEvent,
    visualName,
    imageUrl,
    assignmentId
  } = useVisualPinturaEditing()

  const onActionPageClose = useCallback(async () => {
    logEditEvent(AnalyticsEvent.PINTURA_EXIT)

    navigate(Path.GALLERY.replace(':id', assignmentId))
  }, [logEditEvent, navigate, assignmentId])

  const customizeToolbarButtons = (toolbar: PinturaNode[]) => {
    // Find the export button in the toolbar
    const exportButton = findNode(PINTURA_EXPORT_BTN_ID, toolbar)
    // Find the revert button in the toolbar
    const revertButton = findNode(PINTURA_REVERT_BTN_ID, toolbar)

    const exportButtonOptions = (exportButton?.[2] as PinturaNodeOptions) ?? undefined
    const revertButtonOptions = (revertButton?.[2] as PinturaNodeOptions) ?? undefined

    if (exportButtonOptions) {
      // Hide the default export button as we use a custom export button
      exportButtonOptions.hideLabel = true
      // hide button class
      exportButtonOptions.class = ''
    }

    if (revertButtonOptions) {
      // Ensure the revert button label is visible
      revertButtonOptions.hideLabel = false
    }

    return toolbar
  }

  const handleProcessImage = useCallback(() => {
    if (editorRef.current) {
      setIsProcessingImage(true)

      editorRef.current.editor
        .processImage()
        .then((imageWriterResult) => {
          setIsSuccess(imageWriterResult.dest.size > 0)

          logEditEvent(AnalyticsEvent.PINTURA_PROCESS_IMAGE, {
            status: isSuccess ? 'successfully done' : 'failed',
            result: imageWriterResult.dest,
          })
        })
        .catch((error) => {
          console.error('Error during image processing:', error)

          const sentryMessage = 'Pintura visual editing ERROR'
          Sentry.captureException(sentryMessage, scope => {
            scope.clearBreadcrumbs()
            scope.setFingerprint([sentryMessage])
            scope.setExtra('params', error.config?.params)
            scope.setExtra('visualName', visualName)
            scope.setExtra('assignmentId', assignmentId)
            scope.setExtra('originalImageUrl', imageUrl)
            return scope
          })

          spawnErrorToast(t('general_error', { error }))
        })
        .finally(() => {
          if (ENVIRONMENT === EnvironmentValue.DEVELOPMENT || ENVIRONMENT === EnvironmentValue.LOCALHOST) {
            if (isSuccess) {
              spawnSuccessToast(
                '🤸🏻 Pintura process successfully done, we will forward you back to gallery',
                { timeout: 1000 }
              )
            }
          }
          // Redirect users back to gallery after a short delay
          setTimeout(() => {
            setIsProcessingImage(false)
            navigate(Path.GALLERY.replace(':id', assignmentId))
          }, 2000)
        })
    }
  }, [logEditEvent, isSuccess, spawnErrorToast, t, visualName, assignmentId, imageUrl, spawnSuccessToast, navigate])


  return (
    <ActionPage
      id={`visual-pintura-editing-assignment-${assignmentId}-img-${visualName}`}
      title={t('title')}
      onClose={onActionPageClose}
      footerChildren={
        <VisualPinturaEditingFooter onClose={onActionPageClose} onSave={handleProcessImage} isProcessing={isProcessingImage} />
      }
    >
      <div style={{ height: '100%' }}>
        <PinturaEditor
          {...PINTURA_EDITOR_DEFAULTS}
          ref={editorRef}
          src={imageUrl}
          utils={PINTURA_UTILS}
          willRenderToolbar={customizeToolbarButtons}
        />
      </div>
    </ActionPage>
  )
}
