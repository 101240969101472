import { BuildingType, ConsumptionCertificateBuildingPeriod, ConsumptionCertificateLivingUnits, EnergeticMeasure, FuelType, HotWaterGenerationType, RenewableEnergySource, RenewableEnergySourceUsage } from 'constants/purchaseFlow'
import { ConsumptionCertificateDetailsPayloadDTO, ConsumptionCertificateHeaterPayloadDTO, HeaterBillingPeriodPayloadDTO } from 'models/purchaseFlow/consumptionCertificateDetailsDTO'
import { useCallback, useMemo, useState } from 'react'

import constate from 'constate'
import { useAdditionalInformation } from 'components/pages/PurchaseFlow/common/AdditionalInformation'
import { useConsumptionInformation } from '../ConsumptionInformation'
import { useHeatingSystem } from '../HeatingSystem/HeatingSystem.context'
import { usePropertyInformation } from '../PropertyInformation'

/** Enum of available information types */
export enum InformationType {
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  HEATING_SYSTEM = 'HEATING_SYSTEM',
  CONSUMPTION_INFORMATION = 'CONSUMPTION_INFORMATION',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export const [ConsumptionCertificateStepContextProvider, useConsumptionCertificateStep] = constate(() => {

  const { heatingSystemItems } = useHeatingSystem()

  const {
    exteriorViewUpload,
    heaterNameplateUpload,
    message,
    energyCertificateReason
  } = useAdditionalInformation()

  const {
    ventilationType,
    basementType,
    residentialUnit,
    buildingType,
    constructionYear,
    livingSpace,
    yearOfRenovation,
    commercialArea,
    isConstructionConfirmed,
    isShowConstructionNote,
    buildingLocationType,
    commercialAreaType
  } = usePropertyInformation()

  const {
    systemType,
    useType,
    hasPropertyVacant,
    startDate,
    endDate,
    area,
    energeticConditionType
  } = useConsumptionInformation()

  const [infoType, setInfoType] = useState<InformationType>(InformationType.PROPERTY_INFORMATION)

  const isPropertyInformationValid = useMemo(() => {
    if (isShowConstructionNote && !isConstructionConfirmed) return false

    if (!buildingType || !buildingLocationType) return false

    if (buildingType === BuildingType.MIXED_USE_BUILDING && (!commercialArea || !commercialAreaType)) return false

    if (!constructionYear || constructionYear.length !== 4) return false

    if (yearOfRenovation && yearOfRenovation.length !== 4) return false

    if (!livingSpace) return false

    return true
  }, [isShowConstructionNote, isConstructionConfirmed, buildingType, buildingLocationType, commercialArea, commercialAreaType, constructionYear, yearOfRenovation, livingSpace])

  /** Determines if the heating system is valid checking each item has a value. */
  const isHeatingSystemValid = useMemo(() => {
    return heatingSystemItems.every(({ fuelType, hotWaterType, unitCaptureType, yearOfManufacture, billingPeriodItems, primaryEnergyFactor }) =>
      fuelType !== undefined &&
      hotWaterType !== undefined &&
      unitCaptureType !== undefined &&
      yearOfManufacture !== null &&
      billingPeriodItems.every(({ startDate, endDate, consumption, hotWaterConsumptionKwh }) =>
        startDate !== null &&
        endDate !== null &&
        consumption !== null &&
        (hotWaterType !== HotWaterGenerationType.KNOW_HOT_WATER_CONSUMPTION_IN_KWH || hotWaterConsumptionKwh !== null)
      ) &&
      (fuelType !== FuelType.DISTRICT_HEATING || primaryEnergyFactor !== null)
    )
  }, [heatingSystemItems])

  const isConsumptionInformationValid = useMemo(() => {
    if (hasPropertyVacant) {
      if (!startDate || !endDate || !area) return false
    }

    if (systemType !== RenewableEnergySource.NONE) {
      if (useType === RenewableEnergySourceUsage.NONE) return false
    }

    return true
  }, [area, endDate, hasPropertyVacant, startDate, systemType, useType])

  const isAdditionalInformationValid = useMemo(() => {
    if (!exteriorViewUpload.allFilesArray.length || !heaterNameplateUpload.allFilesArray.length) return false

    return true
  }, [exteriorViewUpload.allFilesArray.length, heaterNameplateUpload.allFilesArray.length])

  const getConsumptionCertificateRequestData = useCallback((): ConsumptionCertificateDetailsPayloadDTO | undefined => {

    if (!buildingType || !constructionYear || !livingSpace || !buildingLocationType) return undefined

    const livingUnits = residentialUnit > 4 ? ConsumptionCertificateLivingUnits.FIVE_OR_MORE : ConsumptionCertificateLivingUnits.LESS_THAN_FIVE
    const buildingPeriod = parseInt(constructionYear) < 1978 ? ConsumptionCertificateBuildingPeriod.BEFORE_1978 : ConsumptionCertificateBuildingPeriod.FROM_1978_ON

    const heaters = heatingSystemItems.toArray().map(([_, { fuelType, hotWaterType, unitCaptureType, yearOfManufacture, billingPeriodItems, primaryEnergyFactor }]) => {

      const billingPeriods: HeaterBillingPeriodPayloadDTO[] = billingPeriodItems.toArray().map(([_, { startDate, endDate, consumption, hotWaterConsumptionKwh }]) => {
        const hotWaterConsumptionKwhValue = hotWaterType === HotWaterGenerationType.KNOW_HOT_WATER_CONSUMPTION_IN_KWH ? hotWaterConsumptionKwh : 0

        return {
          startBillingPeriod: startDate?.format('DD.MM.YYYY') ?? '',
          endOfBillingPeriod: endDate?.format('DD.MM.YYYY') ?? '',
          hotWaterConsumptionKwh: hotWaterConsumptionKwhValue ?? 0,
          consumption: Number(consumption) ?? 0,
        } as HeaterBillingPeriodPayloadDTO
      })

      const primaryEnergyFactorValue = fuelType === FuelType.DISTRICT_HEATING ? Number(primaryEnergyFactor) : 0
      const yearOfManufactureValue = parseInt(yearOfManufacture?.format('YYYY') ?? '0')

      return {
        fuel: fuelType,
        unit: unitCaptureType,
        hotWater: hotWaterType,
        yearOfConstruction: yearOfManufactureValue,
        primaryEnergyFactor: primaryEnergyFactorValue,
        billingPeriod: billingPeriods,
      } as ConsumptionCertificateHeaterPayloadDTO
    })

    const vacancyPeriodsList = (hasPropertyVacant && area) ? [{ areaVacancyInSquareMeters: area, startVacancy: startDate?.format('DD.MM.YYYY') ?? '', endVacancy: endDate?.format('DD.MM.YYYY') ?? '' }] : []
    const isBefore1978AndConstructionConfirmed = isShowConstructionNote && isConstructionConfirmed

    return {
      livingUnitsDescr: livingUnits,
      buildingPeriod,
      energeticRenovations: {
        hasDoubleGlazedWindows: isBefore1978AndConstructionConfirmed || energeticConditionType.has(EnergeticMeasure.WINDOW_REPLACEMENT_25_YEARS),
        hasRoofInsulation: isBefore1978AndConstructionConfirmed || energeticConditionType.has(EnergeticMeasure.ROOF_INSULATION),
        hasExteriorWallInsulation: isBefore1978AndConstructionConfirmed || energeticConditionType.has(EnergeticMeasure.EXTERNAL_WALL_INSULATION),
      },
      currentEnergeticConditions: Array.from(energeticConditionType),
      buildingType,
      typeOfUseCommercial: commercialAreaType,
      commercialSpaceInSquareMeters: parseInt(commercialArea),
      buildingLocation: buildingLocationType,
      buildingYear: parseInt(constructionYear),
      renovationYear: parseInt(yearOfRenovation),
      livingUnits: residentialUnit,
      totalLivingArea: parseInt(livingSpace),
      renewableEnergies: systemType,
      renewableEnergiesUsage: useType,
      ventilation: ventilationType,
      basement: basementType,
      heaters,
      vacancy: {
        vacancyPresent: hasPropertyVacant,
        vacancyPeriods: vacancyPeriodsList
      },
      reasonForEnergyPass: energyCertificateReason,
      messageToExpert: message,
    }
  }, [area, basementType, buildingLocationType, buildingType, commercialArea, commercialAreaType, constructionYear, endDate, energeticConditionType, energyCertificateReason, hasPropertyVacant, heatingSystemItems, isShowConstructionNote, isConstructionConfirmed, livingSpace, message, residentialUnit, startDate, systemType, useType, ventilationType, yearOfRenovation])

  return {
    infoType,
    setInfoType,
    isPropertyInformationValid,
    isHeatingSystemValid,
    isConsumptionInformationValid,
    isAdditionalInformationValid,
    getConsumptionCertificateRequestData
  }
})
