import { useCallback, useEffect, useState } from 'react'

import { useGetAssignmentDocumentFile } from 'dataQueries/assignmentDocuments.query'
import { ImmutableMap } from 'models/helpers'
import { ThreadMessageItem } from '../Thread.component'
import { MessageAttachedFile } from '../ThreadMessage'

/**
 * Custom hook to fetch attached files for a list of thread messages.
 *
 * @param {ThreadMessageItem[]} messages - Array of thread messages.
 * @param {string} assignmentId - The ID of the assignment.
 * 
 * @example
 * const { isLoading, messageFiles } = useFetchMessageFiles(messages, assignmentId)
 */
export const useFetchMessageFiles = (messages: ThreadMessageItem[], assignmentId: string) => {

  const [isFetchingMessageFiles, setIsFetchingMessageFiles] = useState(true)
  const [messageFiles, setMessageFiles] = useState<ImmutableMap<string, MessageAttachedFile[]>>(ImmutableMap([]))

  const assignmentDocumentFile = useGetAssignmentDocumentFile()

  const fetchFiles = useCallback(async () => {
    setIsFetchingMessageFiles(true)

    for (const message of messages) {
      if (message.attachmentFilenames.length === 0) {
        continue
      }

      const promises = message.attachmentFilenames.map((filename) =>
        assignmentDocumentFile.mutateAsync({ assignmentId, filename })
      )

      try {
        const results = await Promise.all(promises)

        const updatedFiles: MessageAttachedFile[] = results.map((result) => ({
          id: result.data.name,
          fileName: result.data.originalName,
          fileUrl: result.data.signedUrl.signedURL,
        }))

        setMessageFiles((prevItems) => prevItems.set(message.id, updatedFiles))
      } catch {
        // If the file fetching fails, set an empty array for the message
        setMessageFiles((prevItems) => prevItems.set(message.id, []))
      }
    }

  }, [messages, assignmentDocumentFile, assignmentId])

  useEffect(() => {
    if (messages.length > 0) {
      fetchFiles()
      setIsFetchingMessageFiles(false)
    } else {
      setIsFetchingMessageFiles(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  return { isFetchingMessageFiles, messageFiles }
}
