import { AvailableStagingsListContextProvider, ChangeRemunerationDrawerContextProvider, GalleryStagingStackProvider } from '../common'
import { FC, Fragment, useMemo } from 'react'
import { GalleryAdditionalVisualsContextProvider, GalleryAssignmentContextProvider, GalleryCommentsContextProvider, GalleryConstantsContextProvider, GalleryContextProvider, GalleryDealContextProvider, GalleryDocumentsContextProvider, GalleryEditorProductContextProvider, GalleryOrderContextProvider, GalleryProductContextProvider, GalleryVirtualVisitContextProvider, GalleryVisualSelectionContextProvider, GalleryVisualTypeContextProvider, GalleryVisualsContextProvider, GalleryVisualsMetaContextProvider } from '../_main/contexts'
import { Outlet, useParams } from 'react-router'

import { FileAPIGalleryVisualsProvider } from './FileAPIGalleryVisuals.context'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { PageTransition } from 'utils/animations'

export const TestGallery: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const assignmentId = useMemo(() => id || undefined, [id])

  if (!assignmentId) return <NotFound />

  return (
    <Fragment>
      {typeof assignmentId !== 'undefined' &&
        <GalleryConstantsContextProvider>
          <GalleryAssignmentContextProvider assignmentId={assignmentId}>
            <GalleryDealContextProvider assignmentId={assignmentId}>
              <GalleryDocumentsContextProvider assignmentId={assignmentId}>
                <GalleryVisualTypeContextProvider assignmentId={assignmentId}>
                  <GalleryProductContextProvider assignmentId={assignmentId}>
                    <GalleryOrderContextProvider assignmentId={assignmentId}>
                      <GalleryVisualsMetaContextProvider assignmentId={assignmentId}>

                        <FileAPIGalleryVisualsProvider assignmentId={assignmentId}>

                          <GalleryVisualsContextProvider assignmentId={assignmentId}>
                            <GalleryVirtualVisitContextProvider assignmentId={assignmentId}>
                              <GalleryVisualSelectionContextProvider assignmentId={assignmentId}>
                                <GalleryAdditionalVisualsContextProvider assignmentId={assignmentId}>
                                  <GalleryCommentsContextProvider assignmentId={assignmentId}>
                                    <GalleryEditorProductContextProvider assignmentId={assignmentId}>
                                      <ChangeRemunerationDrawerContextProvider>
                                        <GalleryStagingStackProvider>
                                          <AvailableStagingsListContextProvider>

                                            <GalleryContextProvider assignmentId={assignmentId}>
                                              <PageTransition>
                                                <Outlet></Outlet>
                                              </PageTransition>
                                            </GalleryContextProvider>

                                          </AvailableStagingsListContextProvider>
                                        </GalleryStagingStackProvider>
                                      </ChangeRemunerationDrawerContextProvider>
                                    </GalleryEditorProductContextProvider>
                                  </GalleryCommentsContextProvider>
                                </GalleryAdditionalVisualsContextProvider>
                              </GalleryVisualSelectionContextProvider>
                            </GalleryVirtualVisitContextProvider>
                          </GalleryVisualsContextProvider>
                        </FileAPIGalleryVisualsProvider>
                      </GalleryVisualsMetaContextProvider>
                    </GalleryOrderContextProvider>
                  </GalleryProductContextProvider>
                </GalleryVisualTypeContextProvider>
              </GalleryDocumentsContextProvider>
            </GalleryDealContextProvider>
          </GalleryAssignmentContextProvider>
        </GalleryConstantsContextProvider>
      }
    </Fragment>
  )
}
