import { APIRequestState, Endpoint, RoleMimetype, awaitedUserMeSignupErrorStatusCodes } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getInitRoles, getRoles, shouldUserRequestOrganizationMe } from 'utils/auth'
import { userMeta, userMetaSaga } from '../UserMeta'

import API from 'utils/API/API'
import { ActionUserMe } from '.'
import moment from 'moment-timezone'
import { trackUser } from 'utils/analytics'

/** Saga which fetches user me */
export function* userMeSaga(receivedAction: ActionUserMe) {
  let roles = getInitRoles()
  try {
    roles = yield getRoles()
  } catch (e) {
    throw new Error('Unable to get roles for user')
  }

  const roleNotSet = !roles || roles.all.size === 0
  const mimeType = roleNotSet ? RoleMimetype.CLIENT : API.decideRoleMimeType(roles)

  const url = Endpoint.USER_ME
  const action: ActionUserMe = yield generalFetch(ActionTypeAPIData.USER_ME, () => API.get(url, {
    headers: {
      Accept: mimeType,
    },
  }, {
    endpoint: Endpoint.USER_ME,
    ignoreErrorFactory: error => awaitedUserMeSignupErrorStatusCodes.has(error.response?.status ?? Number.MIN_VALUE),
  }))

  // if user me is 403 or 404, ask for user meta data
  if (awaitedUserMeSignupErrorStatusCodes.has(action.payload.request.response?.status ?? Number.MIN_VALUE)) {
    yield call(userMetaSaga, userMeta())
  }

  const userMeAction: ActionUserMe = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  const user = action.payload.request.data
  if (user) trackUser(user)

  yield put(userMeAction)

  if (!shouldUserRequestOrganizationMe(user)) return

}

/** Side effect for changing moment timezone according to received userMe */
function updateMomentTimezoneSaga({ payload }: ActionUserMe) {
  if (payload.request.state !== APIRequestState.OK) return
  if (!payload.request.data?.defaultTimezone) return
  moment.tz.setDefault(payload.request.data.defaultTimezone)
}

/** Side effect for changing moment locale according to received userMe language */
function updateMomentLocaleSaga({ payload }: ActionUserMe) {
  if (payload.request.state !== APIRequestState.OK) return
  if (!payload.request.data?.language) return
  moment.locale(payload.request.data.language)
}

/** Watcher of user me actions */
export function* userMeWatcher() {
  yield all([
    takeEvery((action: ActionUserMe) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_ME]), userMeSaga),
    takeEvery((action: ActionUserMe) => actionTypeTupleTest(action, [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.USER_ME]), updateMomentTimezoneSaga),
    takeEvery((action: ActionUserMe) => actionTypeTupleTest(action, [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.USER_ME]), updateMomentLocaleSaga),
  ])
}
