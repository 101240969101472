import { OrganizationKindsType, OrganizationProductKinds, ProductKind, ProductKindsWithCheckbox } from 'constants/product'
import { Place, TravelCostProduct } from 'models/purchaseFlow'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { isEmail, isRequiredString } from 'utils/forms'
import { getMinDateTimeForOrder, getMinDateTimeForOrderWithPrestigeProduct, roundUpToNearestTimeSegment } from 'utils/time'

import { useUserData } from 'components/contexts/UserDataContext'
import { STRIPE_PRIMARY_PRODUCT_KINDS } from 'constants/payments'
import constate from 'constate'
import { useCreateUpdatePFIntent } from 'dataQueries/purchase.query'
import { Nullable } from 'models/helpers'
import { ClientType } from 'models/user'
import { ReactGeocodePlace } from 'react-geocode'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from 'utils/auth'
import { getDefaultErrorMessage } from 'utils/forms/getDefaultErrorMessage'
import { createPlaceFromGooglePlace } from 'utils/location/googlePlaceUtils'
import { InstructionOptionFieldValueTypeIsReactGeocodePlace } from 'utils/typeguards'
import { isAddressValid } from 'utils/validators'
import { useBookCreative } from '../../common/InstructionListing/_main/contexts/BookCreative.context'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { usePurchaseFlowRealEstateProperty } from './PurchaseFlowRealEstateProperty.context'
import { useTargetOrderUser } from './TargetOrderUser.context'

/** Base structure of fields for meeting cards */
export interface MeetingInfo {
  name: string
  phone: string
  email?: string
  comments?: string
  date?: Moment
  address?: ReactGeocodePlace
}

/** Meeting data by meeting type */
export interface MeetingData {
  [ProductKind.MEETING_ON_SITE]: MeetingInfo
  [ProductKind.KEYS_PICKUP]: MeetingInfo
  [ProductKind.ORGANIZATION_THIRD_PARTY]: MeetingInfo
}

interface MeetingFieldsErrors {
  date: string | undefined
  name: string | undefined
  phone: string | undefined
  email: string | undefined
  address: string | undefined
}

const initialFieldErrors: MeetingFieldsErrors = {
  date: undefined,
  name: undefined,
  phone: undefined,
  email: undefined,
  address: undefined,
}

const initialMeetingData: MeetingData = {
  [ProductKind.MEETING_ON_SITE]: {
    name: '',
    phone: '',
    email: '',
    comments: '',
    date: undefined,
  },
  [ProductKind.KEYS_PICKUP]: {
    name: '',
    phone: '',
    email: '',
    comments: '',
    date: undefined,
    address: undefined,
  },
  [ProductKind.ORGANIZATION_THIRD_PARTY]: {
    name: '',
    phone: '',
    email: '',
    comments: '',
  },
}

interface MeetingInfoFillInChecked {
  [ProductKind.MEETING_ON_SITE]: boolean
  [ProductKind.KEYS_PICKUP]: boolean
}

const initialMeetingInfoFilledInChecked: MeetingInfoFillInChecked = {
  [ProductKind.MEETING_ON_SITE]: false,
  [ProductKind.KEYS_PICKUP]: false,
}

export const [PurchaseFlowOrderMetaProvider, usePurchaseFlowOrderMeta] = constate(() => {
  const { t } = useTranslation(['purchase_flow'])
  const { roles } = useAuth0()
  const { targetUser } = useTargetOrderUser()
  const { clientData } = useUserData()
  const { getInstructionCatalogue, availablePaymentMethods, catalogueTimezone } = usePurchaseFlowConfig()
  const { isPrestigeProductSelected, selectedProducts } = usePurchaseFlowProducts()
  const { selectedCreative } = useBookCreative()
  const { selectedProperty } = usePurchaseFlowRealEstateProperty()

  const [reference, setReference] = useState<string>('')
  const [propertyOwner, setPropertyOwner] = useState<string>('')
  const [shootingComments, setShootingComments] = useState<string>('')
  const [stagingComments, setStagingComments] = useState<string>('')
  const [ccEmails, setCcEmails] = useState<Set<string>>(new Set([]))

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<ProductKind | null>(null)
  const [selectedMeetingType, setSelectedMeetingType] = useState<ProductKind | null>(null)
  const [selectedExtraServices, setSelectedExtraServices] = useState<Set<ProductKind>>(new Set([]))

  const [meetingInformation, setMeetingInformation] = useState<MeetingData>(initialMeetingData)
  const [phoneInputValidationError, setPhoneInputValidationError] = useState<string>()
  const [meetingInfoFillInCheckedMap, setMeetingInfoFillInCheckedMap] = useState<MeetingInfoFillInChecked>(initialMeetingInfoFilledInChecked)
  const [externalReportingField, setExternalReportingField] = useState<string>('')

  const [keyPickupTravelCostProduct, setKeyPickupTravelCostProduct] = useState<TravelCostProduct | null>(null)
  const selectedKeyPickupPlace = useMemo<Nullable<Place>>(() => {
    const selectedAddress = meetingInformation.KEYS_PICKUP.address

    if (!selectedAddress || !InstructionOptionFieldValueTypeIsReactGeocodePlace(selectedAddress)) return null

    return createPlaceFromGooglePlace(selectedAddress)

  }, [meetingInformation.KEYS_PICKUP.address])

  // -- VAT NUMBER --
  const [VATNumber, setVATNumber] = useState<string>('')
  const vatInputRequired = useMemo(() => !targetUser.vatNumber && targetUser.clientType === ClientType.B2B, [targetUser.clientType, targetUser.vatNumber])
  // -- END OF VAT

  // -- BILLING ADDRESS --
  const [billingAddress, setBillingAddress] = useState<string>('')
  const billingAddressError = useMemo(() => isRequiredString({ value: billingAddress }), [billingAddress])
  // -- END OF BILLING ADDRESS --

  // -- PAYMENT --
  const isStripePaymentMethod = useMemo(() => !!selectedPaymentMethod && STRIPE_PRIMARY_PRODUCT_KINDS.has(selectedPaymentMethod), [selectedPaymentMethod])
  const paymentIntentMutation = useCreateUpdatePFIntent()
  // -- END OF PAYMENT --

  // -- STATE SETTERS --
  const updateMeetingInformation = useCallback((kind: OrganizationKindsType, updatedInfo: Partial<MeetingInfo>) => {
    setMeetingInformation((prev) => ({
      ...prev,
      [kind]: {
        ...(prev[kind] || {}),
        ...updatedInfo,
      },
    }))
  }, [])

  const fillInMeetingPersonInfo = useCallback((meetingType: ProductKindsWithCheckbox) => {

    const existingValues = meetingInformation[meetingType]

    updateMeetingInformation(meetingType, {
      name: existingValues.name || targetUser.name,
      email: existingValues.email || targetUser.email,
      phone: existingValues.phone || targetUser.phone,
    })
  }, [meetingInformation, targetUser.email, targetUser.name, targetUser.phone, updateMeetingInformation])

  const toggleFillInMeetingInfo = useCallback((meetingType: ProductKindsWithCheckbox) => {
    const previous = meetingInfoFillInCheckedMap

    setMeetingInfoFillInCheckedMap({
      ...previous,
      [meetingType]: !previous[meetingType],
    })

    // If we are switching from false to true, fill in contact info
    if (!previous[meetingType]) fillInMeetingPersonInfo(meetingType)
  }, [fillInMeetingPersonInfo, meetingInfoFillInCheckedMap])

  const toggleExtraService = (service: ProductKind) => {
    const newServices = new Set(selectedExtraServices)

    if (newServices.has(service)) {
      newServices.delete(service)
      logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_FAST_DELIVERY_REMOVED, {})
    } else {
      newServices.add(service)
      logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_FAST_DELIVERY_SELECTED, {})
    }

    setSelectedExtraServices(newServices)
  }
  // -- END OF STATE SETTERS --

  const isCreativeExtraServiceSelected = useMemo(() => !!selectedCreative && selectedExtraServices.has(ProductKind.FAVOURITE_CREATIVE), [selectedExtraServices, selectedCreative])

  /** Datetime object signifying the least available time slot for order */
  const minOrderDateTime = useMemo(() => {
    // Guard for editing category, where catalogue timezone comes as null (no meeting instructions)
    if (!catalogueTimezone) return roundUpToNearestTimeSegment(new Date(), 'quarter-hour')

    if (roles.isAdmin) return roundUpToNearestTimeSegment(new Date(), 'quarter-hour')

    if (isPrestigeProductSelected) return roundUpToNearestTimeSegment(getMinDateTimeForOrderWithPrestigeProduct(moment(), catalogueTimezone), 'quarter-hour')

    return roundUpToNearestTimeSegment(getMinDateTimeForOrder(moment(), catalogueTimezone, isCreativeExtraServiceSelected), 'quarter-hour')
  }, [catalogueTimezone, isPrestigeProductSelected, roles.isAdmin, isCreativeExtraServiceSelected])

  const minOrderDateTimeString = useMemo(() => {
    if (!catalogueTimezone) return ''
    return moment(minOrderDateTime).tz(catalogueTimezone).format('DD. MM. YYYY HH:mm')
  }, [catalogueTimezone, minOrderDateTime])

  /** Errors after validation for current meeting fields (by selected meeting type) */
  const currentFieldErrors = useMemo(() => {
    const errors: MeetingFieldsErrors = { ...initialFieldErrors }

    if (!selectedMeetingType || !OrganizationProductKinds.has(selectedMeetingType)) return errors

    const currentFields = meetingInformation[selectedMeetingType as OrganizationKindsType]

    errors.name = isRequiredString({ value: currentFields.name })
    errors.email = currentFields.email ? isEmail({ value: currentFields.email }) : undefined
    errors.phone = !currentFields.phone ? getDefaultErrorMessage('required') : phoneInputValidationError

    // Validate date for relevant meeting types
    if (selectedMeetingType !== ProductKind.ORGANIZATION_THIRD_PARTY) {
      if (!currentFields.date) errors.date = getDefaultErrorMessage('required')
      else if (!currentFields.date.isValid()) errors.date = getDefaultErrorMessage('dateIsInvalid')
      else if (currentFields.date.isBefore(moment())) errors.date = getDefaultErrorMessage('dateIsPast')
      else if (currentFields.date.isBefore(minOrderDateTime)) errors.date = t('validation_errors.date_too_soon', { dateString: minOrderDateTimeString })
    }

    // Validate address for relevant meeting types
    if (selectedMeetingType === ProductKind.KEYS_PICKUP) {
      const selectedAddress = currentFields.address

      if (!selectedAddress || !InstructionOptionFieldValueTypeIsReactGeocodePlace(selectedAddress)) errors.address = t('validation_errors.no_address')
      else if (InstructionOptionFieldValueTypeIsReactGeocodePlace(selectedAddress) && !isAddressValid(selectedAddress)) errors.address = t('validation_errors.invalid_address')
    }

    return errors
  }, [meetingInformation, minOrderDateTime, minOrderDateTimeString, phoneInputValidationError, selectedMeetingType, t])

  /** Whether or not are currently active meeting info valid */
  const areCurrentMeetingFieldsValid = useMemo(() => {
    if (!selectedMeetingType) return false

    for (const error of Object.values(currentFieldErrors)) {
      if (typeof error === 'string' && error !== '') return false
    }

    return true
  }, [currentFieldErrors, selectedMeetingType])

  const onCheckMeetingType = useCallback((kind: ProductKind) => {
    setSelectedMeetingType((prev) => {
      if (prev === kind) return null

      logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_ORGANISATION_METHOD_SELECTED, { organizationMethod: kind })

      return kind
    })
  }, [])

  // TODO: find more optimized way compared to resetting it on every selectedProduct change
  /* -- ORDERED EFFECTS CLUSTER -- */
  // Reset selected instructions and values when selected products change
  useEffect(() => {
    setMeetingInformation(initialMeetingData)
    setVATNumber('')
    setPropertyOwner('')
    setShootingComments('')
    setStagingComments('')
    setBillingAddress('')
    setSelectedPaymentMethod(null)
    setSelectedMeetingType(null)
    setSelectedExtraServices(new Set([]))
    getInstructionCatalogue.reset()

    // Omitted getInstructionCatalogue to prevent infinite loop since the resetting causes change in the object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts])

  useEffect(() => {
    if (selectedProperty?.title)
      setReference(selectedProperty.title)
    else if (selectedProperty?.objectReferenceId)
      setReference(selectedProperty.objectReferenceId)
    else
      setReference('')
  }, [selectedProperty])

  // Preselect preferred or only available payment method
  useEffect(() => {
    if (selectedPaymentMethod !== null) return

    // SELECT THE ONLY 1 AVAILABLE CASE
    if (Object.values(availablePaymentMethods).length === 1) {
      const onlyMethod = Object.values(availablePaymentMethods)[0]
      if (onlyMethod.kind === ProductKind.INVOICE_BY_EMAIL && (!roles.isAdmin && !targetUser.invoiceAllowed)) return

      setSelectedPaymentMethod(onlyMethod.kind)
      return
    }

    // SELECTED PREFERRED IF MORE THAN 1 AVAILABLE
    if (!roles.isClient) return
    if (!targetUser.preferredPaymentMethod) return
    if (targetUser.preferredPaymentMethod === ProductKind.INVOICE_BY_EMAIL && !targetUser.invoiceAllowed) return
    if (!availablePaymentMethods[targetUser.preferredPaymentMethod]) return

    setSelectedPaymentMethod(targetUser.preferredPaymentMethod)
  }, [availablePaymentMethods, roles.isAdmin, roles.isClient, selectedPaymentMethod, targetUser.invoiceAllowed, targetUser.preferredPaymentMethod])
  /* -- END OF ORDERED EFFECTS CLUSTER -- */

  useEffect(() => {
    if (clientData) {
      setCcEmails(new Set(clientData.ccEmails))
    }
  }, [clientData])

  return {
    reference,
    propertyOwner,
    selectedPaymentMethod,
    selectedMeetingType,
    shootingComments,
    stagingComments,
    ccEmails,
    billingAddress,
    selectedKeyPickupPlace,
    VATNumber,
    selectedExtraServices,
    meetingInformation,
    minOrderDateTime,
    currentFieldErrors,
    areCurrentMeetingFieldsValid,
    vatInputRequired,
    billingAddressError,
    meetingInfoFillInCheckedMap,
    phoneInputValidationError,
    isStripePaymentMethod,
    externalReportingField,
    keyPickupTravelCostProduct,
    paymentIntentMutation,
    isCreativeExtraServiceSelected,
    setKeyPickupTravelCostProduct,
    setExternalReportingField,
    toggleFillInMeetingInfo,
    setPhoneInputValidationError,
    setReference,
    setCcEmails,
    setPropertyOwner,
    setSelectedPaymentMethod,
    setSelectedMeetingType,
    setShootingComments,
    setStagingComments,
    setBillingAddress,
    setVATNumber,
    setSelectedExtraServices,
    toggleExtraService,
    updateMeetingInformation,
    setMeetingInformation,
    onCheckMeetingType
  }
})
