import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowRealEstateProperty, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { AdressMap } from 'components/common/AdressMap'
import { AutocompleteInput } from 'components/common/AdressMap/AutocompleteInput'
import { Box } from '@mui/material'
import { CreateProperty } from '../CreateProperty'
import { FeatureFlag } from 'utils/featureFlags'
import { GoogleAPIPlace } from 'models/purchaseFlow'
import { LoadMapScriptWrapper } from 'components/common/AdressMap/LoadMapScriptWrapper'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { SearchProperty } from '../SearchProperty'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import { SystemMessage } from 'components/common/SystemMessage'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { isAddressValid } from 'utils/validators'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * AddressStep component responsible for handling the address selection step in the purchase flow.
 *
 * @example
 * <AddressStepController />
 */
export const AddressStepController: React.FC = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation('order', { keyPrefix: 'step_address' })
  const { roles } = useAuth0()
  const allowPropertySelectionStep = useFlag(FeatureFlag.ALLOW_PROPERTY_SELECTION_STEP)

  const {
    isPropertyPrefilled,
    requestedPropertyData,
    getPropertyQuery,
    selectedProperty,
    setPropertyName,
    resetRealEstateProperty
  } = usePurchaseFlowRealEstateProperty()

  const {
    getAddressConfig,
    selectedAssignmentPlace,
    sessionId,
    setSelectedAssignmentPlace,
  } = usePurchaseFlowConfig()

  const { targetUser, adminSelectedUserEmail } = useTargetOrderUser()

  const [isOpenCreateProperty, setIsOpenCreateProperty] = useState<boolean>(false)

  const addressIsValid = useMemo(() => isAddressValid(selectedAssignmentPlace), [selectedAssignmentPlace])

  // Fetch the address configuration
  useEffect(() => {
    if (!sessionId) return
    if (roles.isAdmin && !adminSelectedUserEmail) return
    if (!getAddressConfig.isIdle) return

    getAddressConfig.mutate({
      sessionId,
      email: adminSelectedUserEmail,
    })
  }, [adminSelectedUserEmail, getAddressConfig, roles.isAdmin, sessionId])

  /** Logs entering this page with the selected category information. */
  useEffect(() => {
    logAnalyticsEvent('enters_order_address_selection_screen', {})
  }, [])

  const { initialAddress, initialMarker } = useMemo(() => {
    if (selectedAssignmentPlace) {
      return {
        initialAddress: selectedAssignmentPlace.formatted_address,
        initialMarker: selectedAssignmentPlace.geometry.location
      }
    }

    if (isPropertyPrefilled && requestedPropertyData?.address) {
      const { street, streetNumber, city, postalCode, country, coordinates } = requestedPropertyData.address
      return {
        initialAddress: `${street} ${streetNumber}, ${city}, ${postalCode}, ${country}`,
        initialMarker: coordinates,
      }
    }

    return {
      initialAddress: undefined,
      initialMarker: undefined,
    }
  }, [isPropertyPrefilled, requestedPropertyData?.address, selectedAssignmentPlace])

  const prefilled = useRef<boolean>(false)

  const handleMapOnChange = useCallback((newGooglePlace: GoogleAPIPlace | null, isInitialLookup: boolean) => {
    if (!isInitialLookup) setSelectedAssignmentPlace(newGooglePlace)
    if (isInitialLookup && isPropertyPrefilled && !prefilled.current) {
      prefilled.current = true
      setSelectedAssignmentPlace(newGooglePlace)
    }
  }, [isPropertyPrefilled, setSelectedAssignmentPlace])

  return (
    <TransitionAppear visible>

      <LoadMapScriptWrapper
        language={i18n.language}
        countryCode={targetUser?.country}
      >
        {/* Currently only allow client to use property search */}
        {allowPropertySelectionStep && roles.isClient ?
          <>
            {/* Property selection */}
            <StepWrapper title={t('property_selection_title')} subtitle={t('property_selection_text')}>

              <Stack flexDirection="row" justifyContent="space-between" gap="5rem">

                <Stack gap=".4rem" width="46rem" position="relative">

                  {/* Property search and select */}
                  <SearchProperty />

                  {!selectedProperty &&
                    <>
                      <MUIDivider>
                        {t('or')}
                      </MUIDivider>

                      {/* Create property */}
                      {isOpenCreateProperty ?
                        <CreateProperty
                          addressIsValid={addressIsValid}
                          onClose={() => {
                            setIsOpenCreateProperty(false)
                            setSelectedAssignmentPlace(null)
                            setPropertyName('')
                          }}
                          handleMapOnChange={handleMapOnChange}
                        />
                        :
                        <Stack width="100%" alignItems="center">
                          <MUIButton
                            size="md"
                            type="secondaryBorder"
                            isIconButton
                            sx={{ padding: '.8rem 1.2rem' }}
                            startIcon={<AddCircleOutlineRoundedIcon fontSize="large" />}
                            onClick={() => {
                              resetRealEstateProperty()
                              setIsOpenCreateProperty(!isOpenCreateProperty)
                            }}
                          >
                            {t('create_property_button')}
                          </MUIButton>
                        </Stack>
                      }
                    </>
                  }

                </Stack>

                {/* Map */}
                <Stack maxWidth="66rem" width="100%">
                  <AdressMap
                    height={'40rem'}
                    draggable={!selectedProperty}
                    language={i18n.language}
                    countryCode={targetUser?.country}
                    initialAddress={initialAddress}
                    initialMarker={initialMarker}
                    handleChange={handleMapOnChange}
                  />
                </Stack>

              </Stack>

            </StepWrapper>
          </>
          :
          <StepWrapper title={t('title')} subtitle={t('text')}>

            {/* Google Map for Address Selection - Will be deleted after allowing property selection */}
            <Stack>

              {getPropertyQuery.isError &&
                <SystemMessage
                  variant="warning"
                  message={t('property_prefill_fail')}
                  sx={{ mb: 4 }}
                />
              }

              <Stack>

                <Box width="50%" marginBottom="2rem">
                  <AutocompleteInput
                    inputPosition="below"
                    handleChange={(newGooglePlace, isInitialLookup) => handleMapOnChange(newGooglePlace, isInitialLookup)}
                    onInputChange={e => setSelectedAssignmentPlace(null)}
                    error={selectedAssignmentPlace && !addressIsValid ? t('address_not_selected') : undefined}
                  />
                </Box>

                <Stack width="100%">
                  <AdressMap
                    height={'40rem'}
                    draggable={!selectedProperty}
                    language={i18n.language}
                    countryCode={targetUser?.country}
                    initialAddress={initialAddress}
                    initialMarker={initialMarker}
                    handleChange={handleMapOnChange}
                  />
                </Stack>

              </Stack>

            </Stack>

          </StepWrapper>
        }
      </LoadMapScriptWrapper>

    </TransitionAppear>
  )
}
