import { FC, Fragment } from 'react'

import { PageTransition } from 'utils/animations'
import { VisualPinturaEditingContextProvider } from './VisualPinturaEditing.context'
import { VisualPinturaEditingController } from './VisualPinturaEditingController.controller'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

export const VisualPinturaEditing: FC = () => {
  const { t } = useTranslation(['visual_pintura_editing'])
  const location = useLocation()

  const state = location.state as { visualName: string, imageUrl: string, assignmentId: string }

  const { visualName, imageUrl, assignmentId } = state

  const { spawnErrorToast } = useSnackbar()

  if (!visualName) {
    spawnErrorToast(t('error_missing_visual_name'))
    window.history.back()
    return null
  }

  return (
    <Fragment>
      <VisualPinturaEditingContextProvider visualName={visualName} imageUrl={imageUrl} assignmentId={assignmentId}>
        <PageTransition>
          <VisualPinturaEditingController />
        </PageTransition>
      </VisualPinturaEditingContextProvider>
    </Fragment>
  )
}
