import { Box, Stack, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AttachFile } from '@mui/icons-material'
import { Color } from 'constants/assets'
import { GRAY_900 } from 'constants/styling/theme'
import { MUIBadge } from '../MUIBadge'
import { MUIButton } from '../MUIButton'
import { SectionedBorderBox } from '../SectionedBorderBox'

interface Props {
  /** Title for the section box */
  boxTitle?: ReactNode | string
  /** Title for the upload content */
  uploadContentTitle?: ReactNode | string
  /** Slot for the upload component */
  uploadSlot: ReactNode
  /** Variant of component */
  variant: 'owners' | 'representatives' | 'owner_from_owner'
  /** Url of sample */
  sampleUrl?: string
  /** Badge text if empty then the badge is not displayed. */
  badgeText?: string
  /** What style the box is using, either flat with border or with box shadow */
  boxStyle: 'elevated' | 'bordered'
}

/**
 * @component
 * Renders a component wrapper for uploading power of attorney documents.
 *
 * @example
 * <PowerOfAttorneyUpload
 *   boxTitle="Upload Power of Attorney"
 *   uploadSlot={<FileUpload />}
 *   sampleUrl="https://example.com/sample.pdf"
 *   variant="default"
 *   showSubmit={false}
 * />
 */
export const PowerOfAttorneyUpload: FC<Props> = ({
  boxTitle,
  uploadSlot,
  sampleUrl,
  uploadContentTitle,
  boxStyle,
  variant,
  badgeText,
}) => {
  const { t } = useTranslation(['common', 'power_of_attorney'])

  return (
    <SectionedBorderBox
      elevation={boxStyle === 'elevated' ? undefined : 'none'}
      border={boxStyle === 'bordered' ? `1px solid ${Color.GRAY_BORDER}` : undefined}
      title={
        <Stack direction="row" flexWrap="wrap" gap={2} alignItems="center">
          <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>{boxTitle || t(`power_of_attorney:upload_box_title_${variant}`)}</Typography>
          {badgeText && <MUIBadge color='orange' label={badgeText} />}
        </Stack>
      }
    >

      <Stack direction="row" flexWrap="wrap" gap={2}>

        <Stack
          flex="1 1 30%"
          direction="column"
          gap={1}
        >

          <Typography variant="h3" sx={{ margin: '0', lineHeight: 'normal' }}>
            <Trans parent={null}>
              {uploadContentTitle || t('power_of_attorney:upload_content_title')}
            </Trans>
          </Typography>

          <Box flex="1 0 auto">
            <Typography variant="text-sm">
              <Trans parent={null}>
                {t(`power_of_attorney:upload_content_description_${variant}`)}
              </Trans>
            </Typography>
          </Box>

          {sampleUrl &&
            <Box sx={{ mt: 2 }}>
              <MUIButton
                type="secondaryBorder"
                startIcon={<AttachFile />}
                onClick={() => window.open(sampleUrl, '_blank', 'noopener noreferrer nofollow')}
              >
                {t('power_of_attorney:sample_file')}
              </MUIButton>
            </Box>
          }

        </Stack>

        <Box
          flex="1 0 200px"
          sx={{
            minHeight: '200px',
          }}
        >
          {uploadSlot}
        </Box>

      </Stack>

    </SectionedBorderBox>
  )
}
