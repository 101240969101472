import constate from 'constate'
import { logAnalyticsEvent } from 'utils/analytics'
import { useCallback } from 'react'
import { useUserData } from 'components/contexts/UserDataContext'

export const [VisualPinturaEditingContextProvider, useVisualPinturaEditing] = constate(({
  visualName,
  imageUrl,
  assignmentId,
}: {
  visualName: string
  imageUrl: string
  assignmentId: string
}) => {
  const {
    baseUserData,
    organizationData,
  } = useUserData()

  const logEditEvent = useCallback((eventName: string, params?: {}) => {
    logAnalyticsEvent(eventName, {
      userEmail: baseUserData?.email,
      organizationId: organizationData?.id,
      assignmentId,
      visualName,
      originalImageUrl: imageUrl,
      ...params
    })
  }, [baseUserData?.email, imageUrl, organizationData?.id, visualName, assignmentId])

  return {
    visualName,
    imageUrl,
    assignmentId,
    logEditEvent,
  }
})
