import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { POST_WATERMARK, VisualFileType } from 'constants/visual'
import { TypeOfAvailableFileTypes, VisualTypeSelectEnumType, useGalleryConstants } from './GalleryConstants.context'

import { ProductType } from 'constants/product'
import { listVisuals } from 'redux/Individual/Visual/ListVisuals'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useGalleryAssignment } from './GalleryAssignment.context'

interface GalleryVisualTypeInterface {
  visualTypeSelect: VisualFileType.RAW | VisualFileType.POST | typeof POST_WATERMARK
  setVisualTypeSelect: Dispatch<SetStateAction<VisualFileType.RAW | VisualFileType.POST | typeof POST_WATERMARK>>
  originalType: VisualFileType.RAW | VisualFileType.POST | typeof POST_WATERMARK
  thumbnailType: VisualFileType.RAW_THUMB | VisualFileType.POST_THUMB | VisualFileType.POST_THUMB_WATERMARK
  webType: VisualFileType.RAW_WEB | VisualFileType.POST_WEB | VisualFileType.POST_WEB_WATERMARK
  normalizedOriginalType: VisualFileType.RAW | VisualFileType.POST
  fileTypes: Set<TypeOfAvailableFileTypes>
  setFileTypes: Dispatch<SetStateAction<Set<TypeOfAvailableFileTypes>>>
  downloadableTypes: Array<'WEB' | 'HD'>
  transformTypes: () => (VisualFileType.POST | VisualFileType.POST_WEB)[]
  transformAdminTypes: () => (VisualFileType.RAW | VisualFileType.RAW_WEB | VisualFileType.POST | VisualFileType.POST_WEB | VisualFileType.POST_WEB_WATERMARK)[]
}

const defaultGalleryVisualTypeValue: GalleryVisualTypeInterface = {
  visualTypeSelect: POST_WATERMARK,
  setVisualTypeSelect: () => { throw new Error('setVisualTypeSelect is undefined') },
  originalType: POST_WATERMARK,
  thumbnailType: VisualFileType.POST_THUMB_WATERMARK,
  webType: VisualFileType.POST_WEB_WATERMARK,
  normalizedOriginalType: VisualFileType.POST,
  fileTypes: new Set(),
  downloadableTypes: ['HD'],
  setFileTypes: () => { throw new Error('setFileTypes is undefined') },
  transformTypes: () => { throw new Error('transformTypes is undefined') },
  transformAdminTypes: () => { throw new Error('transformAdminTypes is undefined') },
}

/** Gallery visual type context */
export const GalleryVisualTypeContext = createContext<GalleryVisualTypeInterface>(defaultGalleryVisualTypeValue)
/** Gallery visual type context hook */
export const useGalleryVisualType = (): GalleryVisualTypeInterface => useContext(GalleryVisualTypeContext)

/** Context provider for gallery visual type */
export const GalleryVisualTypeContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const dispatch = useDispatch()
    const { roles } = useAuth0()
    const {
      VisualTypeSelectEnum,
      AvailableFileTypes,
      originalTypeMap,
      thumbnailTypeMap,
      webTypeMap,
    } = useGalleryConstants()
    const { canSelectVisualsForStaging } = useGalleryAssignment()

    const [visualTypeSelect, setVisualTypeSelect] = useState<VisualTypeSelectEnumType>(roles.isCreative ? VisualTypeSelectEnum.RAW : (roles.isClient ? ((!canSelectVisualsForStaging) ? VisualTypeSelectEnum.RAW : VisualTypeSelectEnum.POST_WATERMARK) : VisualTypeSelectEnum.POST))
    const originalType = useMemo(() => originalTypeMap[visualTypeSelect], [visualTypeSelect, originalTypeMap])
    const thumbnailType = useMemo(() => thumbnailTypeMap[visualTypeSelect], [visualTypeSelect, thumbnailTypeMap])
    const webType = useMemo(() => webTypeMap[visualTypeSelect], [visualTypeSelect, webTypeMap])
    const normalizedOriginalType = useMemo(() => {
      switch (originalType) {
        case POST_WATERMARK:
          return VisualFileType.POST
        default:
          return originalType
      }
    }, [originalType])
    const [fileTypes, setFileTypes] = useState<Set<TypeOfAvailableFileTypes>>(new Set([AvailableFileTypes.WEB, AvailableFileTypes.HD]))
    const transformTypes = useCallback(() => Array.from(fileTypes).map(type => {
      switch (type) {
        case AvailableFileTypes.HD:
          return VisualFileType.POST
        default:
          return VisualFileType.POST_WEB
      }
    }), [fileTypes, AvailableFileTypes])
    const transformAdminTypes = useCallback(() => Array.from(fileTypes).map(type => {
      switch (type) {
        case AvailableFileTypes.HD:
          return normalizedOriginalType
        default:
          return webType
      }
    }), [fileTypes, AvailableFileTypes, normalizedOriginalType, webType])


    const { product } = useGalleryAssignment()

    const downloadableTypes: Array<'WEB' | 'HD'> = useMemo(() => {
      if (product?.type === ProductType.FLOOR_PLAN || product?.type === ProductType.VIDEO) {
        return ['HD']
      }
      return ['WEB', 'HD']
    }, [product])

    // Change visual type with change of role
    useLayoutEffect(() => {
      if (roles.isClient) setVisualTypeSelect(VisualTypeSelectEnum.POST_WATERMARK)
      else if (roles.isCreative) setVisualTypeSelect(VisualTypeSelectEnum.RAW)
      else setVisualTypeSelect(VisualTypeSelectEnum.POST)
    }, [roles, setVisualTypeSelect, VisualTypeSelectEnum])

    // List new visuals when originalType or assignmentId changes
    useEffect(() => {
      dispatch(listVisuals(assignmentId, normalizedOriginalType))
    }, [originalType, normalizedOriginalType, dispatch, assignmentId])

    return (
      <GalleryVisualTypeContext.Provider
        value={{
          visualTypeSelect,
          setVisualTypeSelect,
          originalType,
          thumbnailType,
          webType,
          normalizedOriginalType,
          fileTypes,
          setFileTypes,
          transformTypes,
          transformAdminTypes,
          downloadableTypes
        }}
      >
        {children}
      </GalleryVisualTypeContext.Provider>
    )
  }
