import { Box, Stack } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'

import { FC } from 'react'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIInputField } from 'components/common/MUIInputField'
import { useTranslation } from 'react-i18next'

/**
 * @component Properties dashboard filter which contains manager dropdown and search input.
 * @example
 * <PropertiesDashboardFilter />
 */
export const PropertiesDashboardFilter: FC = () => {
  const { t } = useTranslation(['properties_dashboard'])

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignContent="center" marginBottom="1.6rem" height="3.6rem">

      {/* Manager filter - TODO: Will be developed in task - BKBN-2328. */}
      <MUIDropdown
        button={(isOpen, action) => (
          <MUIDropdownInput
            readOnly
            value=""
            placeholder={t('manager')}
            type={DropdownInputTypes.SELECTOR}
            onClick={action}
          />
        )}
      >
        <Box height="30rem">
          <div>data</div>
        </Box>
      </MUIDropdown>

      {/* Search input - TODO: Will be developed in task - BKBN-2329. */}
      <MUIInputField
        value=""
        placeholder={t('property_search_placeholder')}
        onChange={(e) => { }}
      />

    </Stack>
  )
}
